import BaseTransformer from "../system/BaseTransformer";
import QuestionResponse from "../../models/survey/response/QuestionResponse";
import QuestionTransformer from "./QuestionTransformer";
import Question from "../../models/survey/structure/Question";
import SurveyResponseTranformer from "./SurveyResponseTransformer";
import SurveyResponse from "../../models/survey/response/SurveyResponse";
import ChoiceResponseTransformer from "./ChoiceResponseTransformer";

export default class QuestionResponseTransformer extends BaseTransformer<QuestionResponse> {
  name = "QuestionResponseTransformer"

  create() : QuestionResponse {
    return new QuestionResponse();
  }

  fill(data: any, questionResponse: QuestionResponse) {
    questionResponse.id = data.id;
    questionResponse.skipped = data.skipped;

    (new QuestionTransformer(this.context))
      .awaitPublish(data.questionId, (question: Question) => {
        questionResponse.question = question;
      });

    (new SurveyResponseTranformer(this.context))
      .awaitPublish(data.surveyResponseId, (surveyResponse: SurveyResponse) => {
        questionResponse.surveyResponse = surveyResponse;
      });

    questionResponse.choiceResponses = (new ChoiceResponseTransformer(this.context))
      .transformMany(data.choiceResponses);

    return questionResponse;
  }
}
