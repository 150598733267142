import * as React from 'react';
import { PaginationPageProps } from './PaginationPage';

interface PaginationProps {
  title?: string;
  children: React.ReactElement<PaginationPageProps>[];
}

const Pagination: React.SFC<PaginationProps> = (props) => {
  return (
    <>
      { props.title && <div style={ {
        fontWeight: 'bold',
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: '10px'
      } }>{ props.title }</div> }
      <div style={ { float: 'none', display: 'block' } }>
        { props.children }
      </div>
    </>
  )
}
export default Pagination;
