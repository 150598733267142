import * as React from 'react';

import App, { Props, State } from '../App';
import RieView from '../components/rie/RieView';
import { RieContextProvider } from '../contexts/rie/RieContext';
import { navigateNext, navigatePrevious } from '../reducers/NavigateReducer';

export default class RieContainer extends React.Component<Props, State> {
  private app: App;

  constructor(props: Props) {
    super(props);

    this.app = new App(props, null, this.setState.bind(this));
    this.state = this.app.state;

    window.onbeforeunload = (e: any) => {
      if(this.state.save.hasUnsavedChanges) {
        return "De gemaakte wijzigingen zullen verloren gaan."
      }
    }
  }

  handleKeydown(event: KeyboardEvent) {
    if(!event.shiftKey) {
      return;
    }

    switch(event.key) {
      case 'ArrowLeft':
        this.app.handlers.navigate(navigatePrevious())
        break;

      case 'ArrowRight':
        this.app.handlers.navigate(navigateNext())
        break;
    }
  }

  componentWillMount(){
    document.addEventListener("keydown", this.handleKeydown.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeydown.bind(this));
  }



  render() {
    return <RieContextProvider value={ this.app.derivations.context }>
      <RieView app={ this.app } />
    </RieContextProvider>
  }
}
