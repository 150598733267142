import * as React from "react";
import Button from "../ui/Button";
import SurveyResponse from "../../models/survey/response/SurveyResponse";

export interface SurveyResponseSendButtonProps {
  surveyResponse: SurveyResponse;
  isSaving: boolean;
  isSending: boolean;
  onClick: () => void;
}

const SurveyResponseSendButton: React.SFC<SurveyResponseSendButtonProps> = ({
  surveyResponse,
  isSaving,
  isSending,
  onClick
}) => {
  let label, isEnabled;

  if (isSending) {
    label = (
      <>
        <div
          className="loader loader--small loader--white"
          style={{
            position: "relative",
            width: 18,
            height: 0,
            right: 8,
            bottom: 15,
            opacity: 0.8
          }}
        />{" "}
        Opslaan...
      </>
    );
    isEnabled = false;
  } else if (surveyResponse.sent) {
    label = "✓ Verzonden";
    isEnabled = false;
  } else if (isSaving) {
    label = "Opslaan en verzenden";
    isEnabled = false;
  } else if (!surveyResponse.canBeSent) {
    label = "Opslaan en verzenden";
    isEnabled = false;
  } else {
    label = "Opslaan en verzenden";
    isEnabled = true;
  }

  return <Button label={label} isEnabled={isEnabled} onClick={onClick} />;
};

export default SurveyResponseSendButton;
