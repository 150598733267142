import * as React from "react"

interface FooterProps {
}

const Footer: React.SFC<FooterProps> = (props) => {
 return (
  <footer id="footer">
    <div id="status_line">
      { props.children }
    </div>
  </footer>
 )
}
export default Footer;
