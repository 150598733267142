import * as React from "react"

interface PageProps {
  title: string;
  renderSidebar: () => JSX.Element;
  renderContent: () => JSX.Element;
  renderFooter: () => JSX.Element;
}

const Page: React.SFC<PageProps> = (props) => {
  return (
    <div>
      <aside id='sidebar_left' style={ { minWidth: '200px', position: 'static' } }>
        { props.renderSidebar() }
      </aside>
      <div id="content" className="has_sidebar_left" style={ { marginLeft: '240px', paddingBottom: '100px' } }>
        <div id="page_title">
          <h2 className="page_title_text">
            { props.title }
          </h2>
        </div>
        { props.renderContent() }
      </div>
      { props.renderFooter() }
    </div>
  )
}
export default Page;
