import QuestionResponse from "./QuestionResponse";
import Choice from "../structure/Choice";
import Question from "../structure/Question";

export default class ChoiceResponse {
  public id: number;
  public choice: Choice;
  public questionResponse: QuestionResponse;
  public explanation: string;

  public get question(): Question {
    return this.questionResponse.question;
  }

  public get isSelected(): boolean {
    return this.questionResponse.choiceResponseSelected(this);
  }

  public set isSelected(value: boolean) {
    this.questionResponse.selectChoiceResponse(this, value);
  }
}
