import * as React from "react";

import App from "../../../App";
import Question from "../../../models/survey/structure/Question";
import { navigateQuestion } from "../../../reducers/NavigateReducer";
import PagedSectionView from "../../survey/PagedSectionView";
import RiePage from "../template/RiePage";
import Button from "../../ui/Button";
import { navigateNext, navigatePrevious } from "../../../reducers/NavigateReducer";
import { saveAll } from "../../../reducers/SaveReducer";
import SaveButton from "../../ui/SaveButton";
import SectionView from "../../survey/SectionView";
import rieQuestionHTML from "../../../helpers/rieQuestionHTML";

export interface RieQualityRequirementsViewProps {
  app: App;
}

const RieQualityRequirementsView: React.SFC<RieQualityRequirementsViewProps> = props => {
  const handlers = props.app.handlers;
  const questionResponse = props.app.derivations.navigation.questionResponse;

  return (
    <RiePage
      app={props.app}
      key={questionResponse.question.id}
      title={questionResponse.sectionResponse.section.title}
      renderContent={() => (
        <>
          <SectionView
            sectionResponse={props.app.derivations.navigation.questionResponse.sectionResponse}
            onSurveyResponse={handlers.surveyResponse.bind(handlers)}
            renderTitle={questionResponse => {
              return (
                <b
                  style={{ margin: "10px 0 6px 0", fontSize: "115%", display: "block" }}
                  dangerouslySetInnerHTML={{
                    __html: rieQuestionHTML(
                      questionResponse.question.title,
                      props.app.derivations.trajectory
                    )
                  }}
                />
              );
            }}
            renderDescription={questionResponse => {
              return (
                <div
                  style={{ margin: "0 0 10px 0", lineHeight: "22px" }}
                  dangerouslySetInnerHTML={{
                    __html: rieQuestionHTML(
                      questionResponse.question.description,
                      props.app.derivations.trajectory
                    )
                  }}
                />
              );
            }}
          />
          <div style={{ marginBottom: "35px" }} />
          <Button
            label="Vorige"
            isEnabled={props.app.derivations.navigation.canNavigatePrevious}
            onClick={() => props.app.handlers.navigate(navigatePrevious())}
          />
          <Button
            label="Volgende"
            isEnabled={props.app.derivations.navigation.canNavigateNext}
            onClick={() => props.app.handlers.navigate(navigateNext())}
          />
          <SaveButton
            isSaving={props.app.state.save.isSaving}
            onSave={() => {
              handlers.save(saveAll());
            }}
            hasUnsavedChanges={props.app.state.save.hasUnsavedChanges}
          />
        </>
      )}
    />
  );
};
export default RieQualityRequirementsView;
