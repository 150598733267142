import * as React from 'react';
import { v1 as uuidv1 } from 'uuid';

import MeasureList from '../../components/rie/partials/MeasureList';
import Button from '../../components/ui/Button';
import TextButton from '../../components/ui/TextButton';
import Measure from '../../models/rie/Measure';
import MeasureSuggestion from '../../models/rie/MeasureSuggestion';
import { measureDelete, measureSave, MeasureAction } from '../../reducers/MeasureReducer';
import { Mode } from './RiskMeasureForm';
import Risk from '../../models/rie/Risk';
import Trajectory from '../../models/rie/Trajectory';
import MeasureForm from '../MeasureForm';

export interface MeasuresFormProps {
  measureSuggestions?: ReadonlyArray<MeasureSuggestion>;
  measures: ReadonlyArray<Measure>;
  onMeasureAction: (action: MeasureAction) => void;
  renderTitle?: () => JSX.Element;
  renderEmptyList?: () => JSX.Element;
  risk?: Risk;
  trajectory: Trajectory;
  showDeadline?: boolean;
  buttonLabel?: string;
  createTitle?: string;
  updateTitle?: string;
}

export interface MeasuresFormState {
  currentMeasure?: Measure;
}

export default class MeasuresForm extends React.Component<MeasuresFormProps, MeasuresFormState> {
  constructor(props: MeasuresFormProps) {
    super(props);

    this.state = {
      currentMeasure: null
    };
  }

  render() {
    const measureSuggestions = this.props.measureSuggestions ? this.props.measureSuggestions : [];
    const renderTitle = this.props.renderTitle ? this.props.renderTitle : () => (<></>);
    const showDeadline = this.props.showDeadline == null ? true : this.props.showDeadline;
    const buttonLabel = this.props.buttonLabel == null ? 'Nieuwe maatregel' : this.props.buttonLabel;

    return (
      <div style={ { marginTop: '20px', marginBottom: '20px' } }>
        {
          measureSuggestions.length > 0 && <div style={ { padding: '10px 0 20px 0' } }>
            <div style={ { fontWeight: 'bold' } }>Suggesties</div>
            { measureSuggestions.map(measureSuggestion => {
              return <div
                key={ measureSuggestion.id }
                style={ { padding:'10px', marginLeft: '-10px' }}
                onClick={ (e) => { e.preventDefault(); this.measureSuggestionHandler(measureSuggestion) }}
              >
                <a href='#'>{ measureSuggestion.description }</a>
              </div>
            }) }
          </div>
        }

        <MeasureList
          renderTitle={ renderTitle }
          measures={ this.props.measures }
          onEditClick={ this.measureChangeHandler.bind(this) }
          onDeleteClick={ this.deleteHandler.bind(this) }
          renderEmptyList={ this.props.renderEmptyList }
          showDeadline={ showDeadline }
        />

        { this.state.currentMeasure ? (
          <MeasureForm
            key={ this.state.currentMeasure.key }
            mode={ this.props.measures.indexOf(this.state.currentMeasure) == -1 ? Mode.Create : Mode.Update }
            initialMeasure={ this.state.currentMeasure }
            onConfirm={ this.confirmHandler.bind(this) }
            onCancel={ this.cancelHandler.bind(this) }
            showDeadline={ showDeadline }
            createTitle={ this.props.createTitle }
            updateTitle={ this.props.updateTitle }
          />
        ) : (
          <div style={ { marginTop: '20px' } }>
            <Button
              label={ buttonLabel }
              onClick={ this.newMeasureHandler.bind(this) }
            />
          </div>
        )}
      </div>
    )
  }

  private get newMeasure(): Measure {
    let measure = new Measure;

    measure.id = null;
    measure.key = uuidv1();
    measure.description = '';
    measure.deadline = null;
    measure.risk = this.props.risk ? this.props.risk : null;
    measure.trajectory = this.props.trajectory;

    return measure;
  }

  measureSuggestionHandler(measureSuggestion: MeasureSuggestion) {
    this.setState(prevState => {
      return {
        ...prevState,
        currentMeasure: (() => {
          let measure = this.newMeasure;
          measure.description = measureSuggestion.description;
          return measure;
        })()
      }
    });
  }

  newMeasureHandler() {
    this.setState(prevState => {
      return {
        ...prevState,
        currentMeasure: this.newMeasure
      }
    });
  }

  measureChangeHandler(currentMeasure: Measure) {
    this.setState(prevState => {
      return {
        ...prevState,
        currentMeasure
      }
    });
  }

  cancelHandler() {
    this.setState(prevState => {
      return {
        ...prevState,
        currentMeasure: null
      }
    });
  }

  confirmHandler(measureData: Measure) {
    this.props.onMeasureAction(
      measureSave(measureData)
    );

    this.setState(prevState => {
      return {
        ...prevState,
        currentMeasure: null
      }
    });
  }

  deleteHandler(measure: Measure) {
    if(confirm("Weet u het zeker?")) {
      return this.props.onMeasureAction(
        measureDelete(measure.key)
      );
    }
  }
}
