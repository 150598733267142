import DerivedNavigation from "./DerivedNavigation";
import Derivations from "../Derivations";
import { View, NavigateState } from "../../reducers/NavigateReducer";

export default class PlanOfAction extends DerivedNavigation {
  constructor(derivations: Derivations) {
    super(derivations);
  }

  get previousNavigateState() : NavigateState {
    return {
      view: View.Evaluation,
      questionId: null,
      riskId: this.derivations.trajectory.lastActiveRiskEvaluation ? this.derivations.trajectory.lastActiveRiskEvaluation.risk.id : null
    };
  }

  get nextNavigateState() : NavigateState {
    return {
      view: View.Finalize,
      questionId: null,
      riskId: null
    };
  }
}
