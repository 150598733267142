import BaseTransformer from "../system/BaseTransformer";
import Dossier from "../../models/general/Dossier";
import PersonTransformer from "./PersonTransformer";

export default class DossierTransformer extends BaseTransformer<Dossier> {
  name = "DossierTransformer"

  create() : Dossier {
    return new Dossier;
  }

  fill(data: any, dossier: Dossier) : Dossier {
    dossier.id = data.id;

    dossier.parent = (new PersonTransformer(this.context))
      .transform(data.parent);

    return dossier;
  }
}
