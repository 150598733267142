import * as React from 'react';

import App from '../../../App';
import RiePage from '../template/RiePage';
import { RieContextConsumer, Role } from '../../../contexts/rie/RieContext';
import ChildminderExplanation from '../partials/explanation/ChildminderExplanation';
import FranchiseeExplanation from '../partials/explanation/FranchiseeExplanation';
import { navigateNext } from '../../../reducers/NavigateReducer';
import Button from '../../ui/Button';

export interface RieExplanationViewProps {
  app: App;
}

const RieExplanationView: React.SFC<RieExplanationViewProps> = (props) => {
  return (
    <RiePage
      app={ props.app }
      title="RI&amp;E veiligheid en gezondheid- Toelichting"
      renderContent={ () => (
        <RieContextConsumer>
          { ctx =>
            <>
              {(() => {
                switch(ctx.role) {
                  case Role.Childminder:
                    return <ChildminderExplanation />;
                  case Role.Franchisee:
                    return <FranchiseeExplanation />;
                  default:
                    return `Error: no explanation configured for role (${ctx.role}).`;
                }
              })()}

              <p>
                <Button
                  label="Volgende"
                  isEnabled={ props.app.derivations.navigation.canNavigateNext }
                  onClick={ () => props.app.handlers.navigate(navigateNext()) }
                />
              </p>
            </>
          }
        </RieContextConsumer>
      )}
    />
  )
}
export default RieExplanationView;
