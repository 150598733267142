import * as React from "react";

import App from "../../App";
import { View } from "../../reducers/NavigateReducer";
import RiePage from "./template/RiePage";
import RieEvaluationView from "./views/RieEvaluationView";
import RiePlanOfActionView from "./views/RiePlanOfActionView";
import RieSurveySectionView from "./views/RieSurveySectionView";
import RieFinalizeView from "./views/RieFinalizeView";
import RieImplementationView from "./views/RieImplementationView";
import RieSendView from "./views/RieSendView";
import RieExplanationView from "./views/RieExplanationView";
import RieAttentionPointsView from "./views/RieAttentionPointsView";
import RieQualityRequirementsView from "./views/RieQualityRequirementsView";

export interface RieViewProps {
  app: App;
}

const RieView: React.SFC<RieViewProps> = props => {
  switch (props.app.state.navigate.view) {
    case View.Explanation:
      return <RieExplanationView app={props.app} />;

    case View.SurveySection:
      const reference = props.app.derivations.navigation.question.survey.reference;
      if (reference === "QUALITY_REQUIREMENTS") {
        return <RieQualityRequirementsView app={props.app} />;
      } else if (reference === "RIE") {
        return <RieSurveySectionView app={props.app} />;
      }

    case View.Evaluation:
      return <RieEvaluationView app={props.app} />;

    case View.PlanOfAction:
      return <RiePlanOfActionView app={props.app} />;

    case View.Finalize:
      return <RieFinalizeView app={props.app} />;

    case View.Implementation:
      return <RieImplementationView app={props.app} />;

    case View.Send:
      return <RieSendView app={props.app} />;

    case View.AttentionPoints:
      return <RieAttentionPointsView app={props.app} />;

    default:
      return <RiePage app={props.app} renderContent={() => <div>Error: view not found</div>} />;
  }
};

export default RieView;
