import * as React from 'react';

export interface PaginationPageProps {
  onClick?: () => void;
  isSelected?: boolean;
  isEnabled?: boolean;
  hasError?: boolean;
  children: string | React.ReactText | React.ReactText[];
}

const PaginationPage: React.SFC<PaginationPageProps> = (props) => {
  const isSelected = props.isSelected === true ? true: false;
  const isEnabled = props.isEnabled === false ? false: true;
  const hasError = props.hasError === true ? true : false;
  const onClick = props.onClick != null && isEnabled ? props.onClick : () => {};

  return <div onClick={ (e) => { !isSelected && onClick(); }} className={ 'page' } style={ {
    padding: isSelected ? '4px 7px' : '8px 15px',
    margin: isSelected ? '4px 8px' : '0 0',
    height: '17px',
    color: isSelected ? 'white' : (isEnabled ? (hasError ? '#900' : '#0597eb') : '#666'),
    cursor: isEnabled ? 'pointer' : 'not-allowed',
    background: isSelected ? '#0597EB' : 'none',
    display: 'inline-block',
    opacity: isEnabled ? 1 : 0.5
  } }>
    { props.children }
  </div>
}
export default PaginationPage;
