import Question from './Question';
import Section from './Section';

export default class Survey {
  public id: number;
  public reference: string;
  public title: string;
  public sections: Section[];

  get questions(): Question[] {
    return this.sections.map(section => {
      return section.questions
    }).reduce((allQuestions, questions) => allQuestions.concat(questions));
  }

  previousQuestion(question: Question): Question {
    let questions = this.questions;
    let index = questions.indexOf(question);
    let previousIndex = index + -1;
    return previousIndex > -1 ? questions[previousIndex] : null;
  }

  nextQuestion(question: Question): Question {
    let questions = this.questions;
    let index = questions.indexOf(question);
    let nextIndex = index + 1;
    return questions.length > nextIndex ? questions[nextIndex] : null;
  }
}
