import BaseTransformer from "../system/BaseTransformer";
import Section from "../../models/survey/structure/Section";
import QuestionTransfomer from "./QuestionTransformer";
import SurveyTransformer from "./SurveyTransformer";
import Survey from "../../models/survey/structure/Survey";
import Question from "../../models/survey/structure/Question";

export default class SectionTransformer extends BaseTransformer<Section> {
  name = "SectionTransformer"

  create() : Section {
    return new Section;
  }

  fill(data: any, section: Section) : Section {
    section.id = data.id;
    section.title = data.title;

    section.questions = (new QuestionTransfomer(this.context))
      .transformMany(data.questions)
      .sort((a: Question, b: Question) => a.id - b.id);
    
    (new SurveyTransformer(this.context))
      .awaitPublish(data.surveyId, (survey: Survey) => {
        section.survey = survey;
      })

    return section;
  }
}
