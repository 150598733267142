import Measure from '../../models/rie/Measure';
import BaseTransformer from '../system/BaseTransformer';
import RiskTransformer from './RiskTransformer';
import TrajectoryTransfomer from './TrajectoryTransformer';

export default class MeasureTransformer extends BaseTransformer<Measure> {
  name = "MeasureTransformer"

  create() : Measure {
    return new Measure;
  }

  fill(data: any, measure: Measure) : Measure {
    measure.id = data.id;
    measure.key = data.id;
    measure.description = data.description;
    measure.deadline = data.deadline ? new Date(data.deadline) : null;
    measure.originalDeadline = data.originalDeadline ? new Date(data.originalDeadline) : null;
    measure.implemented = data.implemented;
    measure.category = data.category;

    if(data.riskId != null) {
      (new RiskTransformer(this.context))
      .awaitPublish(data.riskId, risk => {
        measure.risk = risk;
      });
    } else {
      measure.risk = null;
    }

    (new TrajectoryTransfomer(this.context))
      .awaitPublish(data.trajectoryId, trajectory => {
        measure.trajectory = trajectory;
      });

    return measure;
  }
}
