import * as React from 'react';

export interface FranchiseeExplanationProps {
}

const FranchiseeExplanation: React.SFC<FranchiseeExplanationProps> = () => {
  return <>
    <p>
      Het RI&amp;E traject bestaat uit 6 delen. De eerste 2 delen, ‘Voorbereiden’ en ‘Inventariseren’, kunnen ter voorbereiding van de afspraak, door de gastouder zelf worden ingevuld. ‘Evalueren’, het ‘Plan van Aanpak’ en ‘Afronden’ zijn alleen voor jou inzichtelijk en bewerkbaar. Het laatste deel, ‘Implementatie’, is ook voor de gastouder inzichtelijk, maar alleen door jou te bewerken.
    </p>
    <p style={ { marginBottom: '5px' } }>
      <i>1. Voorbereiden</i><br />
      De gastouder start met het beantwoorden van volgende 4 vragen:
    </p>
    <ul style={ { marginTop: 0 } }>
      <li> - Je biedt opvang aan jongere kinderen (leeftijd 0-3 jaar)</li>
      <li> - Er is een trap aanwezig</li>
      <li> - Er is een tuin aanwezig</li>
      <li> - Er is sprake van opvang op een boerderij</li>
    </ul>
    <p>
    Deze antwoorden bepalen het aantal variabele vragen van de inventarisatie. Na het beantwoorden van de 4 vragen, kan de gastouder beginnen met de Inventarisatie.
    </p>
    <p>
      <i>2. Inventariseren</i><br />
      De inventarisatie bestaat uit 6 categorieën. Per categorie worden alle vragen beantwoord. Bij elk antwoord is er de mogelijkheid om een toelichting te geven. Bij een aantal antwoordopties is een toelichting verplicht. Wanneer de gastouder het antwoord niet weet, kan zij kiezen voor de extra optie; 'vraag overslaan'. Als er een vinkje voor de categorie staat, zijn alle vragen van die categorie beantwoord. Je kunt eenvoudig zien welke vragen van een categorie nog niet (volledig) ingevuld zijn; de betreffende vraagnummers kleuren dan rood.
    </p>
    <p>
    Als alle vragen door de gastouder beantwoord zijn (dit wordt gevalideerd) kan de gastouder de inventarisatie naar jou verzenden. De inventarisatie kan dan alleen nog door jou bewerkt worden.
    </p>
    <p>
      <i>3. Evalueren</i><br />
      Tijdens de afspraak wordt de ingevulde inventarisatie samen doorgelopen. Tijdens de Evaluatie kunnen de antwoorden door jou aangepast worden. Alleen jij hebt de mogelijkheid om aan te geven dat de genomen maatregelen niet voldoende zijn. Wanneer jij deze optie invult, wordt de vraag automatisch als risico overgenomen op de Evaluatie.
    </p>
    <p>
    Bij de Evaluatie zie je alle risico's terug die zijn geconstateerd tijdens de Inventarisatie. Bij elk risico zie je een aantal suggesties voor het nemen van maatregelen. Je kunt een suggestie, een andere maatregel of beide invullen. Je hebt de mogelijkheid om een actiedatum in te vullen waarop de gastouder deze maatregel zou moeten afronden. Als je geen actiedatum toevoegt, wordt deze maatregel als afspraak toegevoegd op het Plan van Aanpak.
    </p>
    <p>
      <i>4. Plan van Aanpak</i><br />
      Als de Evaluatie volledig is ingevuld, ga je naar het item Plan van Aanpak. In het Plan van Aanpak vindt je alle te nemen maatregelen uit de Evaluatie terug. Ook kun je nog extra maatregelen of afspraken toevoegen, met of zonder actiedatum.
    </p>
    <p>
      <i>5. Afronden</i><br />
      Wanneer alle vragen zijn beantwoord en voor alle risico’s minimaal één maatregel/afspraak is toegevoegd, kun je het RI&amp;E traject afronden. Als jij en de gastouder akkoord zijn met de Inventarisatie en het Plan van Aanpak kun je, na het invoeren van de datum, de RI&amp;E definitief maken. De RI&amp;E krijgt nu een referentienummer. Dit referentienummer kan niet meer gewijzigd worden.
    </p>
    <p>
      <i>6. Implementatie</i><br />
      Om voor jezelf en de GGD overzicht te houden, kun je via het item Implementatie de maatregelen met een actiedatum afvinken wanneer de gastouder de maatregel heeft afgerond.
    </p>
  </>
};
export default FranchiseeExplanation;
