import * as React from 'react';

import RiskEvaluation from '../../../models/rie/RiskEvaluation';
import Question from '../../../models/survey/structure/Question';

export interface RiskQuestionReferenceProps {
  riskEvaluation: RiskEvaluation;
  onNavigateQuestion: (question: Question) => void;
}

const RiskQuestionReference: React.SFC<RiskQuestionReferenceProps> = ({ riskEvaluation, onNavigateQuestion }) => {
  const risk = riskEvaluation.risk;
  const surveyQuestionResponses = riskEvaluation.surveyQuestionResponses;

  return (
    <>
      <style>
        {/* Temporary */}
        {
        `.questionResponse {
          cursor: pointer;
        }

        .questionResponse:hover {
          opacity: 0.7;
        }`
        }
      </style>
      { surveyQuestionResponses.map(questionResponse => (
          <div
            key={ questionResponse.question.id }
            onClick={ () => { onNavigateQuestion(questionResponse.question)}}
            className="questionResponse"
          >
            <div style={ { fontWeight: 'bold', color: '#58595B' } }>
              { questionResponse.question.title }
            </div>
            <ul style={ { marginTop: '2px', marginBottom: '8px'} }>
              { questionResponse.selectedChoiceResponses.map(choiceResponse => {
                const isTrigger = riskEvaluation.choiceResponses.find(x => x.choice == choiceResponse.choice);
                return (
                  <li key={ choiceResponse.choice.id} style={ { color: isTrigger ? '#900' : null, display: 'flex' } }>
                    <span style={ { width: '15px', display: 'inline-block' }}>{ isTrigger ? '× ' : '✓ ' }</span>
                    <div style={ { flex: 1 } }>
                      { choiceResponse.choice.title } { choiceResponse.explanation && <>
                        <br />
                        <i>{ choiceResponse.explanation }</i>
                      </> }
                    </div>
                  </li>
                );
              }) }
            </ul>
          </div>
      )) }
    </>
  )
}
export default RiskQuestionReference;
