import BaseTransformer from "../system/BaseTransformer";
import Choice from "../../models/survey/structure/Choice";
import Question from "../../models/survey/structure/Question";
import QuestionTransformer from "./QuestionTransformer";

export default class ChoiceTransformer extends BaseTransformer<Choice> {
  name = "ChoiceTransformer"

  create() : Choice {
    return new Choice;
  }

  fill(data: any, choice: Choice) {
    choice.id = data.id;
    choice.title = data.title;
    choice.explanationMode = data.explanationMode;

    (new QuestionTransformer(this.context))
      .awaitPublish(data.questionId, (question: Question) => {
        choice.question = question;
      });

    return choice;
  }
}
