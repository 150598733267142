import * as React from "react";
import SignaturePad from "../../ui/SignaturePad";

export interface RieSignatureFieldProps {
  title: string;
  onClear?: () => void;
  onSet?: (png: string) => void;
}

export interface RieSignatureFieldState {}

export default class RieSignatureField extends React.Component<
  RieSignatureFieldProps,
  RieSignatureFieldProps
> {
  private el: Element;

  constructor(props: RieSignatureFieldProps) {
    super(props);
  }

  render() {
    const onClear = this.props.onClear ? this.props.onClear : () => {};
    const onSet = this.props.onSet ? this.props.onSet : () => {};

    return (
      <div>
        <div style={{ marginBottom: "5px" }}>{this.props.title}</div>
        <SignaturePad onClear={onClear} onEnd={onSet} />
      </div>
    );
  }
}
