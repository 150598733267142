import * as React from "react";
import { SurveyContext } from "./SurveyContext";
import Trajectory from "../../models/rie/Trajectory";
import { View } from "../../reducers/NavigateReducer";

export enum Role {
  Franchisee,
  Childminder
}

export abstract class RieContext {
  public trajectory: Trajectory;

  constructor(trajectory: Trajectory) {
    this.trajectory = trajectory;
  }

  abstract get surveyContext(): SurveyContext;
  abstract get visibleViews(): View[];
  abstract get role(): Role;

  get inventorizationConsideredDone(): boolean {
    return this.surveyContext.surveyResponseConsideredDone(this.trajectory.rieSurveyResponse);
  }

  get shouldShowQualityRequirements(): boolean {
    return false;
  }
}

const ctxt = React.createContext<RieContext | null>(null);

export const RieContextProvider = ctxt.Provider;
export const RieContextConsumer = ctxt.Consumer;
