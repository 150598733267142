import SurveyResponse from "../../models/survey/response/SurveyResponse";
import BaseTransformer from "../system/BaseTransformer";
import SurveyTransformer from "./SurveyTransformer";
import QuestionResponseTransformer from "./QuestionResponseTransformer";
import Choice from "../../models/survey/structure/Choice";
import QuestionResponse from "../../models/survey/response/QuestionResponse";
import ChoiceResponse from "../../models/survey/response/ChoiceResponse";
import ChoiceTransformer from "./ChoiceTransformer";

export default class ChoiceResponseTransformer extends BaseTransformer<ChoiceResponse> {
  name = "ChoiceResponseTransformer"

  create() : ChoiceResponse {
    return new ChoiceResponse;
  }

  fill(data: any, choiceResponse: ChoiceResponse) : ChoiceResponse {
    choiceResponse.id = data.id;
    choiceResponse.explanation = data.explanation;

    (new QuestionResponseTransformer(this.context))
      .awaitPublish(data.questionResponseId, (questionResponse: QuestionResponse) => {
        choiceResponse.questionResponse = questionResponse;
        choiceResponse.isSelected = data.selected;
      });
 
    (new ChoiceTransformer(this.context))
      .awaitPublish(data.choiceId, (choice: Choice) => {
        choiceResponse.choice = choice;
      });
  
    return choiceResponse;
  }
}
