import * as React from 'react';

import Risk from '../../../models/rie/Risk';
import Trajectory from '../../../models/rie/Trajectory';
import QuestionResponse from '../../../models/survey/response/QuestionResponse';
import MeasureList from './MeasureList';
import RiskEvaluation from '../../../models/rie/RiskEvaluation';

export interface RiskReferenceProps {
  riskEvaluations: ReadonlyArray<RiskEvaluation>;
  onRiskClick?: (risk: Risk) => void;
}

const RiskReference: React.SFC<RiskReferenceProps> = ({ riskEvaluations, onRiskClick }) => {
  return (
    <>
      <style>
        {
        `.riskReference {
          cursor: pointer;
        }

        .riskReference:hover {
          opacity: 0.7;
        }`
        }
      </style>
      { riskEvaluations.length > 0 && (
        <div style={ { fontWeight: 'bold', color: '#58595B', marginTop: '20px', marginBottom: '10px', fontSize: '16px' } }>
          Risico
        </div>
      ) }

      { riskEvaluations.map(riskEvaluation => (
          <div
            key={ riskEvaluation.risk.id }
            data-name={ `risk-${ riskEvaluation.risk.id }` }
            onClick={ () => {
              if(!onRiskClick) {
                return;
              }

              onRiskClick(riskEvaluation.risk)
            }}
            className="riskReference"
          >
            <div style={ { fontWeight: 'bold' }}>
              { riskEvaluation.risk.title }
            </div>
            <MeasureList
              measures={ riskEvaluation.measures }
              readOnly={true}
            />
          </div>
      )) }
    </>
  )
}
export default RiskReference;
