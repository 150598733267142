import * as React from 'react';
import ObjectHelper from '../../helpers/ObjectHelper';
import Facility from '../../models/general/Facility';
import FacilityTransformer from '../../transformers/general/FacilityTransformer';

export interface RieTrajectoryFormProps {
  data: any
}

export interface RieTrajectoryFormState {
  facilityId: number | null;
  dossierIds: number[];
}

export class RieTrajectoryForm extends React.Component<RieTrajectoryFormProps, RieTrajectoryFormState> {
  private facilities: Facility[];

  constructor(props: RieTrajectoryFormProps) {
    super(props);

    let data = this.props.data;

    data = ObjectHelper.keysToCamelCase(data);
    const facilitiesData: [] = data.facilities;
    this.facilities = (new FacilityTransformer).transformMany(facilitiesData);

    this.state = {
      facilityId: this.facilities.length > 0 ? this.facilities[0].id : null,
      dossierIds: []
    };
  }

  render() {
    return <>
      { this.renderFacilityField() }
    </>
  }

  changeFacility(facility: Facility) {
    this.setState({
      facilityId: facility.id,
      dossierIds: []
    });
  }

  renderFacilityField() {
    return <div
        className="field radio_buttons required rie_trajectory_facility_id"
        style={{ display: 'flex' }}
      >
      <label
        className="radio_buttons required control-label"
      >
        <abbr title="vereist">*</abbr>
        Opvanglocatie
      </label>
      <div className="facilities_radio_buttons_wrapper">
        {
          this.facilities.map(facility => {
            return <label
                key={ facility.id }
                onClick={ () => {
                  this.changeFacility(facility);
                }}
                style={{
                  display: 'block',
                  width: 'inherit',
                  float: 'none'
                }}
              >
                <input
                  className="radio_buttons required"
                  type="radio"
                  checked={ this.state.facilityId == facility.id }
                  value={ facility.id }
                  readOnly={ true }
                  name="rie_trajectory[facility_id]"
                />
                { facility.fullInfo }
            </label>
          })
        }
      </div>
    </div>
  }

  private get selectedFacility(): Facility {
    return this.facilities.find(x => x.id == this.state.facilityId);
  }
}
