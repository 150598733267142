import App, { Props, State } from "../App";
import Risk from "../models/rie/Risk";
import Question from "../models/survey/structure/Question";
import Section from "../models/survey/structure/Section";
import { Reducer, StateReduction } from "./Reducer";

export const NAVIGATE_QUESTION = "NAVIGATE_QUESTION";
export type NAVIGATE_QUESTION = typeof NAVIGATE_QUESTION;
export interface NavigateQuestion {
  type: NAVIGATE_QUESTION;
  question: Question;
}
export function navigateQuestion(question: Question): NavigateQuestion {
  return {
    type: NAVIGATE_QUESTION,
    question
  };
}

export const NAVIGATE_SECTION = "NAVIGATE_SECTION";
export type NAVIGATE_SECTION = typeof NAVIGATE_SECTION;
export interface NavigateSection {
  type: NAVIGATE_SECTION;
  section: Section;
}
export function navigateSection(section: Section): NavigateSection {
  return {
    type: NAVIGATE_SECTION,
    section
  };
}

export const NAVIGATE_PREVIOUS = "NAVIGATE_PREVIOUS";
export type NAVIGATE_PREVIOUS = typeof NAVIGATE_PREVIOUS;
export interface NavigatePrevious {
  type: NAVIGATE_PREVIOUS;
}
export function navigatePrevious(): NavigatePrevious {
  return {
    type: NAVIGATE_PREVIOUS
  };
}

export const NAVIGATE_NEXT = "NAVIGATE_NEXT";
export type NAVIGATE_NEXT = typeof NAVIGATE_NEXT;
export interface NavigateNext {
  type: NAVIGATE_NEXT;
}
export function navigateNext(): NavigateNext {
  return {
    type: NAVIGATE_NEXT
  };
}

export const NAVIGATE_RISK = "NAVIGATE_RISK";
export type NAVIGATE_RISK = typeof NAVIGATE_RISK;
export interface NavigateRisk {
  type: NAVIGATE_RISK;
  risk: Risk;
}
export function navigateRisk(risk: Risk): NavigateRisk {
  return {
    type: NAVIGATE_RISK,
    risk
  };
}

export const NAVIGATE_EVALUATION_SECTION = "NAVIGATE_EVALUATION_SECTION";
export type NAVIGATE_EVALUATION_SECTION = typeof NAVIGATE_EVALUATION_SECTION;
export interface NavigateEvaluationSection {
  type: NAVIGATE_EVALUATION_SECTION;
  section: Section;
}
export function navigateEvaluationSection(section: Section): NavigateEvaluationSection {
  return {
    type: NAVIGATE_EVALUATION_SECTION,
    section
  };
}

export const NAVIGATE_VIEW = "NAVIGATE_VIEW";
export type NAVIGATE_VIEW = typeof NAVIGATE_VIEW;
export interface NavigateView {
  type: NAVIGATE_VIEW;
  view: View;
}
export function navigateView(view: View): NavigateView {
  return {
    type: NAVIGATE_VIEW,
    view
  };
}

export type NavigateAction =
  | NavigateSection
  | NavigateQuestion
  | NavigateRisk
  | NavigatePrevious
  | NavigateNext
  | NavigateEvaluationSection
  | NavigateView;

export enum View {
  Explanation = "Explanation",
  SurveySection = "SurveySection",
  Evaluation = "Evaluation",
  PlanOfAction = "PlanOfAction",
  Finalize = "Finalize",
  Implementation = "Implementation",
  Send = "Send",
  AttentionPoints = "AttentionPoints"
}

export type NavigateState = {
  view: View;
  questionId: number | null;
  riskId: number | null;
};

export class NavigateReducer extends Reducer<NavigateAction, NavigateState> {
  reduce(app: App, prevState: State, action: NavigateAction): StateReduction {
    switch (action.type) {
      case NAVIGATE_QUESTION:
        return {
          newState: {
            ...prevState,
            navigate: {
              view: View.SurveySection,
              questionId: action.question.id,
              riskId: null
            }
          }
        };

      case NAVIGATE_SECTION:
        return this.reduce(app, prevState, navigateQuestion(action.section.questions[0]));

      case NAVIGATE_PREVIOUS:
        const previousState = app.derivations.navigation.previousNavigateState;
        return {
          newState: previousState
            ? {
                ...prevState,
                navigate: {
                  ...previousState
                }
              }
            : prevState
        };

      case NAVIGATE_NEXT:
        const nextState = app.derivations.navigation.nextNavigateState;
        return {
          newState: nextState
            ? {
                ...prevState,
                navigate: {
                  ...nextState
                }
              }
            : prevState
        };

      case NAVIGATE_RISK:
        return {
          newState: {
            ...prevState,
            navigate: {
              view: View.Evaluation,
              questionId: null,
              riskId: action.risk.id
            }
          }
        };

      case NAVIGATE_EVALUATION_SECTION:
        const riskEvaluationSection = app.derivations.trajectory.riskEvaluationSections.find(
          x => x.section == action.section
        );
        const firstRiskEvaluation = riskEvaluationSection.firstActiveRiskEvaluation;

        if (firstRiskEvaluation) {
          return this.reduce(app, prevState, navigateRisk(firstRiskEvaluation.risk));
        } else {
          return this.reduce(app, prevState, navigateView(View.Evaluation));
        }

      case NAVIGATE_VIEW:
        switch (action.view) {
          case View.SurveySection:
            return this.reduce(
              app,
              prevState,
              navigateQuestion(
                app.derivations.rieSurveyResponse.firstActiveQuestionResponse.question
              )
            );

          case View.Evaluation:
            return this.navigateEvaluation(app, prevState);

          default:
            return this.navigateStatic(app, prevState, action.view);
        }
    }
  }

  initialState(props: Props): NavigateState {
    if (!props.initialTrajectoryData.finalized) {
      return {
        view: View.Explanation,
        questionId: null,
        riskId: null
      };
    } else {
      return {
        view: View.Implementation,
        questionId: null,
        riskId: null
      };
    }
  }

  private navigateEvaluation(app: App, prevState: State): StateReduction {
    const activeRiskEvaluations = app.derivations.trajectory.riskEvaluations.filter(
      x => x.isActive
    );
    if (activeRiskEvaluations.length > 0) {
      return this.reduce(app, prevState, navigateRisk(activeRiskEvaluations[0].risk));
    } else {
      return {
        newState: {
          ...prevState,
          navigate: {
            view: View.Evaluation,
            questionId: null,
            riskId: null
          }
        }
      };
    }
  }

  private navigateStatic(app: App, prevState: State, view: View): StateReduction {
    return {
      newState: {
        ...prevState,
        navigate: {
          view,
          questionId: null,
          riskId: null
        }
      }
    };
  }
}
