import * as React from 'react';

export interface ChildminderExplanationProps {
}

const ChildminderExplanation: React.SFC<ChildminderExplanationProps> = () => {
  return <>
    <p>
      Uiteraard wil je dat je opvanglocatie aan alle veiligheidseisen voldoet. Ook de overheid verlangt dit van je. Vandaar dat je opvanglocatie met behulp van een zogenaamde ‘Risico-inventarisatie en evaluatie veiligheid en gezondheid’ (RI&amp;E) gecheckt dient te worden. Zo ga je samen met je consulent na welke risico’s de opvang van kinderen op je opvanglocatie (mogelijk) met zich meebrengt. Daarbij moet worden aangegeven welke voorzorgsmaatregelen zijn getroffen om de kans op een ongeval te voorkomen.
    </p>
    <p>
      <b>Aan de slag met de Risico-inventarisatie</b><br />
      De Risico-inventarisatie bestaat uit een aantal delen.
    </p>
    <p>
      <i>1. Voorbereiden</i><br />
      Hier geef je aan welke zaken van toepassing zijn op je opvangsituatie en -locatie. Op basis hiervan worden de vragen in de inventarisatie bepaald.
    </p>
    <p>
      <i>2. Inventariseren</i><br />
      De Inventarisatie bestaat uit 6 onderdelen. Per onderdeel stellen we je vragen om alle risico's duidelijk in kaart te brengen. Geef zo goed mogelijk antwoord op de gestelde vragen. Is iets onduidelijk of heb je nog aanvullende vragen kun je ervoor kiezen om een vraag over te slaan (laatste antwoordmogelijkheid). Deze vragen neem je dan samen door met je ViaViela contactpersoon tijdens jullie afspraak.
    </p>
    <p>
      <i>3. Verzenden</i><br />
      Als je alle vragen hebt doorlopen en hebt beantwoord, ga je de inventarisatie Verzenden. Staat er bij alle onderdelen in de inventarisatie een vinkje? Dan heb je alles ingevuld en hoef je deze alleen nog te versturen. Klik hiervoor in het menu op "Verzenden".
      Let op: Wanneer in de inventarisatie niet alle onderdelen en vragen zijn afgerond kan de inventarisatie niet worden verzonden. Ook kan het zijn dat je nog een toelichting moet geven bij een vraag. Controleer of bij alle onderdelen een vinkje te staat. Zo niet, kijk dan in dit onderdeel bij welke vraag nog geen vinkje staat en beantwoordt alsnog deze vraag.
      Nadat je de inventarisatie hebt verzonden, kan je ViaViela contactpersoon de inventarisatie inzien en zo alvast jullie afspraak voorbereiden.
    </p>
    <p>
      <i>4. Implementatie</i><br />
      Nadat jullie gezamenlijk de RI&amp;E hebben doorgenomen en een Plan van Aanpak hebben opgesteld, kun je via het menu-item "implementatie" de besproken maatregelen met bijbehorende actiedatums inzien.
    </p>
  </>
};
export default ChildminderExplanation;
