import { format, isValid, parse } from "date-fns";
import * as React from "react";

import ChoiceResponse from "../../models/survey/response/ChoiceResponse";
import {
  SurveyResponseAction,
  choiceToggle,
  choiceExplanationChange
} from "../../reducers/SurveyResponseReducer";
import { ExplanationMode } from "../../models/survey/structure/ExplanationMode";
import TextButton from "../../components/ui/TextButton";
import { findDOMNode } from "react-dom";

export enum Mode {
  Create,
  Update
}

export interface ChoiceResponseFormProps {
  choiceResponse: ChoiceResponse;
  readOnly: boolean;
  onSurveyResponse?: (action: SurveyResponseAction) => void;
}

export interface ChoiceResponseFormState {
  showInput: boolean;
}

export default class ChoiceResponseForm extends React.Component<
  ChoiceResponseFormProps,
  ChoiceResponseFormState
  > {
  input?: HTMLElement;

  constructor(props: ChoiceResponseFormProps) {
    super(props);

    this.state = {
      showInput:
        props.choiceResponse.choice.explanationMode == ExplanationMode.Required ||
        !!props.choiceResponse.explanation
    };
  }

  onOuterClick() {
    const choiceResponse = this.props.choiceResponse;

    if (this.props.readOnly === true) {
      return;
    } else {
      this.props.onSurveyResponse(choiceToggle(choiceResponse.choice));
    }
  }

  hideInput() {
    this.setState(prev => {
      return {
        ...prev,
        showInput: false
      };
    });
  }

  showInput() {
    this.setState(prev => {
      return {
        ...prev,
        showInput: true
      };
    });
  }

  onShowInputClick(e: React.MouseEvent) {
    e.stopPropagation();
    this.showInput();
  }

  onInputBlur() {
    let showInput;

    if (this.props.choiceResponse.choice.explanationMode == ExplanationMode.Required) {
      showInput = true;
    } else if (this.props.choiceResponse.explanation) {
      showInput = true;
    } else {
      showInput = false;
    }

    this.setState({
      showInput
    });
  }

  componentDidUpdate(prevProps: ChoiceResponseFormProps, prevState: ChoiceResponseFormState) {
    if (!this.props.choiceResponse.isSelected || !this.state.showInput) {
      return;
    }

    if (this.props.choiceResponse.choice.explanationMode == ExplanationMode.Required) {
      if (!prevProps.choiceResponse.isSelected) {
        this.input.focus();
      }
    } else if (!prevState.showInput) {
      this.input.focus();
    }
  }

  render() {
    const choiceResponse = this.props.choiceResponse;
    const choice = choiceResponse.choice;

    let style: any = {};
    if (this.props.readOnly === true) {
      style.opacity = 0.5;
      style.pointerEvents = "none";
      style.cursor = "not-allowed";
    }

    return (
      <div style={style}>
        <div
          key={choiceResponse.choice.id}
          style={{ display: "flex", alignItems: "flex-start" }}
          className={
            choice.explanationMode == ExplanationMode.Required && !choiceResponse.explanation
              ? "field_with_errors"
              : ""
          }
        >
          <span
            style={{ width: "15px", padding: "10px", display: "inline-block", cursor: "pointer" }}
            onClick={() => {
              this.onOuterClick();
            }}
          >
            <input
              type={choiceResponse.question.allowMultipleChoices ? "checkbox" : "radio"}
              checked={choiceResponse.isSelected}
              name={"choice-" + choiceResponse.choice.id}
              readOnly={true}
            />
          </span>

          <div
            style={{ padding: "10px 10px 10px 0", cursor: "pointer" }}
            onClick={() => {
              this.onOuterClick();
            }}
          >
            <span>
              <span dangerouslySetInnerHTML={{ __html: choiceResponse.choice.title }} />
              {choice.explanationMode == ExplanationMode.Required && (
                <b> (toelichting verplicht)</b>
              )}

              {!this.state.showInput &&
                choiceResponse.isSelected &&
                choice.explanationMode == ExplanationMode.Optional && (
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "7px",
                      marginLeft: "-10px",
                      display: "inline"
                    }}
                  >
                    &nbsp;
                    <TextButton
                      onClick={this.onShowInputClick.bind(this)}
                      label="(indien gewenst toelichten)"
                    />
                  </div>
                )}
            </span>

            {choiceResponse.isSelected &&
              choice.explanationMode != ExplanationMode.Disabled &&
              this.state.showInput && (
                <div style={{ marginTop: "5px" }}>
                  <input
                    style={{
                      width: "100%",
                      cursor: "text",
                      padding: "5px",
                      borderWidth: "1px",
                      maxWidth: "700px"
                    }}
                    type="text"
                    name={choice.title + " explanation"}
                    value={choiceResponse.explanation ? choiceResponse.explanation : ""}
                    onFocus={event => {
                      event.target.select();
                    }}
                    onKeyPress={event => {
                      if (event.key === "Enter") {
                        event.currentTarget.blur();
                      }
                    }}
                    onBlur={this.onInputBlur.bind(this)}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                    readOnly={this.props.readOnly}
                    onChange={e => {
                      if (!this.props.readOnly) {
                        this.props.onSurveyResponse(
                          choiceExplanationChange(e.target.value, choiceResponse.choice)
                        );
                      }
                    }}
                    ref={c => (this.input = c)}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}
