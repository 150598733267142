import DerivedNavigation from "./DerivedNavigation";
import Derivations from "../Derivations";
import { View, NavigateState } from "../../reducers/NavigateReducer";

export default class Evaluation extends DerivedNavigation {
  constructor(derivations: Derivations) {
    super(derivations);
  }

  get previousNavigateState(): NavigateState {
    let previous = this.riskEvaluation ? this.riskEvaluation.previousActiveRiskEvaluation : null;

    return previous ? {
      view: View.Evaluation,
      questionId: null,
      riskId: previous.risk.id
    } : {
        view: View.SurveySection,
        questionId: this.derivations.rieSurveyResponse.lastActiveQuestionResponse ? this.derivations.rieSurveyResponse.lastActiveQuestionResponse.question.id : null,
        riskId: null
      };
  }

  get nextNavigateState(): NavigateState {
    let next = this.riskEvaluation ? this.riskEvaluation.nextActiveRiskEvaluation : null;

    return next ? {
      view: View.Evaluation,
      questionId: null,
      riskId: next.risk.id
    } : {
        view: View.PlanOfAction,
        questionId: null,
        riskId: null
      };
  }
}
