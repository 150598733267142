export default class Context {
  private stores: Map<string,Object>
    = new Map<string, Object>();

  public pendingListeners: Map<string, ((object: Object) => void)[]>
    = new Map<string, ((object: Object) => void)[]>();

  public awaitPublish(globalId: string, callback: (object: Object) => void) {
    let object = this.find(globalId);
    if(object) {
      callback(object);
    } else {
      let callbacks = this.pendingListeners.get(globalId);
      if(!callbacks) {
        callbacks = [];
        this.pendingListeners.set(globalId, callbacks);
      }

      callbacks.push(callback);
    }
  }

  public publish(globalId: string, object: Object) {
    this.stores.set(globalId, object);
  
    let callbacks = this.pendingListeners.get(globalId);
    callbacks = callbacks ? callbacks : [];
    callbacks.forEach(callback => {
      callback(object);
    });
    this.pendingListeners.delete(globalId);
  }

  public find(globalId: string) : Object {
    return this.stores.get(globalId);
  }
}
