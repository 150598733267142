import Choice from '../survey/structure/Choice';
import Question from '../survey/structure/Question';
import MeasureSuggestion from './MeasureSuggestion';
import RieStructure from './RieStructure';
import Section from '../survey/structure/Section';

export default class Risk {
  public id: number;
  public title: string;
  public rieStructure: RieStructure;
  public surveyChoices: Choice[];
  public measureSuggestions: MeasureSuggestion[];

  get previousRisk() : Risk {
    return this.rieStructure.previousRisk(this);
  }

  get nextRisk() : Risk {
    return this.rieStructure.nextRisk(this);
  }

  get section() : Section {
    return this.questions.length > 0 ? this.questions[0].section : null;
  }

  get riskNumber() : number {
    let previous = this.previousRisk;
    return previous && previous.section == this.section ? previous.riskNumber + 1 : 1;
  }

  get questions() : Question[] {
    return this.surveyChoices
      .map(x => x.question)
      .reduce((questions: Question[], question: Question) => {
        if(questions.indexOf(question) == -1) {
          questions.push(question);
        }
        return questions;
      }, []);
  }

  isRelatedToQuestion(question: Question) : boolean {
    return this.questions.find(x => x == question) != null;
  }
}
