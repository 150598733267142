import * as React from 'react';

import RieContainer from '../../containers/RieContainer';
import ObjectHelper from '../../helpers/ObjectHelper';
import Trajectory from '../../models/rie/Trajectory';
import TrajectoryTransfomer from '../../transformers/rie/TrajectoryTransformer';
import User from '../../models/general/User';

export interface RieBootstrapperProps {
  rieTrajectory: object;
  updateURL: string;
  documentsURL: string;
  backURL: string;
  csrfToken: string;
  currentUser: User;
}

export class RieBootstrapper extends React.Component<RieBootstrapperProps> {
  render() {
    let data = this.props.rieTrajectory;
    data = ObjectHelper.keysToCamelCase(data);
    let trajectory: Trajectory = (new TrajectoryTransfomer).transform(data);

    return <RieContainer
      rieStructure={ trajectory.rieStructure }
      initialTrajectoryData={ trajectory }
      metadata={ {
        backURL: this.props.backURL,
        updateURL: this.props.updateURL,
        csrfToken: this.props.csrfToken,
        documentsURL: this.props.documentsURL
      }}
      currentUser={ this.props.currentUser }
    />
  }
}
