import BaseTransformer from "../system/BaseTransformer";
import Condition from "../../models/survey/structure/Condition";
import Question from "../../models/survey/structure/Question";
import Choice from "../../models/survey/structure/Choice";
import QuestionTransformer from "./QuestionTransformer";
import ChoiceTransformer from "./ChoiceTransformer";

export default class ConditionTransformer extends BaseTransformer<Condition> {
  name = "ConditionTransformer"

  create() : Condition {
    return new Condition;
  }

  fill(data: any, condition: Condition) {
    condition.id = data.id;

    (new QuestionTransformer(this.context))
      .awaitPublish(data.questionId, (question: Question) => {
        condition.question = question;
      });

    (new ChoiceTransformer(this.context))
      .awaitPublish(data.choiceId, (choice: Choice) => {
        condition.choice = choice;
      });

    return condition
  }
}
