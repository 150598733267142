import DerivedNavigation from "./DerivedNavigation";
import Derivations from "../Derivations";
import { View, NavigateState } from "../../reducers/NavigateReducer";

export default class Implementation extends DerivedNavigation {
  constructor(derivations: Derivations) {
    super(derivations);
  }

  get previousNavigateState() : NavigateState {
    return {
      view: View.Explanation,
      questionId: null,
      riskId: null
    };
  }

  get nextNavigateState() : NavigateState {
    return null;
  }
}
