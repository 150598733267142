import BaseTransformer from "../system/BaseTransformer";
import Person from "../../models/general/Person";
import Address from "../../models/general/Address";

export default class AddressTransformer extends BaseTransformer<Address> {
  name = "AddressTransformer"

  create() : Address {
    return new Address;
  }

  fill(data: any, address: Address) : Address {
    address.id = data.id;
    address.line1 = data.line1;
    address.line2 = data.line2;

    return address;
  }
}
