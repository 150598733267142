import Choice from './Choice';
import Condition from './Condition';
import Section from './Section';
import Survey from './Survey';

export default class Question {
  public id: number;
  public title: string;
  public description: string;
  public allowMultipleChoices: boolean;
  public choices: Choice[];
  public conditions: Condition[];
  public section: Section;

  get survey(): Survey {
    return this.section ? this.section.survey : null;
  }

  get previousQuestion() : Question {
    return this.survey.previousQuestion(this);
  }

  get nextQuestion() : Question {
    return this.survey.nextQuestion(this);
  }

  get questionNumber() : number {
    let previous = this.previousQuestion;

    return previous && previous.section == this.section ? previous.questionNumber + 1 : 1;
  }
}
