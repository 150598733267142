import { format } from 'date-fns';

import App, { Props, State } from '../App';
import Measure from '../models/rie/Measure';
import { Reducer, StateReduction } from './Reducer';
import { MeasureCategory } from '../models/rie/MeasureCategory';

export const MEASURE_SAVE = 'MEASURE_SAVE';
export type MEASURE_SAVE = typeof MEASURE_SAVE;
export interface MeasureSave {
  type: MEASURE_SAVE,
  data: Measure;
}
export function measureSave(data: Measure) : MeasureSave {
  return {
    type: MEASURE_SAVE,
    data
  };
}

export const MEASURE_DELETE = 'MEASURE_DELETE';
export type MEASURE_DELETE = typeof MEASURE_DELETE;
export interface MeasureDelete {
  type: MEASURE_DELETE;
  key: string;
}
export function measureDelete(key: string) : MeasureDelete {
  return {
    type: MEASURE_DELETE,
    key: key
  };
}

export type MeasureAction =
  MeasureSave |
  MeasureDelete;

export type MeasuresState = {
  id: number,
  key: string,
  riskId?: number,
  description: string,
  deadlineString?: string,
  originalDeadlineString?: string,
  implemented: boolean,
  category: MeasureCategory
}[]

export class MeasureReducer extends Reducer<MeasureAction, MeasuresState> {
  reduce(app: App, prevState: State, action: MeasureAction) : StateReduction {

    switch(action.type) {
      case MEASURE_SAVE:
        return this.handleMeasureSave(app, prevState, action);

      case MEASURE_DELETE:
        return this.handleMeasureDelete(app, prevState, action)

    }
  }

  initialState(props: Props) : MeasuresState {
    let initialTrajectoryData = props.initialTrajectoryData;

    return initialTrajectoryData.measures.map(measure => {
      return {
        id: measure.id,
        key: measure.key,
        riskId: measure.risk ? measure.risk.id : null,
        description: measure.description,
        deadlineString: measure.deadline ? format(measure.deadline, 'yyyy-MM-dd') : null,
        originalDeadlineString: measure.originalDeadline ? format(measure.originalDeadline, 'yyyy-MM-dd') : null,
        implemented: measure.implemented,
        category: measure.category
      };
    });
  }


  private handleMeasureDelete(app: App, prevState: State, action: MeasureDelete) : StateReduction {
    return {
      newState: {
        ...prevState,
        save: {
          ...prevState.save,
          hasUnsavedChanges: true
        },
        measures: prevState.measures.filter(measure => {
          return measure.key != action.key;
        })
      }
    }
  }

  private handleMeasureSave(app: App, prevState: State, action: MeasureSave) : StateReduction {
    const existingItem = prevState.measures.find(x => x.key == action.data.key);
    const index = existingItem ? prevState.measures.indexOf(existingItem) : -1;

    if(index == -1) {
      return {
        newState: {
          ...prevState,
          save: {
            ...prevState.save,
            hasUnsavedChanges: true
          },
          measures: prevState.measures.concat([{
            id: action.data.id,
            key: action.data.key,
            riskId: action.data.risk ? action.data.risk.id : null,
            description: action.data.description,
            deadlineString: action.data.deadline ? format(action.data.deadline, 'yyyy-MM-dd') : null,
            originalDeadlineString: action.data.originalDeadline ? format(action.data.originalDeadline, 'yyyy-MM-dd') : null,
            implemented: action.data.implemented,
            category: action.data.category
          }])
        }
      };
    } else {
      return {
        newState: {
          ...prevState,
          save: {
            ...prevState.save,
            hasUnsavedChanges: true
          },
          measures: prevState.measures.map(item => {
            if(item.key != action.data.key) {
              return item;
            } else {
              return {
                ...item,
                riskId: action.data.risk ? action.data.risk.id : null,
                description: action.data.description,
                deadlineString: action.data.deadline ? format(action.data.deadline, 'yyyy-MM-dd') : null,
                originalDeadlineString: action.data.originalDeadline ? format(action.data.originalDeadline, 'yyyy-MM-dd') : null,
                implemented: action.data.implemented,
                category: action.data.category
              }
            }
          })
        }
      }
    }
  }
}
