export enum ExplanationMode {
  Disabled = 'disabled',
  Optional = 'optional',
  Required = 'required'
};

const weights: [ExplanationMode, number][] = [
  [ExplanationMode.Disabled, 0],
  [ExplanationMode.Optional, 1],
  [ExplanationMode.Required, 2]
];

let getWeight = (explanationMode: ExplanationMode) : number => {
  return weights.find((tuple: [ExplanationMode, number]) => {
    return tuple[0] == explanationMode;
  })[1];
}

export const explanationModeComparator = (a: ExplanationMode, b: ExplanationMode) => {
  return getWeight(a) - getWeight(b);
};
