import Survey from '../structure/Survey';
import ChoiceResponse from './ChoiceResponse';
import QuestionResponse from './QuestionResponse';
import SectionResponse from './SectionResponse';
import Question from '../structure/Question';

export default class SurveyResponse {
  public id: number;
  public questionResponses: QuestionResponse[] = [];
  public survey: Survey;
  public sent: boolean;


  get isStrictlyCompleted(): boolean {
    return this.questionResponses.find((questionResponse) => {
      return !questionResponse.isCompleted
    }) == null;
  }

  get canBeSent(): boolean {
    return this.questionResponses.find((questionResponse) => {
      return !questionResponse.isCompletedOrSkipped
    }) == null;
  }

  get sectionResponses(): SectionResponse[] {
    return this.survey.sections.map(section => {
      let sectionResponse = new SectionResponse;
      sectionResponse.section = section;
      sectionResponse.surveyResponse = this;
      return sectionResponse;
    });
  }

  get choiceResponses(): ChoiceResponse[] {
    return this.questionResponses.map(questionResponse => {
      return questionResponse.choiceResponses;
    }).reduce((allChoiceResponses, choiceResponses) => allChoiceResponses.concat(choiceResponses));
  }

  get activeQuestionResponses(): QuestionResponse[] {
    return this.questionResponses.filter(x => x.isActive);
  }

  get firstActiveQuestionResponse(): QuestionResponse {
    let activeQuestionResponses = this.activeQuestionResponses;
    return activeQuestionResponses.length > 0 ? activeQuestionResponses[0] : null;
  }

  get lastActiveQuestionResponse(): QuestionResponse {
    let activeQuestionResponses = this.activeQuestionResponses;
    return activeQuestionResponses.length > 0 ? activeQuestionResponses[activeQuestionResponses.length - 1] : null;
  }

  getQuestionResponse(question: Question): QuestionResponse {
    return this.questionResponses.find(x => x.question.id === question.id);
  }

  getQuestionsResponses(questions: Question[]): QuestionResponse[] {
    return questions.map(this.getQuestionResponse.bind(this));
  }
}
