import * as React from "react";

import QuestionResponse from "../../models/survey/response/QuestionResponse";
import Question from "../../models/survey/structure/Question";
import { SurveyResponseAction } from "../../reducers/SurveyResponseReducer";
import QuestionForm from "./QuestionForm";
import QuestionNavigation from "./QuestionNavigation";
import SectionResponse from "../../models/survey/response/SectionResponse";

export interface SectionViewProperties {
  sectionResponse: SectionResponse;
  onSurveyResponse: (action: SurveyResponseAction) => void;
  allowSkipCondition?: (questionResponse: QuestionResponse) => boolean;
  checkmarkCondition?: (questionResponse: QuestionResponse) => boolean;
  renderTitle?: (questionResponse: QuestionResponse) => JSX.Element;
  renderDescription?: (questionResponse: QuestionResponse) => JSX.Element;
}

const SectionView: React.SFC<SectionViewProperties> = ({
  sectionResponse,
  onSurveyResponse,
  renderTitle = questionResponse => {
    return (
      <>
        <b
          style={{ margin: "10px 0 6px 0", fontSize: "115%", display: "block" }}
          dangerouslySetInnerHTML={{ __html: questionResponse.question.title }}
        />
      </>
    );
  },
  renderDescription = undefined
}) => {
  return (
    <>
      {sectionResponse.questionResponses
        .filter(x => x.isActive)
        .map(questionResponse => {
          return (
            <div style={{ margin: "0 0 16px 0" }} key={questionResponse.question.id}>
              <QuestionForm
                questionResponse={questionResponse}
                onSurveyResponse={onSurveyResponse}
                renderDescription={renderDescription}
                renderTitle={renderTitle}
              />
            </div>
          );
        })}
    </>
  );
};
export default SectionView;
