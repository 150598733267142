import * as React from "react";

import App from "../../../App";
import Question from "../../../models/survey/structure/Question";
import { navigateQuestion, navigateRisk, View } from "../../../reducers/NavigateReducer";
import PagedSectionView from "../../survey/PagedSectionView";
import RiskReference from "../partials/RiskReference.tsx";
import RiePage from "../template/RiePage";
import { RieContextConsumer } from "../../../contexts/rie/RieContext";
import { SurveyContextProvider, SurveyContextConsumer } from "../../../contexts/rie/SurveyContext";
import Button from "../../ui/Button";
import { navigateNext, navigatePrevious } from "../../../reducers/NavigateReducer";
import { saveAll } from "../../../reducers/SaveReducer";
import SaveButton from "../../ui/SaveButton";
import rieQuestionHTML from "../../../helpers/rieQuestionHTML";

export interface RieSurveySectionViewProps {
  app: App;
}

const RieSurveySectionView: React.SFC<RieSurveySectionViewProps> = props => {
  const handlers = props.app.handlers;
  const questionResponse = props.app.derivations.navigation.questionResponse;
  const trajectory = props.app.derivations.trajectory;
  const riskEvaluations = trajectory.riskEvaluations.filter(
    x => x.isActive && x.isRelatedToQuestionResponse(questionResponse)
  );

  const currentUser = props.app.derivations.currentUser;

  return (
    <RiePage
      app={props.app}
      key={questionResponse.question.id}
      title={questionResponse.sectionResponse.section.title}
      renderContent={() => (
        <RieContextConsumer>
          {ctx => (
            <SurveyContextProvider value={ctx.surveyContext}>
              <PagedSectionView
                sectionResponse={props.app.derivations.navigation.questionResponse.sectionResponse}
                selectedQuestionResponse={props.app.derivations.navigation.questionResponse}
                onNavigateQuestion={(question: Question) => {
                  props.app.handlers.navigate(navigateQuestion(question));
                }}
                onSurveyResponse={handlers.surveyResponse.bind(handlers)}
                checkmarkCondition={questionResponse =>
                  currentUser.isCustomer
                    ? questionResponse.isCompletedOrSkipped
                    : questionResponse.isCompleted
                }
                allowSkipCondition={questionReasponse =>
                  ctx.surveyContext.questionResponseAllowSkip(questionResponse)
                }
                readOnly={currentUser.isCustomer && props.app.derivations.rieSurveyResponse.sent}
                renderTitle={questionResponse => {
                  return (
                    <h2>
                      {questionResponse.question.questionNumber}.{" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: rieQuestionHTML(
                            questionResponse.question.title,
                            props.app.derivations.trajectory
                          )
                        }}
                      />
                    </h2>
                  );
                }}
                renderDescription={questionResponse => {
                  return (
                    <div
                      style={{ margin: "0 0 10px 0", lineHeight: "22px" }}
                      dangerouslySetInnerHTML={{
                        __html: rieQuestionHTML(
                          questionResponse.question.description,
                          props.app.derivations.trajectory
                        )
                      }}
                    />
                  );
                }}
              />
              {ctx.visibleViews.find(x => x == View.Evaluation) && (
                <RiskReference
                  riskEvaluations={riskEvaluations}
                  onRiskClick={risk => {
                    handlers.navigate(navigateRisk(risk));
                  }}
                />
              )}

              <div style={{ marginBottom: "35px" }} />

              <Button
                label="Vorige"
                isEnabled={props.app.derivations.navigation.canNavigatePrevious}
                onClick={() => props.app.handlers.navigate(navigatePrevious())}
              />

              <Button
                label="Volgende"
                isEnabled={props.app.derivations.navigation.canNavigateNext}
                onClick={() => props.app.handlers.navigate(navigateNext())}
              />

              <SaveButton
                isSaving={props.app.state.save.isSaving}
                onSave={() => {
                  handlers.save(saveAll());
                }}
                hasUnsavedChanges={props.app.state.save.hasUnsavedChanges}
              />
            </SurveyContextProvider>
          )}
        </RieContextConsumer>
      )}
    />
  );
};
export default RieSurveySectionView;
