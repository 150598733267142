import * as React from "react";

import QuestionResponse from "../../models/survey/response/QuestionResponse";
import Question from "../../models/survey/structure/Question";
import { SurveyResponseAction } from "../../reducers/SurveyResponseReducer";
import QuestionForm from "./QuestionForm";
import QuestionNavigation from "./QuestionNavigation";
import SectionResponse from "../../models/survey/response/SectionResponse";
import { SurveyContextProvider, SurveyContextConsumer } from "../../contexts/rie/SurveyContext";

export interface PagedSectionViewProperties {
  sectionResponse: SectionResponse;
  selectedQuestionResponse: QuestionResponse;
  onNavigateQuestion: (question: Question) => void;
  onSurveyResponse: (action: SurveyResponseAction) => void;
  readOnly?: boolean;
  allowSkipCondition?: (questionResponse: QuestionResponse) => boolean;
  checkmarkCondition?: (questionResponse: QuestionResponse) => boolean;
  renderTitle?: (questionResponse: QuestionResponse) => JSX.Element;
  renderDescription?: (questionResponse: QuestionResponse) => JSX.Element;
}

const PagedSectionView: React.SFC<PagedSectionViewProperties> = ({
  sectionResponse,
  selectedQuestionResponse,
  onNavigateQuestion,
  onSurveyResponse,
  readOnly = false,
  allowSkipCondition = () => true,
  checkmarkCondition = null,
  renderTitle = undefined,
  renderDescription = undefined
}) => {
  return (
    <>
      <QuestionNavigation
        questionResponses={sectionResponse.questionResponses}
        selectedQuestionResponse={selectedQuestionResponse}
        onNavigateQuestion={onNavigateQuestion}
        checkmarkCondition={checkmarkCondition}
      />

      <QuestionForm
        questionResponse={selectedQuestionResponse}
        onSurveyResponse={onSurveyResponse}
        readOnly={readOnly}
        allowSkip={allowSkipCondition(selectedQuestionResponse)}
        renderTitle={renderTitle}
        renderDescription={renderDescription}
      />
    </>
  );
};
export default PagedSectionView;
