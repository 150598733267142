import * as React from "react"
import { MenuItemProps } from "./MenuItem";

export interface MenuProps {
  children: React.ReactElement<MenuItemProps>[];
}

const Menu: React.SFC<MenuProps> = (props) => {
  return (
    <div className="page_menu">
      <ul>
        { props.children }
      </ul>
    </div>
  )
}
export default Menu;
