import * as React from 'react';

import App from '../../../App';
import MeasuresForm from '../../../containers/rie/MeasuresForm';
import Risk from '../../../models/rie/Risk';
import { navigateQuestion, navigateRisk } from '../../../reducers/NavigateReducer';
import RiskEvaluationNavigation from '../partials/RiskEvaluationNavigation';
import RiskQuestionReference from '../partials/RiskQuestionReference';
import RiePage from '../template/RiePage';

export interface RieSurveySectionViewProps {
  app: App;
}

const RieEvaluationView: React.SFC<RieSurveySectionViewProps> = (props) => {
  const handlers = props.app.handlers;
  const riskEvaluation = props.app.derivations.navigation.riskEvaluation;
  const trajectory = props.app.derivations.trajectory;
  const riskEvaluations = riskEvaluation ? trajectory.riskEvaluations.filter(x => x.section == riskEvaluation.risk.section) : [];

  return (
    <RiePage
      app={ props.app }
      title="Evalueren"
      key={ riskEvaluation ? riskEvaluation.risk.id : null }
      renderContent={ () => (
        <>
          { riskEvaluation == null ? (
            trajectory.inventorizationCompleted ? (
              <div>✓ Er zijn geen risico's geconstateerd.</div>
            ) : (
              <div>Er zijn geen risico's geconstateerd. Let op: de inventarisatie is nog niet afgerond.</div>
            )
          ) : (
            <>
              <RiskEvaluationNavigation
                riskEvaluations={ riskEvaluations }
                currentRisk={ props.app.derivations.navigation.risk }
                onNavigateRisk={ (risk: Risk) => { handlers.navigate(navigateRisk(risk)) }}
              />

              <h2>{ riskEvaluation.risk.riskNumber }. { riskEvaluation.risk.title }</h2>
              <RiskQuestionReference
                riskEvaluation={ riskEvaluation }
                onNavigateQuestion={ question => { handlers.navigate(navigateQuestion(question)); } }
              />

              <MeasuresForm
                renderTitle={ () => {
                  return (
                    <div style={ { fontWeight: 'bold' } }>Maatregelen/afspraken</div>
                  )
                } }
                renderEmptyList={ () => {
                  return (
                    <div style={ { marginTop: '3px', fontStyle: 'italic', color: '#900' } }>
                      Er zijn nog geen maatregelen gepland.
                    </div>
                  )
                }}
                measures={ riskEvaluation.measures }
                measureSuggestions={ riskEvaluation.remainingMeasureSuggestions }
                risk={ riskEvaluation.risk }
                trajectory={ riskEvaluation.trajectory }
                onMeasureAction={ handlers.measureAction.bind(handlers) }
              />
            </>
          )}
        </>
      )}
    />
  )
}
export default RieEvaluationView;
