import { format } from 'date-fns';
import * as React from 'react';

import Document from '../../../models/rie/Document';

export interface DocumentListProps {
  documents: Document[]
}

const DocumentList: React.SFC<DocumentListProps> = ({
  documents
}) => {
  return <>
    <ul className="margin-top-xxxs">
      { documents.map(document =>
        <li key={ document.id }>
          - <a href={ document.downloadPathInline } target="_blank">{ document.type }</a>
        </li>
      )}
    </ul>
  </>
};
export default DocumentList;
