import Address from "./Address";

export default class Person {
  id: number;
  path: string;
  fullName: string;
  address: Address
  phone1: string;
  phone2: string;
  email: string;
}
