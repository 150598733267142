import App, { Props, State } from "../App";
import { Reducer, StateReduction } from "./Reducer";
import { saveAll } from "./SaveReducer";
import { navigateView, View } from "./NavigateReducer";

export const RIE_CHANGE = "RIE_CHANGE";
export type RIE_CHANGE = typeof RIE_CHANGE;
export interface RieChange {
  type: RIE_CHANGE;
  rieDate?: Date;
  extraInfo?: string;
}
export function rieChange(props: { rieDate?: Date; extraInfo?: string }): RieChange {
  return {
    type: RIE_CHANGE,
    ...props
  };
}

export const RIE_FINALIZE = "RIE_FINALIZE";
export type RIE_FINALIZE = typeof RIE_FINALIZE;
export interface RieFinalize {
  type: RIE_FINALIZE;
  childminderSignature: string;
  franchiseeSignature: string;
}
export function rieFinalize({
  childminderSignature,
  franchiseeSignature
}: {
  childminderSignature: string;
  franchiseeSignature: string;
}): RieFinalize {
  return {
    type: RIE_FINALIZE,
    childminderSignature,
    franchiseeSignature
  };
}

export type RieAction = RieChange | RieFinalize;

export type RieState = {
  rieDate: Date;
  extraInfo: string;
  finalizing: boolean;
  finalized: boolean;
  referenceCode: string;
  documents: DocumentState[];
  childminderSignature: string;
  franchiseeSignature: string;
};

export type DocumentState = {
  id: number;
  downloadPathInline: string;
  type: string;
};

export class RieReducer extends Reducer<RieAction, RieState> {
  reduce(app: App, prevState: State, action: RieAction): StateReduction {
    switch (action.type) {
      case RIE_CHANGE:
        return this.handleRieChange(app, prevState, action);

      case RIE_FINALIZE:
        return this.handleRieFinalize(app, prevState, action);
    }
  }

  initialState(props: Props): RieState {
    return {
      rieDate: props.initialTrajectoryData.rieDate,
      extraInfo: props.initialTrajectoryData.extraInfo,
      finalizing: false,
      finalized: props.initialTrajectoryData.finalized,
      referenceCode: props.initialTrajectoryData.referenceCode,
      documents: props.initialTrajectoryData.documents.map(document => {
        return {
          id: document.id,
          downloadPathInline: document.downloadPathInline,
          type: document.type
        };
      }),
      franchiseeSignature: null,
      childminderSignature: null
    };
  }

  private handleRieChange(app: App, prevState: State, action: RieChange): StateReduction {
    return {
      newState: {
        ...prevState,
        save: {
          ...prevState.save,
          hasUnsavedChanges: true
        },
        rie: {
          ...prevState.rie,
          ...(() => {
            const a = {
              ...action
            };

            delete a.type;

            return a;
          })()
        }
      }
    };
  }

  private handleRieFinalize(app: App, prevState: State, action: RieFinalize): StateReduction {
    return {
      newState: {
        ...prevState,
        rie: {
          ...prevState.rie,
          finalizing: true,
          franchiseeSignature: action.franchiseeSignature,
          childminderSignature: action.childminderSignature
        }
      },
      async: (getCurrentState: () => State, resolve: (reduction: StateReduction) => void) => {
        app.handlers.save(saveAll()).then(() => {
          app.handlers.navigate(navigateView(View.Implementation));
        });
      }
    };
  }
}
