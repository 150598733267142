import * as React from 'react';

import App from '../../../App';
import { navigateNext, navigatePrevious } from '../../../reducers/NavigateReducer';
import { saveAll } from '../../../reducers/SaveReducer';
import Button from '../../ui/Button';
import Footer from '../../ui/Footer';
import SaveButton from '../../ui/SaveButton';

export interface RieFooterProperties {
  app: App
}

const RieFooter: React.SFC<RieFooterProperties> = (props) => {
  const handlers = props.app.handlers;

  return (
    <Footer>
      <Button
        label="Vorige"
        isEnabled={ props.app.derivations.navigation.canNavigatePrevious }
        onClick={ () => handlers.navigate(navigatePrevious()) }
      />
      <Button
        label="Volgende"
        isEnabled={ props.app.derivations.navigation.canNavigateNext }
        onClick={ () => handlers.navigate(navigateNext()) }
      />
      <SaveButton
        isSaving={ props.app.state.save.isSaving }
        onSave={ () => { handlers.save(saveAll()) } }
        hasUnsavedChanges={ props.app.state.save.hasUnsavedChanges }
      />
    </Footer>
  )
}
export default RieFooter;
