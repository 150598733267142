import { NavigateState, View } from "../../reducers/NavigateReducer";
import QuestionResponse from "../../models/survey/response/QuestionResponse";
import { State, Props } from "../../App";
import Question from "../../models/survey/structure/Question";
import Derivations from "../Derivations";
import SectionResponse from "../../models/survey/response/SectionResponse";
import Section from "../../models/survey/structure/Section";
import Risk from "../../models/rie/Risk";
import RiskEvaluation from "../../models/rie/RiskEvaluation";
import Survey from "../../models/survey/structure/Survey";

export type DerivedNavigateState = {
  view: View;
  questionResponse: QuestionResponse;
  riskEvaluation: RiskEvaluation;
}

export default abstract class DerivedNavigation {
  protected derivations: Derivations;

  constructor(derivations: Derivations) {
    this.derivations = derivations;
  }

  abstract get previousNavigateState(): NavigateState;
  abstract get nextNavigateState(): NavigateState;

  get state(): State {
    return this.derivations.state;
  }

  get survey(): Survey {
    return this.section ? this.section.survey : null;
  }

  get section(): Section {
    return this.question ? this.question.section : null;
  }

  get sectionResponse(): SectionResponse {
    return this.questionResponse ? this.questionResponse.sectionResponse : null;
  }

  get view(): View {
    return this.state.navigate.view;
  }

  get question(): Question {
    let questionId = this.state.navigate.questionId;

    if (!questionId) {
      return null
    }

    return this.derivations.trajectory.questions.find(x => x.id == questionId);
  }

  get questionResponse(): QuestionResponse {
    if (!this.question) {
      return null;
    }

    return this.derivations.trajectory.questionResponses.find(x => x.question.id == this.question.id);
  }

  get risk(): Risk {
    let riskId = this.state.navigate.riskId;

    if (!riskId) {
      return null;
    }

    return this.derivations.trajectory.rieStructure.risks.find(x => x.id == riskId);
  }

  get riskEvaluation(): RiskEvaluation {
    if (!this.risk) {
      return null;
    }

    return this.derivations.trajectory.riskEvaluations.find(x => x.risk == this.risk);
  }

  get canNavigatePrevious(): boolean {
    return this.previousNavigateState != null;
  }

  get canNavigateNext(): boolean {
    return this.nextNavigateState != null;
  }

  viewVisible(view: View): boolean {
    return !!this.derivations.context.visibleViews.find(x => x === view);
  }
}
