import Trajectory from './Trajectory';
import Section from '../survey/structure/Section';
import RiskEvaluation from './RiskEvaluation';
import SectionResponse from '../survey/response/SectionResponse';

export default class RiskEvaluationSection {
  section: Section;
  trajectory: Trajectory;

  get riskEvaluations(): RiskEvaluation[] {
    return this.trajectory.riskEvaluations.filter(x => {
      return x.section == this.section;
    });
  }

  get activeRiskEvaluations(): RiskEvaluation[] {
    return this.trajectory.activeRiskEvaluations.filter(x => {
      return x.section == this.section;
    })
  }

  get firstActiveRiskEvaluation(): RiskEvaluation {
    let activeRiskEvaluations = this.activeRiskEvaluations;
    return activeRiskEvaluations.length > 0 ? activeRiskEvaluations[0] : null;
  }

  get sectionResponse(): SectionResponse {
    return this.trajectory.rieSurveyResponse.sectionResponses.find(x => x.section == this.section);
  }

  get completed(): boolean {
    return this.sectionResponse.isCompleted && this.activeRiskEvaluations.find(x => !x.isCompleted) == null;
  }
}
