import * as React from 'react';
import SurveyResponse from '../../models/survey/response/SurveyResponse';
import SectionResponse from '../../models/survey/response/SectionResponse';
import QuestionResponse from '../../models/survey/response/QuestionResponse';
import ChoiceResponse from '../../models/survey/response/ChoiceResponse';
import { ExplanationMode } from '../../models/survey/structure/ExplanationMode';
import Question from '../../models/survey/structure/Question';

export class SurveyContext {

  public consideredDone(object: SurveyResponse | SectionResponse | QuestionResponse | ChoiceResponse) : boolean {
    if(object instanceof ChoiceResponse) {
      return this.choiceResponseConsideredDone(object);
    } else if(object instanceof QuestionResponse) {
      return this.questionResponseConsideredDone(object);
    } else if(object instanceof SectionResponse) {
      return this.sectionResponseConsideredDone(object);
    } else if(object instanceof SurveyResponse) {
      return this.surveyResponseConsideredDone(object);
    } else {
      return true;
    }
  }

  public questionResponseAllowSkip(questionResponse: QuestionResponse) : boolean {
    return true;
  }

  public surveyResponseConsideredDone(surveyResponse: SurveyResponse) : boolean {
    const allSectionResponsesConsideredDone = surveyResponse.sectionResponses.find(sectionResponse => {
      return !this.sectionResponseConsideredDone(sectionResponse)
    }) == null;

    return allSectionResponsesConsideredDone;
  }

  public sectionResponseConsideredDone(sectionResponse: SectionResponse) : boolean {
    const allQuestionResponsesConsideredDone = sectionResponse.questionResponses.find(questionResponse => {
      return !this.questionResponseConsideredDone(questionResponse)
    }) == null;

    return allQuestionResponsesConsideredDone;
  }

  public questionResponseConsideredDone(questionResponse: QuestionResponse) : boolean {
    const notActive = !questionResponse.isActive;
    const skipped = questionResponse.skipped && this.questionResponseAllowSkip(questionResponse);
    const atLeastOneSelected = questionResponse.selectedChoiceResponses.length > 0;
    const allChoicesConsideredDone = questionResponse.choiceResponses.find(choiceResponse => {
      return !this.choiceResponseConsideredDone(choiceResponse)
    }) == null;

    return notActive || skipped || (atLeastOneSelected && allChoicesConsideredDone);
  }

  public choiceResponseConsideredDone(choiceResponse: ChoiceResponse) {
    return !choiceResponse.isSelected || choiceResponse.choice.explanationMode != ExplanationMode.Required || !!choiceResponse.explanation;
  }

  public choiceResponseVisible(choiceResponse: ChoiceResponse) {
    return true;
  }
}

const ctxt = React.createContext<SurveyContext>(new SurveyContext());

export const SurveyContextProvider = ctxt.Provider;
export const SurveyContextConsumer = ctxt.Consumer;
