import { SurveyResponsesState, SurveyResponseState } from './../reducers/SurveyResponseReducer';
import { Props, State } from "../App";
import Trajectory from "../models/rie/Trajectory";
import SurveyResponse from "../models/survey/response/SurveyResponse";
import Measure from "../models/rie/Measure";
import Document from "../models/rie/Document";
import ChoiceResponse from "../models/survey/response/ChoiceResponse";
import QuestionResponse from "../models/survey/response/QuestionResponse";

export default class DerivedTrajectory {
  private _trajectory: Trajectory;

  public constructor(props: Props, state: State) {
    let trajectory = new Trajectory;
    let initTrajectory = props.initialTrajectoryData;

    trajectory.id = initTrajectory.id;
    trajectory.rieDate = state.rie.rieDate;
    trajectory.extraInfo = state.rie.extraInfo;
    trajectory.repeatedFromTrajectoryId = initTrajectory.repeatedFromTrajectoryId;
    trajectory.finalized = state.rie.finalized;
    trajectory.referenceCode = state.rie.referenceCode;
    trajectory.rieStructure = initTrajectory.rieStructure;

    const rieResponseState = state.surveyResponses.find(x => x.reference === 'RIE');
    trajectory.rieSurveyResponse = this.deriveSurveyResponse(props.initialTrajectoryData.rieSurveyResponse, rieResponseState);

    const qualityRequirementsResponseState = state.surveyResponses.find(x => x.reference === 'QUALITY_REQUIREMENTS');
    if (qualityRequirementsResponseState) {
      trajectory.qualityRequirementsSurveyResponse = this.deriveSurveyResponse(props.initialTrajectoryData.qualityRequirementsSurveyResponse, qualityRequirementsResponseState);
    } else {
      trajectory.qualityRequirementsSurveyResponse = null;
    }

    trajectory.measures = this.deriveMeasures(props, state, trajectory);
    trajectory.documents = this.deriveDocuments(props, state, trajectory);
    trajectory.facility = initTrajectory.facility;
    trajectory.franchisee = initTrajectory.franchisee;

    this._trajectory = trajectory;
  }

  get trajectory(): Trajectory {
    return this._trajectory;
  }

  private deriveSurveyResponse(initSurveyResponse: SurveyResponse, surveyResponseState: SurveyResponseState): SurveyResponse {
    let survey = initSurveyResponse.survey;
    let surveyResponse = new SurveyResponse;

    surveyResponse.id = initSurveyResponse.id;
    surveyResponse.sent = surveyResponseState.sent;
    surveyResponse.survey = initSurveyResponse.survey;

    surveyResponse.questionResponses = survey.questions.map(question => {
      let initQuestionResponse = initSurveyResponse.questionResponses.find(x => x.question.id == question.id);
      let questionResponse = new QuestionResponse;
      let questionResponseState = surveyResponseState.questionResponses.find(x => x.questionId == question.id);

      questionResponse.id = initQuestionResponse ? initQuestionResponse.id : null;
      questionResponse.skipped = questionResponseState.skipped;
      questionResponse.question = question;
      questionResponse.surveyResponse = surveyResponse;

      questionResponse.choiceResponses = question.choices.map(choice => {
        let initChoiceResponse = initQuestionResponse ? initQuestionResponse.choiceResponses.find(x => x.choice.id == choice.id) : null;
        let choiceResponse = new ChoiceResponse;
        let choiceResponseState = questionResponseState.choiceResponses.find(x => x.choiceId == choice.id);

        choiceResponse.id = initChoiceResponse ? initChoiceResponse.id : null;
        choiceResponse.choice = choice;
        choiceResponse.questionResponse = questionResponse;
        choiceResponse.isSelected = choiceResponseState.isSelected;
        choiceResponse.explanation = choiceResponseState.explanation;

        return choiceResponse;
      });

      return questionResponse;
    });

    return surveyResponse;
  }

  private deriveMeasures(props: Props, state: State, trajectory: Trajectory): Measure[] {
    const rieStructure = props.initialTrajectoryData.rieStructure;

    return state.measures.map(data => {
      let measure = new Measure;

      measure.id = data.id;
      measure.key = data.key;
      measure.description = data.description;
      measure.deadline = data.deadlineString ? new Date(data.deadlineString) : null;
      measure.originalDeadline = data.originalDeadlineString ? new Date(data.originalDeadlineString) : null;
      measure.risk = rieStructure.risks.find(x => x.id == data.riskId);
      measure.trajectory = trajectory;
      measure.implemented = data.implemented;
      measure.category = data.category;

      return measure;
    });
  }

  private deriveDocuments(props: Props, state: State, trajectory: Trajectory): Document[] {
    return state.rie.documents.map(data => {
      const document = new Document;

      document.id = data.id;
      document.type = data.type;
      document.downloadPathInline = data.downloadPathInline;

      return document;
    });
  }
}
