import * as React from "react";

import App from "../../../App";
import RiePage from "../template/RiePage";
import { navigateQuestion } from "../../../reducers/NavigateReducer";
import TextButton from "../../ui/TextButton";
import { clearQuestion, clearQuestions } from "../../../reducers/SurveyResponseReducer";

export interface RieAttentionPointsProps {
  app: App;
}

const RieAttentionPointsView: React.SFC<RieAttentionPointsProps> = props => {
  const trajectory = props.app.derivations.trajectory;
  const handlers = props.app.handlers;

  const relevantAttentionPoints = trajectory.relevantAttentionPoints;

  return (
    <RiePage
      app={props.app}
      title="Aandachtspunten"
      renderContent={() => (
        <>
          <style>
            {`.questionResponse {
                cursor: pointer;
              }

              .questionResponse:hover {
                opacity: 0.7;
              }`}
          </style>
          {relevantAttentionPoints.length === 0 && <i>Er zijn geen extra aandachtspunten.</i>}
          {relevantAttentionPoints.length > 0 && (
            <p>Zijn er wijzigingen/aanpassingen geweest in de volgende ruimtes en/of situaties:</p>
          )}
          {relevantAttentionPoints.map(attentionPoint => (
            <div key={attentionPoint.id}>
              <div
                style={{
                  marginBottom: "5px",
                  fontWeight: "bold"
                }}
              >
                {attentionPoint.title}
                {!attentionPoint.isEmpty(trajectory.rieSurveyResponse) && (
                  <TextButton
                    onClick={() => {
                      handlers.surveyResponse(clearQuestions(attentionPoint.surveyQuestions));
                    }}
                    label="(leegmaken)"
                  />
                )}
              </div>

              <ul style={{ marginTop: 0 }}>
                {attentionPoint.surveyQuestions.map(question => {
                  const questionResponse = trajectory.rieSurveyResponse.questionResponses.find(
                    x => x.question.id === question.id
                  );

                  if (!questionResponse.isActive) return false;

                  return (
                    <li
                      style={{
                        padding: "10px 0",
                        color: !questionResponse.isCompleted ? "rgb(153, 0, 0)" : undefined
                      }}
                      key={question.id}
                    >
                      <span
                        className="questionResponse"
                        onClick={() => {
                          questionResponse.isActive &&
                            handlers.navigate(navigateQuestion(question));
                        }}
                      >
                        {questionResponse.isCompleted ? "✓ " : "× "}
                        {question.title}
                      </span>

                      {questionResponse.isCompleted && (
                        <TextButton
                          onClick={() => {
                            handlers.surveyResponse(clearQuestion(questionResponse.question));
                          }}
                          label="(leegmaken)"
                        />
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
        </>
      )}
    />
  );
};
export default RieAttentionPointsView;
