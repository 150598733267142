import * as React from 'react';

interface ButtonProps {
  label: string;
  isEnabled?: boolean;
  onClick: (e: React.MouseEvent) => void;
}

const TextButton: React.SFC<ButtonProps> = (props) => {
  let isEnabled = props.isEnabled == null ? true : props.isEnabled;

  if(isEnabled) {
    return (
      <a
        href="#"
        style={ { padding: '10px'} }
        onClick={ (e) => { e.preventDefault(); props.onClick(e)}}
      >
        { props.label }
      </a>
    );
  } else {
    return (
      <span>{ props.label }</span>
    )
  }
}
export default TextButton;
