import DerivedNavigation from "./DerivedNavigation";
import Derivations from "../Derivations";
import { View, NavigateState } from "../../reducers/NavigateReducer";
import RieSurveySection from "./RieSurveySection";
import QualityRequirementsSurveySection from "./QualityRequirementsSurveySection";

export default class SurveySection extends DerivedNavigation {
  constructor(derivations: Derivations) {
    super(derivations);
  }

  get previousNavigateState(): NavigateState {
    return this.surveySectionInstance.previousNavigateState;
  }

  get nextNavigateState(): NavigateState {
    return this.surveySectionInstance.nextNavigateState;
  }

  private get surveySectionInstance(): DerivedNavigation {
    switch (this.survey.reference) {
      case 'RIE':
        return new RieSurveySection(this.derivations);

      case 'QUALITY_REQUIREMENTS':
        return new QualityRequirementsSurveySection(this.derivations);

      default:
        throw new Error(`No registered navigation deriver for survey with reference ${this.survey.reference}`);
    }
  }
}
