import DerivedNavigation from "./DerivedNavigation";
import Derivations from "../Derivations";
import { View, NavigateState } from "../../reducers/NavigateReducer";

export default class Explanation extends DerivedNavigation {
  constructor(derivations: Derivations) {
    super(derivations);
  }

  get previousNavigateState(): NavigateState {
    return null;
  }

  get nextNavigateState(): NavigateState {
    if (this.derivations.trajectory.finalized) {
      return {
        view: View.Implementation,
        questionId: null,
        riskId: null
      };
    } else {
      let surveyResponse = this.derivations.trajectory.surveyResponses[0];
      if (surveyResponse.survey.reference === 'QUALITY_REQUIREMENTS' && !this.derivations.context.shouldShowQualityRequirements) {
        surveyResponse = this.derivations.trajectory.surveyResponses[1];
      }

      return {
        view: View.SurveySection,
        questionId: surveyResponse.questionResponses[0].question.id,
        riskId: null
      };
    }
  }
}
