import Question from '../survey/structure/Question';
import SurveyResponse from '../survey/response/SurveyResponse';

export default class AttentionPoint {
  public id: number;
  public title: string;
  public surveyQuestions: Question[];

  public isEmpty(surveyResponse: SurveyResponse): boolean {
    return !surveyResponse.getQuestionsResponses(this.surveyQuestions).find(questionResponse => !questionResponse.isEmpty);
  }
}
