import BaseTransformer from "../system/BaseTransformer";
import Document from "../../models/rie/Document";

export default class DocumentTransformer extends BaseTransformer<Document> {
  name = "DocumentTransformer"

  create() : Document {
    return new Document;
  }

  fill(data: any, document: Document) : Document {
    document.id = data.id;
    document.type = data.type
    document.downloadPathInline = data.downloadPathInline

    return document;
  }
}
