import Context from "./Context";

export default abstract class BaseTransformer<T> {
  name = ""

  private _context: Context;
  get context() : Context {
    return this._context;
  }

  private isRootTransfomer: boolean;

  public constructor(context: Context = null) {
    this.isRootTransfomer = context == null;
    this._context = context ? context : new Context;
  }

  public transform(data: any) : T {
    if(data == undefined) {
      return undefined;
    }

    let id = this.getId(data);
    let existingModel = this.findPublished(id);
    let model = existingModel ? existingModel : this.create();

    this.fill(data, model);

    if(!existingModel) {
      this.publish(id, model);
    }

    if(this.isRootTransfomer && this.context.pendingListeners.size > 0) {
      this.reportBrokenLinks();
    }

    return model;
  }

  public transformMany(data: any[]) : T[] {
    if(data == undefined) {
      return undefined;
    }

    return data.map((itemData) => {
      return this.transform(itemData);
    })
  }

  static storeName: string;

  abstract create() : T;

  abstract fill(data: any, object: T) : T;

  protected getId(data: any) : string {
    return data.id;
  }

  private globalId(id: string) : string {
    return `${this.name ? this.name : this.constructor.name}:${id}`;
  }

  public awaitPublish(id: string, callback: (object: T) => void) {
    this.context.awaitPublish(this.globalId(id), (object: Object) => {
      callback(<T>object);
    });
  }

  private findPublished(id: string) : T {
    return <T>this.context.find(this.globalId(id));
  }

  private publish(id: string, object: T) {
    this.context.publish(this.globalId(id), object);
  }

  private reportBrokenLinks() {
    // When the root node is finished processing, all links should be resolved.
    // If this is not the case, then there is a broken link.
    // For example: if a question has sectionId = 123, but there does not exist
    // such a section, then a warning will be given here.
    Array.from(this.context.pendingListeners.keys()).forEach((key: string) => {
      let parts = key.split(':');
      let transformerName = parts[0];
      let id = parts[1];
      console.warn(`Tranformer '${ transformerName }' did not publish an entity with id '${id}'`);
    });
}
}
