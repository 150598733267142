import BaseTransformer from "../system/BaseTransformer";
import RieStructure from "../../models/rie/RieStructure";
import RiskTransformer from "./RiskTransformer";
import SurveyTransformer from "../survey/SurveyTransformer";
import Survey from "../../models/survey/structure/Survey";
import RieSurveyChoiceTransformer from "./SurveyChoiceTransformer";
import AttentionPointTransformer from "./AttentionPointTransformer";

export default class RieStructureTransformer extends BaseTransformer<RieStructure> {
  name = "RieStructureTransformer"

  create(): RieStructure {
    return new RieStructure;
  }

  fill(data: any, rieStructure: RieStructure): RieStructure {
    rieStructure.id = data.id;

    (new SurveyTransformer(this.context))
      .awaitPublish(data.surveyId, (survey: Survey) => {
        rieStructure.survey = survey;
      });

    rieStructure.risks = (new RiskTransformer(this.context))
      .transformMany(data.risks);

    rieStructure.attentionPoints = (new AttentionPointTransformer(this.context))
      .transformMany(data.attentionPoints);

    if (data.surveyChoices) {
      rieStructure.surveyChoices = (new RieSurveyChoiceTransformer(this.context))
        .transformMany(data.surveyChoices);
    } else {
      rieStructure.surveyChoices = [];
    }


    return rieStructure;
  }
}
