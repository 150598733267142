import Choice from '../survey/structure/Choice';
import RieStructure from './RieStructure';

export default class RieSurveyChoice {
  public id: number;
  public choice: Choice;
  public rieStructure: RieStructure;
  public visibleForChildminder: boolean;
  public visibleForFranchisee: boolean;
}
