import * as React from "react";

import App from "../../../App";
import Trajectory from "../../../models/rie/Trajectory";
import Section from "../../../models/survey/structure/Section";
import {
  NavigateAction,
  navigateSection,
  navigateView,
  View,
  navigateEvaluationSection
} from "../../../reducers/NavigateReducer";
import BackLink from "../../ui/BackLink";
import Menu from "../../ui/menu/Menu";
import MenuItem from "../../ui/menu/MenuItem";
import RieGeneralInformation from "../partials/RieGeneralInformation";
import { RieContextConsumer } from "../../../contexts/rie/RieContext";

export interface RieSidebarProperties {
  backURL: string;
  trajectory: Trajectory;
  onNavigate: (action: NavigateAction) => void;
  app: App;
}

const RieSidebar: React.SFC<RieSidebarProperties> = props => {
  const trajectory = props.trajectory;
  const preparationSectionResponse = props.trajectory.preparationSectionResponse;
  const inventorizationSectionResponses = props.trajectory.inventorizationSectionResponses;
  const activeSection = props.app.derivations.navigation.section;
  const currentUser = props.app.derivations.currentUser;

  return (
    <RieContextConsumer>
      {ctx => (
        <>
          <Menu>
            {ctx.visibleViews.find(x => x == View.Explanation) && (
              <MenuItem
                label="✓ Toelichting"
                isSelected={props.app.derivations.navigation.view == View.Explanation}
                onClick={() => props.onNavigate(navigateView(View.Explanation))}
              />
            )}

            {ctx.shouldShowQualityRequirements && (
              <MenuItem
                label={
                  (props.app.derivations.trajectory.qualityRequirementsSurveyResponse
                    .isStrictlyCompleted
                    ? "✓ "
                    : "× ") + "Kwaliteitscriteria"
                }
                isSelected={
                  props.app.derivations.navigation.view == View.SurveySection &&
                  !!props.app.derivations.trajectory.qualityRequirementsSurveyResponse.survey.sections.find(
                    x => x.id === activeSection.id
                  )
                }
                isEnabled={!props.app.derivations.trajectory.finalized}
                onClick={() =>
                  props.onNavigate(
                    navigateSection(
                      props.trajectory.qualityRequirementsSurveyResponse.sectionResponses[0].section
                    )
                  )
                }
              >
                {props.app.derivations.navigation.view == View.SurveySection &&
                  !!props.app.derivations.trajectory.qualityRequirementsSurveyResponse.survey.sections.find(
                    x => x.id === activeSection.id
                  ) && (
                    <Menu>
                      {props.trajectory.qualityRequirementsSurveyResponse.sectionResponses.map(
                        sectionResponse => (
                          <MenuItem
                            key={sectionResponse.section.id}
                            isSelected={
                              props.app.derivations.navigation.view == View.SurveySection &&
                              sectionResponse.section.id == activeSection.id
                            }
                            onClick={() =>
                              props.onNavigate(navigateSection(sectionResponse.section))
                            }
                            label={
                              (ctx.surveyContext.sectionResponseConsideredDone(sectionResponse)
                                ? "✓ "
                                : "× ") + sectionResponse.section.title
                            }
                            isEnabled={true}
                          />
                        )
                      )}
                    </Menu>
                  )}
              </MenuItem>
            )}

            {ctx.visibleViews.find(x => x == View.SurveySection) && (
              <MenuItem
                isSelected={
                  props.app.derivations.navigation.view == View.SurveySection &&
                  preparationSectionResponse.section.id == activeSection.id
                }
                isEnabled={!props.app.derivations.trajectory.finalized}
                onClick={() =>
                  props.onNavigate(navigateSection(preparationSectionResponse.section))
                }
                label={
                  (props.trajectory.preparationCompleted ? "✓ " : "× ") +
                  preparationSectionResponse.section.title
                }
              />
            )}

            {ctx.visibleViews.find(x => x == View.AttentionPoints) && (
              <MenuItem
                label="Aandachtspunten"
                isSelected={props.app.derivations.navigation.view == View.AttentionPoints}
                isEnabled={
                  preparationSectionResponse.isCompleted &&
                  !props.app.derivations.trajectory.finalized
                }
                onClick={() => props.onNavigate(navigateView(View.AttentionPoints))}
              />
            )}

            {ctx.visibleViews.find(x => x == View.SurveySection) && (
              <MenuItem
                label={(ctx.inventorizationConsideredDone ? "✓ " : "× ") + "Inventariseren"}
                isEnabled={
                  preparationSectionResponse.isCompleted &&
                  !props.app.derivations.trajectory.finalized
                }
                onClick={() =>
                  props.onNavigate(navigateSection(inventorizationSectionResponses[0].section))
                }
                isSelected={
                  props.app.derivations.navigation.view == View.SurveySection &&
                  activeSection.id !== preparationSectionResponse.section.id &&
                  !!props.app.derivations.trajectory.rieSurveyResponse.survey.sections.find(
                    x => x.id === activeSection.id
                  )
                }
              >
                {(currentUser.isCustomer ||
                  (props.app.derivations.navigation.view == View.SurveySection &&
                    activeSection.id !== preparationSectionResponse.section.id &&
                    !!props.app.derivations.trajectory.rieSurveyResponse.survey.sections.find(
                      x => x.id === activeSection.id
                    ))) && (
                  <Menu>
                    {inventorizationSectionResponses.map(sectionResponse => (
                      <MenuItem
                        key={sectionResponse.section.id}
                        isSelected={
                          props.app.derivations.navigation.view == View.SurveySection &&
                          sectionResponse.section.id == activeSection.id
                        }
                        onClick={() => props.onNavigate(navigateSection(sectionResponse.section))}
                        label={
                          (ctx.surveyContext.sectionResponseConsideredDone(sectionResponse)
                            ? "✓ "
                            : "× ") + sectionResponse.section.title
                        }
                        isEnabled={
                          preparationSectionResponse.isCompleted &&
                          !props.app.derivations.trajectory.finalized
                        }
                      />
                    ))}
                  </Menu>
                )}
              </MenuItem>
            )}

            {ctx.visibleViews.find(x => x == View.Send) && (
              <MenuItem
                isSelected={props.app.derivations.navigation.view == View.Send}
                isEnabled={
                  preparationSectionResponse.isCompleted &&
                  !props.app.derivations.trajectory.finalized
                }
                onClick={() => props.onNavigate(navigateView(View.Send))}
                label={
                  (props.trajectory.finalized || props.trajectory.rieSurveyResponse.sent
                    ? "✓ "
                    : "× ") + "Verzenden"
                }
              />
            )}

            {ctx.visibleViews.find(x => x == View.Evaluation) && (
              <MenuItem
                isSelected={props.app.derivations.navigation.view == View.Evaluation}
                isEnabled={
                  preparationSectionResponse.isCompleted &&
                  !props.app.derivations.trajectory.finalized
                }
                onClick={() => props.onNavigate(navigateView(View.Evaluation))}
                label={(props.trajectory.evaluationCompleted ? "✓ " : "× ") + "Evalueren"}
              >
                {props.app.derivations.navigation.view == View.Evaluation && (
                  <Menu>
                    {trajectory.riskEvaluationSections.map(riskEvaluationSection => (
                      <MenuItem
                        key={riskEvaluationSection.section.id}
                        isSelected={
                          props.app.derivations.navigation.view == View.Evaluation &&
                          riskEvaluationSection.riskEvaluations.find(
                            x => x.risk == props.app.derivations.navigation.risk
                          ) != null
                        }
                        onClick={() =>
                          props.onNavigate(navigateEvaluationSection(riskEvaluationSection.section))
                        }
                        label={
                          (riskEvaluationSection.completed ? "✓ " : "× ") +
                          riskEvaluationSection.section.title
                        }
                        isEnabled={
                          preparationSectionResponse.isCompleted &&
                          !trajectory.finalized &&
                          trajectory.riskEvaluationSections.find(
                            x => x.section == riskEvaluationSection.section
                          ).activeRiskEvaluations.length > 0
                        }
                      />
                    ))}
                  </Menu>
                )}
              </MenuItem>
            )}

            {ctx.visibleViews.find(x => x == View.PlanOfAction) && (
              <MenuItem
                isSelected={props.app.derivations.navigation.view == View.PlanOfAction}
                isEnabled={
                  preparationSectionResponse.isCompleted &&
                  !props.app.derivations.trajectory.finalized
                }
                onClick={() => props.onNavigate(navigateView(View.PlanOfAction))}
                label={(props.trajectory.evaluationCompleted ? "✓ " : "× ") + "Plan van aanpak"}
              />
            )}

            {ctx.visibleViews.find(x => x == View.Finalize) && (
              <MenuItem
                label={(props.trajectory.finalized ? "✓ " : "× ") + "Afronden"}
                isSelected={props.app.derivations.navigation.view == View.Finalize}
                isEnabled={
                  preparationSectionResponse.isCompleted &&
                  !props.app.derivations.trajectory.finalized
                }
                onClick={() => props.onNavigate(navigateView(View.Finalize))}
              />
            )}

            {ctx.visibleViews.find(x => x == View.Implementation) && (
              <MenuItem
                label={(props.trajectory.implementationCompleted ? "✓ " : "× ") + "Implementatie"}
                isSelected={props.app.derivations.navigation.view == View.Implementation}
                isEnabled={props.app.derivations.trajectory.finalized}
                onClick={() => props.onNavigate(navigateView(View.Implementation))}
              />
            )}
          </Menu>

          <hr />

          <RieGeneralInformation app={props.app} />
        </>
      )}
    </RieContextConsumer>
  );
};
export default RieSidebar;
