import * as React from "react";
import RieSignatureField from "./RieSignatureField";
import Person from "../../../models/general/Person";
import Franchisee from "../../../models/general/Franchisee";

export interface RieSignatureFieldsProps {
  franchisee: Franchisee;
  childminder: Person;
  onFranchiseeSignatureChange: (signature?: string) => void;
  onChildminderSignatureChange: (signature?: string) => void;
}

export interface RieSignatureFieldsState {}

export default class RieSignatureFields extends React.Component<
  RieSignatureFieldsProps,
  RieSignatureFieldsState
> {
  render() {
    return (
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <span style={{ fontWeight: "bold", color: "#58595B" }}>Handtekeningen</span>
        <div>
          <div style={{ display: "inline-block", marginRight: "20px" }}>
            <RieSignatureField
              title={this.props.childminder.fullName}
              onClear={() => {
                this.props.onChildminderSignatureChange(null);
              }}
              onSet={(png: string) => {
                this.props.onChildminderSignatureChange(png);
              }}
            />
          </div>
          <div style={{ display: "inline-block" }}>
            <RieSignatureField
              title={this.props.franchisee.name}
              onClear={() => {
                this.props.onFranchiseeSignatureChange(null);
              }}
              onSet={png => {
                this.props.onFranchiseeSignatureChange(png);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
