import * as React from 'react';
import App from '../../../App';

export interface RieGeneralInformationProps {
  app: App;
}

const RieGeneralInformation: React.SFC<RieGeneralInformationProps> = ({
  app
}) => {
  const referenceCode = app.derivations.trajectory.referenceCode;
  const facility = app.derivations.trajectory.facility;
  const childminder = facility.childminder;

  return <div>

    { !app.derivations.currentUser.isCustomer && <>
      <b>Referentiecode</b><br />
      <p className="margin-top-none">
        {
          app.derivations.trajectory.finalized ?
            ( referenceCode ?
              referenceCode :
              '-'
            ) :
            <i>RI&amp;E nog niet afgerond</i>
        }
      </p>

      <b>Gegevens gastouder</b>
      <p className="margin-top-none">
        <a href={ childminder.path } target="_blank">{ childminder.fullName }</a><br />
        Klant nr: { childminder.id }
      </p>
      <p>
        { childminder.address.line1 }<br />
        { childminder.address.line2 }
      </p>
      <p>
        { childminder.phone1 ? <>T: { childminder.phone1 }</> : <>T: -</> }<br />
        { childminder.phone2 && <>M: { childminder.phone2 }<br /></> }
        { childminder.email ? <>E: <a href={ 'mailto:' + childminder.email }>{ childminder.email }</a></> : <>E: -</> }<br />
      </p>
      </> }

    <b>Opvanglocatie</b><br />
    <p className="margin-top-none">
      LRK-nummer: { facility.lrkNumber ? facility.lrkNumber : '-' }
      <br />
      Aantal kindplaatsen:{" "}
      {facility.numberOfChildren ? facility.numberOfChildren : "-"}
    </p>
    <p>
      { facility.address.line1 }<br />
      { facility.address.line2 }
    </p>
  </div>
}

export default RieGeneralInformation;
