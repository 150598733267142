import * as React from 'react';

import App from '../../../App';
import RiePage from '../template/RiePage';
import MeasureList from '../partials/MeasureList';
import MeasureChecklist from '../partials/MeasuresChecklist';
import Measure from '../../../models/rie/Measure';
import { measureSave } from '../../../reducers/MeasureReducer';
import DocumentList from '../partials/DocumentList';

export interface RieImplementationViewProps {
  app: App;
}

const RieImplementationView: React.SFC<RieImplementationViewProps> = (props) => {
  const measures = props.app.derivations.trajectory.measures;
  const documents = props.app.derivations.trajectory.documents;
  const expiringMeasures = measures.filter(x => x.deadline != null);
  const continuousMeasures = measures.filter(x => x.deadline == null);
  const handlers = props.app.handlers;
  const currentUser = props.app.derivations.currentUser;

  return (
    <RiePage
      app={ props.app }
      title="Implementatie"
      renderContent={ () => (
        <>
          <div style={ { marginBottom: '20px'} }>
            <b>Gekoppelde documenten</b><br />
            <DocumentList
              documents={ documents }
            />
          </div>
          <div style={ { marginBottom: '20px'} }>
            {
              measures.length == 0 ?
              (
                <>
                  <b>Acties: te nemen maatregelen</b><br />
                  <i>
                    Er zijn geen maatregelen om te tonen.
                  </i>
                </>
              ) :
              (
                <>
                  <b>Acties: te nemen maatregelen</b><br />
                  <MeasureChecklist
                    measures={ expiringMeasures }
                    readOnly={ currentUser.isCustomer }
                    onMeasureClick={ (measure: Measure) => {
                      measure.implemented = !measure.implemented;
                      handlers.measureAction(
                        measureSave(measure)
                      );
                    }}
                    onDeadlineChange={ (measure: Measure, deadline: Date) => {
                      measure.deadline = deadline;
                      handlers.measureAction(
                        measureSave(measure)
                      );
                    }}
                  />
                </>
              )
            }
          </div>
          <div style={ { marginBottom: '20px'} }>
            {
              measures.length == 0 ?
              (
                <>
                  <b>Doorlopende afspraken</b><br />
                  <i>
                    Er zijn geen afspraken om te tonen.
                  </i>
                </>
              ) :
              (
                <>
                  <b>Doorlopende afspraken</b><br />
                  <MeasureList
                    showDeadline={ false }
                    readOnly={ true }
                    measures={ continuousMeasures }
                  />
                </>
              )
            }
          </div>
        </>
      )}
    />
  )
}
export default RieImplementationView;
