import SectionResponse from '../survey/response/SectionResponse';
import SurveyResponse from '../survey/response/SurveyResponse';
import Measure from './Measure';
import RieStructure from './RieStructure';
import RiskEvaluation from './RiskEvaluation';
import Facility from '../general/Facility';
import Document from './Document';
import Section from '../survey/structure/Section';
import RiskEvaluationSection from './RiskEvaluationSection';
import Franchisee from '../general/Franchisee';
import AttentionPoint from './AttentionPoint';
import QuestionResponse from '../survey/response/QuestionResponse';
import Question from '../survey/structure/Question';

export default class Trajectory {
  public id: number;
  public referenceCode: string;
  public rieDate: Date;
  public extraInfo: string;
  public rieSurveyResponse: SurveyResponse;
  public qualityRequirementsSurveyResponse: SurveyResponse;
  public rieStructure: RieStructure;
  public finalized: boolean;
  public facility: Facility;
  public documents: Document[];
  public franchisee: Franchisee;
  public repeatedFromTrajectoryId: number;

  private _measures: Measure[];

  public set measures(measures: Measure[]) {
    this._measures = measures;
  }
  public get measures(): Measure[] {
    return this._measures.sort((a: Measure, b: Measure) => {
      if (a.deadline == null && b.deadline == null) {
        return a.description.localeCompare(b.description, undefined, { numeric: true, sensitivity: 'base' });
      } else if (a.deadline && b.deadline) {
        return a.deadline.getTime() == b.deadline.getTime() ?
          a.description.localeCompare(b.description, undefined, { numeric: true, sensitivity: 'base' }) :
          a.deadline.getTime() - b.deadline.getTime();
      } else {
        return a.deadline ? -1 : 1;
      }
    });
  }

  get activeMeasures(): Measure[] {
    return this.measures.filter(measure => {
      return measure.isActive;
    });
  }

  get preparationSectionResponse(): SectionResponse {
    return this.rieSurveyResponse.sectionResponses[0];
  }

  get inventorizationSectionResponses(): SectionResponse[] {
    return this.rieSurveyResponse.sectionResponses.slice().splice(1);
  }

  get riskEvaluations(): RiskEvaluation[] {
    return this.rieStructure.risks.map(risk => {
      let riskEvaluation = new RiskEvaluation;

      riskEvaluation.risk = risk;
      riskEvaluation.trajectory = this;

      return riskEvaluation;
    });
  }

  get riskEvaluationSections(): RiskEvaluationSection[] {
    return this.inventorizationSectionResponses.map(sectionResponse => {
      let riskEvaluationSection = new RiskEvaluationSection;

      riskEvaluationSection.section = sectionResponse.section;
      riskEvaluationSection.trajectory = this;

      return riskEvaluationSection;
    });
  }

  get activeRiskEvaluations(): RiskEvaluation[] {
    return this.riskEvaluations.filter(x => x.isActive);
  }

  get firstActiveRiskEvaluation(): RiskEvaluation {
    let activeRiskEvaluations = this.activeRiskEvaluations;
    return activeRiskEvaluations.length > 0 ? activeRiskEvaluations[0] : null;
  }

  get lastActiveRiskEvaluation(): RiskEvaluation {
    let activeRiskEvaluations = this.activeRiskEvaluations;
    return activeRiskEvaluations.length > 0 ? activeRiskEvaluations[activeRiskEvaluations.length - 1] : null;
  }

  get preparationCompleted(): boolean {
    return this.preparationSectionResponse.isCompleted;
  }

  get inventorizationCompleted(): boolean {
    return this.preparationCompleted && this.inventorizationSectionResponses.find(x => !x.isCompleted) == null;
  }

  get inventorizationCompletedOrSkipped(): boolean {
    return this.preparationCompleted && this.inventorizationSectionResponses.find(x => !x.isCompletedOrSkipped) == null;
  }

  get evaluationCompleted(): boolean {
    return this.inventorizationCompleted && this.activeRiskEvaluations.find(x => !x.isCompleted) == null;
  }

  get qualityRequirementsCompleted(): boolean {
    if (!this.qualityRequirementsSurveyResponse) {
      return true;
    }

    return this.qualityRequirementsSurveyResponse.isStrictlyCompleted;
  }

  get canBeFinalized(): boolean {
    return this.evaluationCompleted && this.rieDate != null && this.qualityRequirementsCompleted;
  }

  get implementationCompleted(): boolean {
    return this.finalized && this.measures.find(x => x.deadline != null && x.implemented == false) == null;
  }

  attentionPointIsRelevant(attentionPoint: AttentionPoint): boolean {
    return !!attentionPoint.surveyQuestions.find(question => {
      const questionResponse = this.rieSurveyResponse.questionResponses.find(x => x.question.id === question.id);
      return questionResponse.isActive;
    });
  }

  get relevantAttentionPoints(): AttentionPoint[] {
    return this.rieStructure.attentionPoints.filter(this.attentionPointIsRelevant.bind(this));
  }

  get surveyResponses(): SurveyResponse[] {
    return [
      !!this.qualityRequirementsSurveyResponse &&
      this.qualityRequirementsSurveyResponse,
      this.rieSurveyResponse
    ].filter(Boolean);
  }

  get questionResponses(): QuestionResponse[] {
    return this.surveyResponses.reduce((questionResponses: QuestionResponse[], surveyResponse: SurveyResponse) => {
      return questionResponses.concat(surveyResponse.questionResponses);
    }, []);
  }

  get questions(): Question[] {
    return this.questionResponses.map(x => x.question);
  }
}
