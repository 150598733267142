import * as React from "react";

import App from "../../../App";
import RiePage from "../template/RiePage";
import { saveAll } from "../../../reducers/SaveReducer";
import { rieChange } from "../../../reducers/RieReducer";
import TextButton from "../../ui/TextButton";
import RieFinalizeButton from "../partials/RieFinalizeButton";
import Datepicker from "../partials/Datepicker";
import RieSignatureFields from "../partials/RieSignatureFields";
import Button from "../../ui/Button";
import { RieContextConsumer } from "../../../contexts/rie/RieContext";

export interface RieFinalizeViewProps {
  app: App;
}

export interface RieFinalizeViewState {
  showSignatures: boolean;
  franchiseeSignature: string;
  childminderSignature: string;
}

export default class RieFinalizeView extends React.Component<
  RieFinalizeViewProps,
  RieFinalizeViewState
> {
  constructor(props: RieFinalizeViewProps) {
    super(props);

    this.state = {
      showSignatures: false,
      franchiseeSignature: null,
      childminderSignature: null
    };
  }

  renderForm() {
    const app = this.props.app;
    const handlers = this.props.app.handlers;

    return (
      <p>
        <label>
          <b>RI&amp;E-datum</b>
          <br />
          <Datepicker
            value={app.state.rie.rieDate}
            onChange={(value: string) => {
              handlers.rieAction(
                rieChange({
                  rieDate: value ? new Date(value) : null
                })
              );
            }}
          />
        </label>
      </p>
    );
  }

  renderDocumentsPreview() {
    const app = this.props.app;
    const handlers = this.props.app.handlers;

    return (
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <b>Voorvertoning documenten</b>
        <br />
        {app.state.save.hasUnsavedChanges ? (
          <>
            <i>RI&amp;E moet eerst worden opgeslagen.</i>
            &nbsp;-&nbsp;
            <TextButton
              label={app.state.save.isSaving ? "Nu opslaan..." : "Nu opslaan"}
              onClick={() => {
                handlers.save(saveAll());
              }}
              isEnabled={!app.state.save.isSaving}
            />
          </>
        ) : (
          <ul style={{ marginTop: "2px" }}>
            <RieContextConsumer>
              {ctx => {
                return ctx.shouldShowQualityRequirements ? (
                  <li>
                    <a
                      href={app.derivations.metadata.documentsURL + "/quality_requirements.pdf"}
                      target="_blank"
                    >
                      Kwaliteitscriteria
                    </a>
                  </li>
                ) : (
                  <></>
                );
              }}
            </RieContextConsumer>
            <li>
              <a
                href={app.derivations.metadata.documentsURL + "/inventorization.pdf"}
                target="_blank"
              >
                Risico-Inventarisatie &amp; Evaluatie
              </a>
            </li>
            <li>
              <a
                href={app.derivations.metadata.documentsURL + "/plan_of_action.pdf"}
                target="_blank"
              >
                Plan van aanpak
              </a>
            </li>
          </ul>
        )}
      </div>
    );
  }

  renderChecklist() {
    const app = this.props.app;
    const allSurveysCompleted = !app.derivations.trajectory.surveyResponses.find(
      x => !x.isStrictlyCompleted
    );
    const eachRiskHasMeasure =
      app.derivations.trajectory.riskEvaluations.find(x => {
        return x.isActive && x.measures.length == 0;
      }) == null;
    const hasRieDate = app.state.rie.rieDate != null;

    return (
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <span style={{ fontWeight: "bold", color: "#58595B" }}>Checklist</span>
        <ul style={{ marginTop: "2px", marginBottom: "8px" }}>
          <li style={{ color: allSurveysCompleted ? null : "#900" }}>
            <span style={{ width: "15px", display: "inline-block" }}>
              {allSurveysCompleted ? "✓ " : "× "}
            </span>
            Alle vragen zijn beantwoord en toegelicht indien verplicht
          </li>
          <li style={{ color: eachRiskHasMeasure ? null : "#900" }}>
            <span style={{ width: "15px", display: "inline-block" }}>
              {eachRiskHasMeasure ? "✓ " : "× "}
            </span>
            Voor elk risico is in ieder geval één maatregel/afspraak gepland
          </li>
          <li style={{ color: hasRieDate ? null : "#900" }}>
            <span style={{ width: "15px", display: "inline-block" }}>
              {hasRieDate ? "✓ " : "× "}
            </span>
            RI&amp;E-datum is ingevuld
          </li>
        </ul>
      </div>
    );
  }

  render() {
    const app = this.props.app;

    return (
      <RiePage
        app={app}
        title="Afronden"
        renderContent={() => (
          <>
            {this.renderForm()}
            {this.renderChecklist()}
            {this.renderDocumentsPreview()}

            {!this.state.showSignatures && (
              <>
                <Button
                  label="Ondertekenen"
                  isEnabled={this.props.app.derivations.trajectory.canBeFinalized}
                  onClick={() => {
                    this.setState({
                      showSignatures: true,
                      franchiseeSignature: null,
                      childminderSignature: null
                    });
                  }}
                />
              </>
            )}
            {this.state.showSignatures && (
              <>
                <RieSignatureFields
                  franchisee={app.derivations.trajectory.franchisee}
                  childminder={app.derivations.trajectory.facility.childminder}
                  onChildminderSignatureChange={childminderSignature => {
                    this.setState(prev => {
                      return {
                        ...prev,
                        childminderSignature
                      };
                    });
                  }}
                  onFranchiseeSignatureChange={franchiseeSignature => {
                    this.setState(prev => {
                      return {
                        ...prev,
                        franchiseeSignature
                      };
                    });
                  }}
                />

                <div style={{ marginTop: "10px" }}>
                  <RieFinalizeButton
                    childminderSignature={this.state.childminderSignature}
                    franchiseeSignature={this.state.franchiseeSignature}
                    app={app}
                  />

                  <Button
                    label="Annuleren"
                    onClick={() => {
                      this.setState({
                        showSignatures: false,
                        franchiseeSignature: null,
                        childminderSignature: null
                      });
                    }}
                  />
                </div>
              </>
            )}
          </>
        )}
      />
    );
  }
}
