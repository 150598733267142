import BaseTransformer from "../system/BaseTransformer";
import RieStructure from "../../models/rie/RieStructure";
import Risk from "../../models/rie/Risk";
import RieStructureTransformer from "./RieStructureTransformer";
import Choice from "../../models/survey/structure/Choice";
import ChoiceTransformer from "../survey/ChoiceTransformer";
import MeasureSuggestionTransformer from "./MeasureSuggestionTransformer";

export default class RiskTransformer extends BaseTransformer<Risk> {
  name = "RiskTransformer"

  create() : Risk {
    return new Risk;
  }

  fill(data: any, risk: Risk) : Risk {
    risk.id = data.id;
    risk.title = data.title;

    risk.measureSuggestions = (new MeasureSuggestionTransformer(this.context))
      .transformMany(data.measureSuggestions)
      .sort((a, b) => a.id - b.id);

    (new RieStructureTransformer(this.context))
      .awaitPublish(data.rieStructureId, (rieStructure: RieStructure) => {
        risk.rieStructure = rieStructure;
      });

    risk.surveyChoices = [];
    data.surveyChoiceIds.forEach((surveyChoiceId: any) => {
      (new ChoiceTransformer(this.context)).awaitPublish(surveyChoiceId, (surveyChoice: Choice) => {
        risk.surveyChoices.push(surveyChoice);
      });
    });

    return risk;
  }
}
