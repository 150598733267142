import SurveyResponse from "../../models/survey/response/SurveyResponse";
import BaseTransformer from "../system/BaseTransformer";
import SurveyTransformer from "./SurveyTransformer";
import QuestionResponseTransformer from "./QuestionResponseTransformer";
import QuestionResponse from "../../models/survey/response/QuestionResponse";
import ChoiceResponse from "../../models/survey/response/ChoiceResponse";

export default class SurveyResponseTranformer extends BaseTransformer<SurveyResponse> {
  name = "SurveyResponseTranformer"

  create() : SurveyResponse {
    return new SurveyResponse;
  }

  fill(data: any, surveyResponse: SurveyResponse) : SurveyResponse {
    surveyResponse.id = data.id;
    surveyResponse.sent = data.sent;

    surveyResponse.survey = (new SurveyTransformer(this.context))
      .transform(data.survey);

    surveyResponse.questionResponses = (new QuestionResponseTransformer(this.context))
      .transformMany(data.questionResponses.sort(
        (a: any, b: any) => a.questionId - b.questionId)
      );

    this.generateMissingQuestionResponses(surveyResponse);

    return surveyResponse;
  }

  private generateMissingQuestionResponses(surveyResponse: SurveyResponse) {
    surveyResponse.survey.questions.forEach(question => {
      let questionResponseExists = surveyResponse.questionResponses.find(questionResponse => questionResponse.question == question);
      if(!questionResponseExists) {
        let questionResponse = new QuestionResponse;
        questionResponse.question = question;
        questionResponse.surveyResponse = surveyResponse;
        questionResponse.choiceResponses = [];
        surveyResponse.questionResponses.push(questionResponse);
      }
    });

    this.generateMissingChoiceResponses(surveyResponse);
  }

  private generateMissingChoiceResponses(surveyResponse: SurveyResponse) {
    surveyResponse.questionResponses.forEach(questionResponse => {
      questionResponse.question.choices.forEach(choice => {
        let choiceResponseExists = questionResponse.choiceResponses.find(choiceResponse => choiceResponse.choice == choice);
        if(!choiceResponseExists) {
          let choiceResponse = new ChoiceResponse;
          choiceResponse.choice = choice;
          choiceResponse.questionResponse = questionResponse;
          choiceResponse.isSelected = false;
          questionResponse.choiceResponses.push(choiceResponse);
        }
      })
    });
  }
}
