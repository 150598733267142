import { format } from 'date-fns';
import * as React from 'react';

import Measure from '../../../models/rie/Measure';
import TextButton from '../../ui/TextButton';

export interface MeasureListProps {
  measures: ReadonlyArray<Measure>;
  readOnly?: boolean;
  showDeadline?: boolean;
  onDeleteClick?: (measure: Measure) => void;
  onEditClick?: (measure: Measure) => void;
  onReadClick?: (measure: Measure) => void;
  renderEmptyList?: () => JSX.Element;
  renderTitle?: () => JSX.Element;
}

const MeasureList: React.SFC<MeasureListProps> = ({
  measures,
  readOnly = false,
  showDeadline = true,
  renderEmptyList,
  onReadClick = null,
  onDeleteClick = () => {},
  onEditClick = () => {},
  renderTitle = () => (<></>)
}) => {
  return <>
    { renderTitle() }
    { measures.length == 0 ? (
      <>
        {
          renderEmptyList ?
          renderEmptyList() :
          <div style={ { fontStyle: 'italic', marginTop: '3px' }}>Geen maatregelen om te tonen.</div>
        }
      </>
    ) : (
      <table style={ { width: '100%', maxWidth: '700px' } } className={ 'minimal_table margin-top-none full_width' + (onReadClick ? ' hoverable' : '')}>
        <thead>
          <tr>
            { showDeadline && <th style={ { textAlign: 'left', width: '90px' } }>Streefdatum</th> }
            <th style={ { textAlign: 'left' }}>Beschrijving</th>
            { !readOnly && (
              <>
                <th style={ { width: '80px' } }></th>
                <th style={ { width: '80px' } }></th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          { measures.map(measure => {
            return <tr key={ measure.key } className="item">
              { showDeadline && <td onClick={ () => { onReadClick && onReadClick(measure); } }>
                { measure.deadline ?
                  <span>
                    { format(measure.deadline, 'd-M-yyyy') }
                  </span> :
                  <span>-</span>
                }
              </td> }
              <td onClick={ () => { onReadClick && onReadClick(measure); } }>
                { measure.description }
              </td>
              { !readOnly && (<>
                  <td style={ { textAlign: 'right' } }>
                    <TextButton
                      onClick={ () => { onEditClick(measure); } }
                      label="wijzigen"
                    />
                  </td>
                  <td style={ { textAlign: 'right' } }>
                    <TextButton
                      onClick={ () => { onDeleteClick(measure); } }
                      label="verwijderen"
                    />
                  </td>
              </>) }
            </tr>
          })}
        </tbody>
      </table>
    ) }
  </>
};
export default MeasureList;
