import SurveyResponse from "./SurveyResponse";
import QuestionResponse from "./QuestionResponse";
import Section from "../structure/Section";

export default class SectionResponse {
  public surveyResponse: SurveyResponse;
  public section: Section;

  get isCompleted(): boolean {
    return this.questionResponses.find(x => {
      return !x.isCompleted
    }) == null;
  }

  get questionResponses(): QuestionResponse[] {
    return this.surveyResponse.questionResponses.filter(questionResponse => {
      return questionResponse.question.section == this.section;
    });
  }

  get isActive(): boolean {
    return this.questionResponses.find(x => x.isActive) != null;
  }

  get isCompletedOrSkipped(): boolean {
    return this.questionResponses.find(x => {
      return !x.isCompleted && !x.skipped
    }) == null;
  }

  get activeQuestionResponses(): QuestionResponse[] {
    return this.questionResponses.filter(x => x.isActive);
  }

  get firstActiveQuestionResponse(): QuestionResponse {
    let activeQuestionResponses = this.activeQuestionResponses;
    return activeQuestionResponses.length > 0 ? activeQuestionResponses[0] : null;
  }

  get lastActiveQuestionResponse(): QuestionResponse {
    let activeQuestionResponses = this.activeQuestionResponses;
    return activeQuestionResponses.length > 0 ? activeQuestionResponses[activeQuestionResponses.length - 1] : null;
  }

  get previousSectionResponse(): SectionResponse {
    const index = this.surveyResponse.sectionResponses.findIndex(x => x.section.id === this.section.id);
    if (index < 0) {
      return null;
    }

    return this.surveyResponse.sectionResponses[index - 1];
  }

  get nextSectionResponse(): SectionResponse {
    const index = this.surveyResponse.sectionResponses.findIndex(x => x.section.id === this.section.id);
    if (index + 1 >= this.surveyResponse.sectionResponses.length) {
      return null;
    }

    return this.surveyResponse.sectionResponses[index + 1];
  }
}
