import { format, parse } from 'date-fns';
import * as React from 'react';

export interface DatepickerState {
}

export interface DatepickerProps {
  value?: Date | string;
  name?: string;
  onChange: (value: string) => void;
}

export default class Datepicker extends React.Component<DatepickerProps, DatepickerState> {
  private el: Element;
  private $el: any;

  constructor(props: DatepickerProps) {
    super(props);
  }

  componentDidMount() {
    this.$el = $(this.el);

    this.$el.datepicker({
      changeMonth: true,
      changeYear: true,
      showOtherMonths: true,
      selectOtherMonths: true,
      yearRange: '-20:+11',
      dateFormat: 'dd-mm-yy',
      onSelect: (e: any) => {
        this.props.onChange(e.split("-").reverse().join("/"));
      }
    });

    // Hack to hide jQuery artifacts (Datepicker renders shadows over document even before its activated)
    $('.ui-datepicker').hide();
  }

  render() {
    const value = this.props.value ? new Date(this.props.value) : "";
    const name = this.props.name;
    const onChange = this.props.onChange;

    return <input type="string" ref={ el => this.el = el }
      name={ name }
      value={ value ? format(value, 'dd-MM-yyyy') : '' }
      readOnly={ true }
    />
  }
}
