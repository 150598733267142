import * as React from 'react';
import { MenuProps } from './Menu';

export interface MenuItemProps {
  isSelected?: boolean;
  onClick?: () => void;
  label: string;
  isEnabled?: boolean;
  children?: React.ReactElement<MenuProps>;
}

const MenuItem: React.SFC<MenuItemProps> = (props) => {
  let isEnabled = !(props.isEnabled === false);
  let isSelected = props.isSelected === true;
  let onClick = props.onClick;

  let aClasses: string[] = [];
  let liClasses: string[] = [];
  if(isSelected) {
    aClasses.push('selected');
    liClasses.push('selected');
    liClasses.push('simple-navigation-active-leaf')
  }

  return (
    <li
      id="details"
      className={ liClasses.join(' ') }
      onClick= { (e) => {  isEnabled && !isSelected && onClick && onClick(); } }
    >
      { props.onClick && isEnabled &&
        <a className={ aClasses.join(' ') } href="#" onClick={ (e) => { e.preventDefault(); } }>
          { props.label }
        </a>
      }
      {
        (!props.onClick || !isEnabled) &&
          <div>{ props.label }</div>
      }
      { props.children }
    </li>
  );
}
export default MenuItem;
