import * as React from 'react';

import App from '../../../App';
import Page from '../../ui/Page';
import RieFooter from './RieFooter';
import RieSidebar from './RieSidebar';

export interface RiePageProps {
  app: App;
  title?: string;
  renderContent: () => JSX.Element;
}

const RiePage: React.SFC<RiePageProps> = (props) => {
  let handlers = props.app.handlers;

  return (
    <Page
      title={ props.title ? props.title : "Risico-inventerisatie & -evaluatie" }

      renderSidebar={ () => (
        <RieSidebar
          app={ props.app }
          trajectory={ props.app.derivations.trajectory }
          backURL={ props.app.derivations.metadata.backURL }
          onNavigate={ handlers.navigate.bind(handlers) }
        />
      )}

      renderContent={ props.renderContent }

      renderFooter={ () => (
        <RieFooter app={ props.app }/>
      )}
    />
  )
}
export default RiePage;
