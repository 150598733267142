import * as React from "react";

import App from "../../../App";
import RiePage from "../template/RiePage";
import SurveyResponseSendButton from "../../survey/SurveyResponseSendButton";
import { surveyResponseSend } from "../../../reducers/SurveyResponseReducer";

export interface RieSendViewProps {
  app: App;
}

const RieSendView: React.SFC<RieSendViewProps> = ({ app }) => {
  const handlers = app.handlers;
  const rieDate = app.state.rie.rieDate;

  const renderChecklist = () => {
    const surveyResponse = app.derivations.rieSurveyResponse;

    return (
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <span style={{ fontWeight: "bold", color: "#58595B" }}>Checklist</span>
        <ul style={{ marginTop: "2px", marginBottom: "8px" }}>
          <li style={{ color: surveyResponse.canBeSent ? null : "#900" }}>
            <span style={{ width: "15px", display: "inline-block" }}>
              {surveyResponse.canBeSent ? "✓ " : "× "}
            </span>
            Alle vragen zijn beantwoord en toegelicht indien verplicht
          </li>
        </ul>
      </div>
    );
  };

  const rieResponseState = app.state.surveyResponses.find(x => x.reference === "RIE");

  return (
    <RiePage
      app={app}
      title="Verzenden"
      renderContent={() => (
        <>
          {renderChecklist()}

          <div style={{ marginTop: "10px" }}>
            <SurveyResponseSendButton
              surveyResponse={app.derivations.rieSurveyResponse}
              isSaving={app.state.save.isSaving}
              isSending={rieResponseState.sending}
              onClick={() => {
                if (confirm("Weet je zeker dat je de vragenlijst wilt versturen?")) {
                  handlers.surveyResponse(
                    surveyResponseSend(app.derivations.rieSurveyResponse.survey)
                  );
                }
              }}
            />
          </div>
        </>
      )}
    />
  );
};
export default RieSendView;
