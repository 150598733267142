import BaseTransformer from "../system/BaseTransformer";
import Person from "../../models/general/Person";
import AddressTransformer from "./AddressTransformer";

export default class PersonTransformer extends BaseTransformer<Person> {
  name = "PersonTransformer"

  create() : Person {
    return new Person;
  }

  fill(data: any, person: Person) : Person {
    person.id = data.id;
    person.path = data.path;
    person.fullName = data.fullName;
    person.phone1 = data.phone1;
    person.phone2 = data.phone2;
    person.email = data.email;

    person.address = (new AddressTransformer(this.context))
      .transform(data.address);

    return person;
  }
}
