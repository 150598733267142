import "core-js/stable";
import * as React from "react";
import { createRoot } from "react-dom/client";

const exposedComponents = require("./exposed_components").default;

let _window: any = window;
_window.react = {

  components: exposedComponents,

  // Render a React component in an HTML element with a given id and
  // optional properties.
  renderComponent: (component: string, id: string, props: {} = {}) => {
    let componentClass: React.ComponentClass = exposedComponents[component];
    if(!componentClass) {
      throw `Component '${ component }' does not exist, or is not registered as an exposed component in exposed_components.tsx.`
    }
  
    let element = document.getElementById(id);
    if(!element) {
      throw `Couldn't find element with id '${ id }'`
    }

    const root = createRoot(element);
    root.render(React.createElement(componentClass, props))
  }
}
