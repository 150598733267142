import * as React from "react";

import QuestionResponse from "../../models/survey/response/QuestionResponse";
import Question from "../../models/survey/structure/Question";
import Pagination from "../ui/pagination/Pagination";
import PaginationPage from "../ui/pagination/PaginationPage";

export interface QuestionNavigationProperties {
  questionResponses: ReadonlyArray<QuestionResponse>;
  selectedQuestionResponse?: QuestionResponse;
  onNavigateQuestion?: (question: Question) => void;
  checkmarkCondition?: (questionResponse: QuestionResponse) => boolean;
}

const QuestionNavigation: React.SFC<QuestionNavigationProperties> = ({
  questionResponses,
  selectedQuestionResponse,
  onNavigateQuestion,
  checkmarkCondition = (questionResponse: QuestionResponse) => {
    return questionResponse.isCompleted;
  }
}) => {
  return (
    <Pagination title="Vragen">
      {questionResponses.map(questionResponse => {
        return (
          <PaginationPage
            onClick={() => {
              selectedQuestionResponse != questionResponse &&
                onNavigateQuestion(questionResponse.question);
            }}
            key={questionResponse.question.id}
            isSelected={selectedQuestionResponse == questionResponse}
            hasError={!questionResponse.isCompleted}
            isEnabled={questionResponse.isActive}
          >
            {questionResponse.isActive && (questionResponse.isCompleted ? "✓" : "")}{" "}
            {questionResponse.question.questionNumber}
          </PaginationPage>
        );
      })}
    </Pagination>
  );
};
export default QuestionNavigation;
