import * as React from "react";

import QuestionResponse from "../../models/survey/response/QuestionResponse";
import { SurveyResponseAction, questionSkipChange } from "../../reducers/SurveyResponseReducer";
import ChoiceResponseForm from "../../containers/survey/ChoiceResponseForm";
import QuestionSkip from "./QuestionSkip";
import { SurveyContextConsumer } from "../../contexts/rie/SurveyContext";

export interface QuestionFormProperties {
  questionResponse: QuestionResponse;
  onSurveyResponse?: (action: SurveyResponseAction) => void;
  allowSkip?: boolean;
  readOnly?: boolean;
  renderTitle?: (questionResponse: QuestionResponse) => JSX.Element;
  renderDescription?: (questionResponse: QuestionResponse) => JSX.Element;
}

export default class QuestionForm extends React.Component<QuestionFormProperties, {}> {
  render() {
    const questionResponse = this.props.questionResponse;
    const question = questionResponse.question;

    let style: any = {};
    if (this.props.readOnly === true) {
      style.opacity = 0.5;
      style.pointerEvents = "none";
      style.cursor = "not-allowed";
    }

    return (
      <SurveyContextConsumer>
        {ctx => (
          <div>
            <div style={style}>
              {this.props.renderTitle ? (
                this.props.renderTitle(questionResponse)
              ) : (
                <h2>
                  {question.questionNumber}.{" "}
                  <span dangerouslySetInnerHTML={{ __html: question.title }} />
                </h2>
              )}

              {question.description &&
                (this.props.renderDescription ? (
                  this.props.renderDescription(questionResponse)
                ) : (
                  <div
                    style={{ margin: "0 0 10px 0", lineHeight: "22px" }}
                    dangerouslySetInnerHTML={{
                      __html: question.description.replace(/(?:\r\n|\r|\n)/g, "<br>")
                    }}
                  />
                ))}
            </div>
            <div>
              {questionResponse.question.choices
                .map(choice => questionResponse.choiceResponses.find(x => x.choice == choice))
                .filter(choiceResponse => ctx.choiceResponseVisible(choiceResponse))
                .map(choiceResponse => (
                  <div key={choiceResponse.choice.id} className="choice">
                    <ChoiceResponseForm
                      choiceResponse={choiceResponse}
                      readOnly={
                        this.props.readOnly || (this.props.allowSkip && questionResponse.skipped)
                      }
                      onSurveyResponse={this.props.onSurveyResponse}
                    />
                  </div>
                ))}
            </div>

            {this.props.allowSkip && (
              <div style={style}>
                <QuestionSkip
                  skipped={questionResponse.skipped}
                  onSkipChange={value => {
                    this.props.onSurveyResponse(questionSkipChange(question, value));
                  }}
                />
              </div>
            )}
          </div>
        )}
      </SurveyContextConsumer>
    );
  }
}
