import BaseTransformer from "../system/BaseTransformer";
import MeasureSuggestion from "../../models/rie/MeasureSuggestion";
import RiskTransformer from "./RiskTransformer";

export default class MeasureSuggestionTransformer extends BaseTransformer<MeasureSuggestion> {
  name = "MeasureSuggestionTransformer"

  create() : MeasureSuggestion {
    return new MeasureSuggestion;
  }

  fill(data: any, measureSuggestion: MeasureSuggestion) : MeasureSuggestion {
    measureSuggestion.id = data.id;
    measureSuggestion.description = data.description;

    (new RiskTransformer(this.context))
      .awaitPublish(data.riskId, risk => {
        measureSuggestion.risk = risk;
      });

    return measureSuggestion;
  }
}
