import * as React from "react";

import Button from "./Button";

export interface SaveButtonProps {
  isSaving: boolean;
  hasUnsavedChanges: boolean;
  onSave: () => void;
}

const SaveButton: React.SFC<SaveButtonProps> = props => {
  let label, isEnabled;

  if (props.isSaving) {
    label = (
      <>
        <div 
          className="loader loader--small loader--white"
          style={{ 
            position: "relative",
            width: 18,
            height: 0,
            right: 8,
            bottom: 15,
            opacity: 0.8
          }}
        /> Opslaan...
      </>
    );
    isEnabled = false;
  } else if (props.hasUnsavedChanges) {
    label = "Opslaan";
    isEnabled = true;
  } else {
    label = "✓ Opgeslagen";
    isEnabled = false;
  }

  return <Button label={label} isEnabled={isEnabled} id="save-button" onClick={props.onSave} />;
};

export default SaveButton;
