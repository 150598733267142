import * as React from 'react';
import Button from '../../ui/Button';
import App from '../../../App';
import { rieFinalize } from '../../../reducers/RieReducer';

export interface RieFinalizeButtonProps {
  app: App;
  childminderSignature?: string;
  franchiseeSignature?: string;
}

const RieFinalizeButton: React.SFC<RieFinalizeButtonProps> = (props) => {
  let label, isEnabled;
  const saveState = props.app.state.save;
  const rieState = props.app.state.rie;
  const handlers = props.app.handlers;

  if(rieState.finalizing) {
    label = "Opslaan en afronden..."
    isEnabled = false;
  } else if (saveState.isSaving) {
    label = "Opslaan en afronden"
    isEnabled = false;
  } else if (rieState.finalized) {
    label = "✓ Afgerond"
    isEnabled = false;
  } else if (props.app.derivations.trajectory.canBeFinalized) {
    label = "Opslaan en afronden"
    isEnabled = true;
  } else {
    label = "Opslaan en afronden"
    isEnabled = false;
  }

  if(props.childminderSignature == null || props.franchiseeSignature == null) {
    isEnabled = false;
  }

  return <Button
    label={ label }
    isEnabled={ isEnabled }
    onClick={ () => {
      if(confirm("Weet je zeker dat je de RI&E wilt afronden? Deze kan niet meer worden aangepast.")) {
        handlers.rieAction(
          rieFinalize({
            childminderSignature: props.childminderSignature,
            franchiseeSignature: props.franchiseeSignature
          })
        )
      }
    } }
  />
}

export default RieFinalizeButton;
