import * as React from 'react';

export interface QuestionSkipProps {
  skipped: boolean;
  onSkipChange: (skipped: boolean) => void;
}

const QuestionSkip: React.SFC<QuestionSkipProps> = ({
  skipped,
  onSkipChange
}) => {
  return <label style={ { display: 'flex', padding: '10px' } }>
    <input style={ { width: '15px', display: 'inline-block' } } type='checkbox' checked={ skipped } onChange={ (e) => { onSkipChange(e.target.checked) } } />
    <span style={ { paddingLeft: '10px', flex: 1, display: 'inline-block' } }>
      Deze vraag overslaan
    </span>
  </label>
}

export default QuestionSkip;
