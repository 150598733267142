import BaseTransformer from "../system/BaseTransformer";
import Choice from "../../models/survey/structure/Choice";
import ChoiceTransformer from "../survey/ChoiceTransformer";
import RieSurveyChoice from "../../models/rie/RieSurveyChoice";
import RieStructureTransformer from "./RieStructureTransformer";
import RieStructure from "../../models/rie/RieStructure";

export default class RieSurveyChoiceTransformer extends BaseTransformer<RieSurveyChoice> {
  name = "RieSurveyChoiceTransformer"

  create() : RieSurveyChoice {
    return new RieSurveyChoice;
  }

  fill(data: any, surveyChoice: RieSurveyChoice) : RieSurveyChoice {
    surveyChoice.id = data.id;
    surveyChoice.visibleForFranchisee = data.visibleForFranchisee;
    surveyChoice.visibleForChildminder = data.visibleForChildminder;

    (new RieStructureTransformer(this.context))
      .awaitPublish(data.rieStructureId, (rieStructure: RieStructure) => {
        surveyChoice.rieStructure = rieStructure;
      });

    (new ChoiceTransformer(this.context))
      .awaitPublish(data.surveyChoiceId, (choice: Choice) => {
        surveyChoice.choice = choice;
      });

    return surveyChoice;
  }
}
