import App, { State } from "./App";
import { NavigateAction, NavigateReducer } from "./reducers/NavigateReducer";
import { StateReduction } from "./reducers/Reducer";
import { MeasureAction, MeasureReducer } from "./reducers/MeasureReducer";
import { SaveAction, SaveReducer } from "./reducers/SaveReducer";
import { SurveyResponseAction, SurveyResponsesReducer } from "./reducers/SurveyResponseReducer";
import { RieAction, RieReducer } from "./reducers/RieReducer";

export default class Handlers {
  private app: App;
  private setState: (newState: State) => void;

  constructor(app: App, setState: (newState: State) => void) {
    this.app = app;
    this.setState = setState;
  }

  private handleStateReduction(reduction: StateReduction): Promise<void> {
    return new Promise((resolve, reject) => {
      this.setState(reduction.newState);

      if (reduction.async) {
        reduction.async(
          () => this.app.state,
          (reduction: StateReduction) => {
            this.handleStateReduction(reduction).then(() => {
              resolve();
            });
          },
          (reduction: StateReduction) => {
            this.handleStateReduction(reduction).then(() => {
              reject();
            });
          }
        );
      } else {
        resolve();
      }
    });
  }

  private get state(): State {
    return this.app.state;
  }

  save(action: SaveAction): Promise<void> {
    return this.handleStateReduction(new SaveReducer().reduce(this.app, this.state, action));
  }

  navigate(action: NavigateAction): Promise<void> {
    return this.handleStateReduction(new NavigateReducer().reduce(this.app, this.state, action));
  }

  surveyResponse(action: SurveyResponseAction): Promise<void> {
    return this.handleStateReduction(
      new SurveyResponsesReducer().reduce(this.app, this.state, action)
    );
  }

  measureAction(action: MeasureAction): Promise<void> {
    return this.handleStateReduction(new MeasureReducer().reduce(this.app, this.state, action));
  }

  rieAction(action: RieAction): Promise<void> {
    return this.handleStateReduction(new RieReducer().reduce(this.app, this.state, action));
  }
}
