import BaseTransformer from "../system/BaseTransformer";
import Survey from "../../models/survey/structure/Survey";
import SectionTransformer from "./SectionTransformer";
import Section from "../../models/survey/structure/Section";

export default class SurveyTransformer extends BaseTransformer<Survey> {
  name = "SurveyTransformer"
  
  create(): Survey {
    return new Survey;
  }

  fill(data: any, survey: Survey): Survey {
    survey.id = data.id;
    survey.reference = data.reference;
    survey.title = data.title;

    survey.sections = (new SectionTransformer(this.context))
      .transformMany(data.sections)
      .sort((a: Section, b: Section) => a.id - b.id);

    return survey;
  }
}
