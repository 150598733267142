import QuestionResponse from "../models/survey/response/QuestionResponse";
import Trajectory from "../models/rie/Trajectory";

const convertLineBreaks = (input: string): string => {
  return input.replace(/(?:\r\n|\r|\n)/g, "<br>")
}

const replaceKeywordsWithUrls = (input: string, trajectory: Trajectory) => {
  const replacements = [
    {
      keyword: "‘Overzicht Opvangplaatsen’",
      replace: `<a href="/people/${trajectory.facility.childminder.id}/week_overview/children" target="_blank">Overzicht Opvangplaatsen</a>`
    },
    {
      keyword: "‘Personenregister’",
      replace: `<a href="/people/${trajectory.facility.childminder.id}/personenregister" target="_blank">Personenregister</a>`
    }
  ];

  return replacements.reduce((value, currentValue) => {
    return value.replace(currentValue.keyword, currentValue.replace);
  }, input);
}


const rieQuestionHTML = (text: string, trajectory: Trajectory) => {
  return replaceKeywordsWithUrls(
    convertLineBreaks(text),
    trajectory
  );
};

export default rieQuestionHTML;
