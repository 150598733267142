import BaseTransformer from "../system/BaseTransformer";
import AttentionPoint from "../../models/rie/AttentionPoint";
import QuestionTransformer from "../survey/QuestionTransformer";
import Question from "../../models/survey/structure/Question";

export default class AttentionPointTransformer extends BaseTransformer<AttentionPoint> {
  name = "AttentionPointTransformer"

  create(): AttentionPoint {
    return new AttentionPoint;
  }

  fill(data: any, attentionPoint: AttentionPoint): AttentionPoint {
    attentionPoint.id = data.id;
    attentionPoint.title = data.title;

    attentionPoint.surveyQuestions = [];
    data.surveyQuestionIds.forEach((surveyQuestionId: any) => {
      (new QuestionTransformer(this.context)).awaitPublish(surveyQuestionId, (question: Question) => {
        attentionPoint.surveyQuestions.push(question);
      });
    });

    return attentionPoint;
  }
}
