import * as React from "react";

import App from "../../../App";
import Measure from "../../../models/rie/Measure";
import { navigateRisk } from "../../../reducers/NavigateReducer";
import MeasureList from "../partials/MeasureList";
import RiePage from "../template/RiePage";
import MeasuresForm from "../../../containers/rie/MeasuresForm";
import { MeasureAction, MEASURE_SAVE } from "../../../reducers/MeasureReducer";
import { MeasureCategory } from "../../../models/rie/MeasureCategory";
import { rieChange } from "../../../reducers/RieReducer";

export interface RiePlanOfActionViewProps {
  app: App;
}

const RiePlanOfActionView: React.SFC<RiePlanOfActionViewProps> = props => {
  const handlers = props.app.handlers;

  return (
    <RiePage
      app={props.app}
      title="Plan van aanpak"
      renderContent={() => (
        <>
          <MeasureList
            renderTitle={() => {
              return (
                <div style={{ fontWeight: "bold" }}>Maatregelen/afspraken horend bij risico</div>
              );
            }}
            measures={props.app.derivations.trajectory.activeMeasures.filter(
              x => x.risk != null && x.category == MeasureCategory.Basic
            )}
            readOnly={true}
            onReadClick={(measure: Measure) => {
              props.app.handlers.navigate(navigateRisk(measure.risk));
            }}
          />

          <hr />

          <MeasuresForm
            renderTitle={() => {
              return <div style={{ fontWeight: "bold" }}>Losse maatregelen/afspraken</div>;
            }}
            trajectory={props.app.derivations.trajectory}
            measures={props.app.derivations.trajectory.activeMeasures.filter(
              x => x.risk == null && x.category == MeasureCategory.Basic
            )}
            onMeasureAction={handlers.measureAction.bind(handlers)}
          />

          <hr />

          <MeasuresForm
            renderTitle={() => {
              return (
                <div style={{ fontWeight: "bold" }}>
                  Naleving gedragsafspraken met oudere kinderen
                </div>
              );
            }}
            buttonLabel="Nieuwe maatregel"
            createTitle="Afspraak maatregel toevoegen"
            updateTitle="Afspraak maatregel wijzigen"
            showDeadline={false}
            trajectory={props.app.derivations.trajectory}
            measures={props.app.derivations.trajectory.activeMeasures.filter(
              x => x.risk == null && x.category == MeasureCategory.ComplianceRuleOfBehavior
            )}
            onMeasureAction={(action: MeasureAction) => {
              if (action.type == MEASURE_SAVE) {
                action.data.category = MeasureCategory.ComplianceRuleOfBehavior;
              }

              handlers.measureAction(action);
            }}
          />

          <hr />

          <div style={{ fontWeight: "bold" }}>Extra informatie</div>
          <textarea
            cols={80}
            rows={10}
            style={{ marginTop: 3 }}
            value={props.app.state.rie.extraInfo}
            onChange={e => {
              handlers.rieAction(
                rieChange({
                  extraInfo: e.target.value
                })
              );
            }}
          ></textarea>
        </>
      )}
    />
  );
};
export default RiePlanOfActionView;
