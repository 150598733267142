import * as _ from 'lodash';

export default class ObjectHelper {
  static keysToCamelCase(input: {}) : {} {
    if((input !== Object(input))) {
      // Primitive type
      return input;
    }

    let object = input;
    let camelCaseObject = _.cloneDeep(object);

    if (_.isArray(camelCaseObject)) {
      return _.map(camelCaseObject, ObjectHelper.keysToCamelCase);
    } else {
      camelCaseObject = _.mapKeys(camelCaseObject, (value, key) => {
        return _.camelCase(key);
      });
  
      // Recursively apply throughout object
      return _.mapValues(camelCaseObject, (value) => {
        if (_.isPlainObject(value)) {
          return ObjectHelper.keysToCamelCase(value);
        } else if (_.isArray(value)) {
          return _.map(value, ObjectHelper.keysToCamelCase);
        } else {
          return value;
        }
      });
    }
  }
}
