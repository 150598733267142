import BaseTransformer from "../system/BaseTransformer";
import Question from "../../models/survey/structure/Question";
import ChoiceTransformer from "./ChoiceTransformer";
import ConditionTransformer from "./ConditionTransformer";
import Section from "../../models/survey/structure/Section";
import SectionTransformer from "./SectionTransformer";
import Choice from "../../models/survey/structure/Choice";

export default class QuestionTransformer extends BaseTransformer<Question> {
  name = "QuestionTransformer"
  
  create() : Question {
    return new Question;
  }

  fill(data: any, question: Question) : Question {
    question.id = data.id;
    question.title = data.title;
    question.description = data.description;
    question.allowMultipleChoices = data.allowMultipleChoices

    question.choices = (new ChoiceTransformer(this.context))
      .transformMany(data.choices)
      .sort((a: Choice, b: Choice) => a.id - b.id);

    question.conditions = (new ConditionTransformer(this.context)
      .transformMany(data.conditions));

    (new SectionTransformer(this.context))
      .awaitPublish(data.sectionId, section => {
        question.section = section;
      });

    return question;
  }
}
