import { RieContext, Role } from "./RieContext";
import { SurveyContext } from "./SurveyContext";
import QuestionResponse from "../../models/survey/response/QuestionResponse";
import { View } from "../../reducers/NavigateReducer";
import ChoiceResponse from "../../models/survey/response/ChoiceResponse";

export default class RieFranchiseeContext extends RieContext {
  public role = Role.Franchisee;

  public get visibleViews(): View[] {
    return [
      View.Explanation,
      this.trajectory.repeatedFromTrajectoryId && View.AttentionPoints,
      View.SurveySection,
      View.Evaluation,
      View.PlanOfAction,
      View.Finalize,
      View.Implementation
    ].filter(Boolean);
  }

  public get shouldShowQualityRequirements() {
    return !!this.trajectory.qualityRequirementsSurveyResponse;
  }

  surveyContext: SurveyContext = new (class extends SurveyContext {
    private rieContext: RieContext;

    constructor(rieContext: RieContext) {
      super();
      this.rieContext = rieContext;
    }

    questionResponseAllowSkip(questionResponse: QuestionResponse) {
      return false;
    }

    choiceResponseVisible(choiceResponse: ChoiceResponse) {
      const choice = this.rieContext.trajectory.rieStructure.surveyChoices.find(
        x => x.choice == choiceResponse.choice
      );
      return !choice || choice.visibleForFranchisee;
    }
  })(this);
}
