import BaseTransformer from "../system/BaseTransformer";
import Franchisee from "../../models/general/Franchisee";

export default class FranchiseeTransformer extends BaseTransformer<Franchisee> {
  name = "FranchiseeTransformer"

  create(): Franchisee {
    return new Franchisee;
  }

  fill(data: any, franchisee: Franchisee): Franchisee {
    franchisee.id = data.id;
    franchisee.name = data.name;
    franchisee.holderFullName = data.holderFullName;

    return franchisee;
  }
}
