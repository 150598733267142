import * as React from "react";
import SignaturePad from "./SignaturePad";
export interface SignatureInputProps {
  signature?: string;
  id?: string;
}

export interface SignatureInputState {
  signature: string | null;
}

export class SignatureInput extends React.Component<SignatureInputProps, SignatureInputState> {
  constructor(props: SignatureInputProps) {
    super(props);

    this.state = {
      signature: props.signature ? props.signature : null
    };
  }

  onClear() {
    this.setState({
      signature: null
    });
  }

  onEnd(signature: string) {
    this.setState({
      signature
    });
  }

  render() {
    return (
      <div>
        {this.props.id && (
          <input
            type="hidden"
            name={this.props.id}
            id={this.props.id}
            readOnly={true}
            value={this.state.signature ? this.state.signature : ""}
          ></input>
        )}

        <SignaturePad
          onClear={this.onClear.bind(this)}
          onEnd={this.onEnd.bind(this)}
          signature={this.state.signature}
        ></SignaturePad>
      </div>
    );
  }
}
