import DerivedNavigation from "./DerivedNavigation";
import Derivations from "../Derivations";
import { View, NavigateState } from "../../reducers/NavigateReducer";

export default class QualityRequirementsSurveySection extends DerivedNavigation {
  constructor(derivations: Derivations) {
    super(derivations);
  }

  get previousNavigateState(): NavigateState {
    const previousSectionResponse = this.questionResponse.sectionResponse.previousSectionResponse;

    if (previousSectionResponse) {
      return {
        view: View.SurveySection,
        questionId: previousSectionResponse.questionResponses[0].question.id,
        riskId: null
      };
    } else {
      return {
        view: View.Explanation,
        questionId: null,
        riskId: null
      };
    }
  }

  get nextNavigateState(): NavigateState {
    const nextSectionResponse = this.questionResponse.sectionResponse.nextSectionResponse;

    if (nextSectionResponse) {
      return {
        view: View.SurveySection,
        questionId: nextSectionResponse.questionResponses[0].question.id,
        riskId: null
      }
    } else {
      return {
        view: View.SurveySection,
        questionId: this.derivations.trajectory.rieSurveyResponse.firstActiveQuestionResponse.question.id,
        riskId: null
      }
    }
  }
}
