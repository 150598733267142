import ChoiceResponse from '../survey/response/ChoiceResponse';
import QuestionResponse from '../survey/response/QuestionResponse';
import Measure from './Measure';
import MeasureSuggestion from './MeasureSuggestion';
import Risk from './Risk';
import Trajectory from './Trajectory';
import Section from '../survey/structure/Section';

export default class RiskEvaluation {
  risk: Risk;
  trajectory: Trajectory;

  get isActive(): boolean {
    return this.choiceResponses.filter(x => x.isSelected).length > 0;
  }

  get measures(): ReadonlyArray<Measure> {
    return this.trajectory.measures.filter(measure => {
      return measure.risk == this.risk;
    });
  }

  get isCompleted(): boolean {
    return !this.isActive || this.measures.length > 0;
  }

  get surveyQuestionResponses(): QuestionResponse[] {
    return this.risk.questions.map(question => {
      return this.trajectory.rieSurveyResponse.questionResponses.find(x => x.question == question);
    });
  }

  get choiceResponses(): ReadonlyArray<ChoiceResponse> {
    return this.risk.surveyChoices.map(surveyChoice => {
      return this.trajectory.rieSurveyResponse.choiceResponses.find(x => x.choice == surveyChoice);
    });
  }

  get previousRiskEvaluation(): RiskEvaluation {
    return this.risk.previousRisk ? this.trajectory.riskEvaluations.find(x => x.risk == this.risk.previousRisk) : null;
  }

  get section(): Section {
    return this.surveyQuestionResponses[0].question.section;
  }

  public get previousRiskEvaluations(): ReadonlyArray<RiskEvaluation> {
    let previousRiskEvaluations: RiskEvaluation[] = [];
    let current = this.previousRiskEvaluation;
    while (current) {
      previousRiskEvaluations.push(current);
      current = current.previousRiskEvaluation;
    }
    return previousRiskEvaluations;
  }

  public get previousActiveRiskEvaluation(): RiskEvaluation {
    return this.previousRiskEvaluations.find(x => x.isActive);
  }

  public get nextRiskEvaluation(): RiskEvaluation {
    return this.risk.nextRisk ? this.trajectory.riskEvaluations.find(x => x.risk == this.risk.nextRisk) : null;
  }

  public get nextRiskEvaluations(): ReadonlyArray<RiskEvaluation> {
    let nextRiskEvaluations: RiskEvaluation[] = [];
    let current = this.nextRiskEvaluation;;
    while (current) {
      nextRiskEvaluations.push(current);
      current = current.nextRiskEvaluation;
    }
    return nextRiskEvaluations;
  }

  public get nextActiveRiskEvaluation(): RiskEvaluation {
    return this.nextRiskEvaluations.find(x => x.isActive);
  }

  // Measure suggestions that currently are not adopted for the risk evaluation,
  // based on matching descriptions.
  public get remainingMeasureSuggestions(): ReadonlyArray<MeasureSuggestion> {
    return this.risk.measureSuggestions.filter(measureSuggestion => {
      return this.measures.find(x => x.description == measureSuggestion.description) == null;
    })
  }

  previousMeasure(measure: Measure): Measure {
    let measures = this.measures;
    let index = measures.indexOf(measure);
    let previousIndex = index + -1;
    return previousIndex > -1 ? measures[previousIndex] : null;
  }

  nextMeasure(measure: Measure): Measure {
    let measures = this.measures;
    let index = measures.indexOf(measure);
    let nextIndex = index + 1;
    return measures.length > nextIndex ? measures[nextIndex] : null;
  }

  isRelatedToQuestionResponse(questionResponse: QuestionResponse) {
    return this.risk.isRelatedToQuestion(questionResponse.question);
  }
}
