import * as React from "react";
import { default as SignaturePadPlugin } from "signature_pad";

export interface SignaturePadProps {
  onClear?: () => void;
  onEnd?: (signature: string) => void;
  signature?: string;
}

export interface SignaturePadState {
  signature: string;
}

export default class SignaturePad extends React.Component<SignaturePadProps, SignaturePadState> {
  private el: HTMLCanvasElement;
  private signaturePad: any;

  constructor(props: SignaturePadProps) {
    super(props);
    this.state = {
      signature: props.signature ? props.signature : null
    };
  }

  componentDidMount() {
    const onEnd = this.props.onEnd ? this.props.onEnd : () => {};

    this.signaturePad = new SignaturePadPlugin(this.el);

    var ratio = Math.max(window.devicePixelRatio || 1, 1);
    this.el.width = this.el.offsetWidth * ratio;
    this.el.height = this.el.offsetHeight * ratio;
    this.el.getContext("2d").scale(ratio, ratio);

    this.signaturePad.minWidth = 0.5;
    this.signaturePad.maxWidth = 3;
    this.signaturePad.penColor = "#4f55c6";

    if (this.props.signature) {
      this.signaturePad.fromDataURL(this.props.signature);
    }

    this.signaturePad.onEnd = () => {
      const signature = this.signaturePad.toDataURL("image/png");
      this.setState({
        signature
      });
      onEnd(signature);
    };
  }

  render() {
    return (
      <div style={{ userSelect: "none" }}>
        <canvas
          ref={el => (this.el = el)}
          style={{ border: "1px solid #888", width: 300, height: 170 }}
          width={300}
          height={170}
        ></canvas>
        <div style={{ marginTop: "5px" }}>
          <button
            onClick={() => {
              this.signaturePad.clear();
              this.setState({
                signature: null
              });
              this.props.onClear && this.props.onClear();
            }}
            type="button"
            className="button secondary"
            style={{ color: "#666" }}
          >
            Opnieuw
          </button>
        </div>
      </div>
    );
  }
}
