import { Metadata, Props, State } from "../App";
import Trajectory from "../models/rie/Trajectory";
import SurveyResponse from "../models/survey/response/SurveyResponse";
import Survey from "../models/survey/structure/Survey";
import DerivedNavigation from "./navigation/DerivedNavigation";
import DerivedTrajectory from "./DerivedTrajectory";
import DerivedNavigationBuilder from "./navigation/DerivedNavigationBuilder";
import User from "../models/general/User";
import { RieContext } from "../contexts/rie/RieContext";
import RieChildminderContext from "../contexts/rie/RieChildminderContext";
import RieFranchiseeContext from "../contexts/rie/RieFranchiseeContext";

export default class Derivations {
  private _props: Props;
  private _state: State;
  private _trajectory: DerivedTrajectory;
  private _navigation: DerivedNavigation;
  private _context: RieContext;

  constructor(props: Props, state: State) {
    this._props = props;
    this._state = state;

    this._trajectory = new DerivedTrajectory(props, state);
    this._navigation = DerivedNavigationBuilder.build(this);
    this._context = this.props.currentUser.isCustomer
      ? new RieChildminderContext(this.trajectory)
      : new RieFranchiseeContext(this.trajectory);
  }

  get props(): Props {
    return this._props;
  }

  get state(): State {
    return this._state;
  }

  get context(): RieContext {
    return this._context;
  }

  get trajectory(): Trajectory {
    return this._trajectory.trajectory;
  }

  get navigation(): DerivedNavigation {
    return this._navigation;
  }

  get rieSurveyResponse(): SurveyResponse {
    return this.trajectory.rieSurveyResponse;
  }

  get rieSurvey(): Survey {
    return this.rieSurveyResponse.survey;
  }

  get metadata(): Metadata {
    return this.props.metadata;
  }

  get currentUser(): User {
    return this.props.currentUser;
  }
}
