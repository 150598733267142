import Risk from './Risk';
import RiskEvaluation from './RiskEvaluation';
import Trajectory from './Trajectory';
import { MeasureCategory } from './MeasureCategory';

export default class Measure {
  public id: number;
  public key: string;

  public description: string;
  public deadline: Date;
  public originalDeadline: Date;
  public risk: Risk;
  public trajectory: Trajectory;
  public implemented: boolean = false;
  public category: MeasureCategory = MeasureCategory.Basic;

  get riskEvaluation(): RiskEvaluation {
    if(this.risk == null) {
      return null;
    }

    return this.trajectory.riskEvaluations.find(x => x.risk == this.risk);
  }

  get previousMeasure() : Measure {
    return this.riskEvaluation.previousMeasure(this);
  }

  get nextMeasure() : Measure {
    return this.riskEvaluation.nextMeasure(this);
  }

  get measureNumber() : number {
    let previous = this.previousMeasure;
    return previous ? previous.measureNumber + 1 : 1;
  }

  get isActive(): boolean {
    return this.risk ? this.riskEvaluation.isActive : true;
  }

}
