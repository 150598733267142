import DerivedNavigation from "./DerivedNavigation";
import Derivations from "../Derivations";
import { View, NavigateState } from "../../reducers/NavigateReducer";

export default class Send extends DerivedNavigation {
  constructor(derivations: Derivations) {
    super(derivations);
  }

  get previousNavigateState(): NavigateState {
    return {
      view: View.SurveySection,
      questionId: this.derivations.rieSurveyResponse.lastActiveQuestionResponse ? this.derivations.rieSurveyResponse.lastActiveQuestionResponse.question.id : null,
      riskId: null
    };
  }

  get nextNavigateState(): NavigateState {
    return null;
  }
}
