import * as React from 'react';

import Risk from '../../../models/rie/Risk';
import Trajectory from '../../../models/rie/Trajectory';
import Pagination from '../../ui/pagination/Pagination';
import PaginationPage from '../../ui/pagination/PaginationPage';
import RiskEvaluation from '../../../models/rie/RiskEvaluation';

export interface RiskEvaluationNavigationProps {
  riskEvaluations: RiskEvaluation[];
  currentRisk: Risk;
  onNavigateRisk: (risk: Risk) => void;
}

const RiskEvaluationNavigation: React.SFC<RiskEvaluationNavigationProps> = (props) => {
  return (
    <Pagination title="Risico's">
    {
      props.riskEvaluations.map(riskEvaluation => {
        return <PaginationPage
          onClick={ () => { props.onNavigateRisk(riskEvaluation.risk) } }
          key={ riskEvaluation.risk.id }
          isSelected={ props.currentRisk == riskEvaluation.risk }
          hasError={ !riskEvaluation.isCompleted }
          isEnabled={ riskEvaluation.isActive }
        >
          { riskEvaluation.isActive && (riskEvaluation.isCompleted ? '✓' : '') } { riskEvaluation.risk.riskNumber }
        </PaginationPage>
      })
    }
    </Pagination>
  )
}
export default RiskEvaluationNavigation;
