import App, { Props, State } from "../App";

export abstract class Reducer<A, S> {
  abstract initialState(props: Props): S;
  abstract reduce(app: App, previousState: State, action: A): StateReduction;
}

export type StateReduction = {
  newState: State;
  async?: (
    getCurrentState: () => State,
    resolve: (reduction: StateReduction) => void,
    reject: (reduction: StateReduction) => void
  ) => void;
};
