import axios, { AxiosPromise } from "axios";
import { format } from "../helpers/date";

import App, { Props, State } from "../App";
import { Reducer, StateReduction } from "./Reducer";
import SurveyResponse from "../models/survey/response/SurveyResponse";
import { SurveyResponseState } from "./SurveyResponseReducer";

export const SAVE_ALL = "SAVE_ALL";
export type SAVE_ALL = typeof SAVE_ALL;
export interface SaveAll {
  type: SAVE_ALL;
}
export function saveAll(): SaveAll {
  return {
    type: SAVE_ALL
  };
}

export type SaveAction = SaveAll;

export type SaveState = {
  isSaving: boolean;
  hasUnsavedChanges: boolean;
};

export class SaveReducer extends Reducer<SaveAction, SaveState> {
  reduce(app: App, prevState: State, action: SaveAction): StateReduction {
    switch (action.type) {
      case SAVE_ALL:
        return this.saveAll(app, prevState, action);
    }
  }

  initialState(propsCopy: Props): SaveState {
    return {
      isSaving: false,
      hasUnsavedChanges: false
    };
  }

  private saveAll(app: App, prevState: State, action: SaveAction): StateReduction {
    return {
      newState: {
        ...prevState,
        save: {
          ...prevState.save,
          isSaving: true
        }
      },
      async: (
        getCurrentState: () => State,
        resolve: (reduction: StateReduction) => void,
        reject: (reduction: StateReduction) => void
      ) => {
        let newPrevState = getCurrentState();

        this.sendSaveRequest(app)
          .then(response => {
            resolve({
              newState: {
                ...newPrevState,
                rie: {
                  ...newPrevState.rie,
                  rieDate: response.data.rie_date,
                  extraInfo: response.data.extra_info ? response.data.extra_info : "",
                  finalizing: false,
                  finalized: response.data.finalized,
                  referenceCode: response.data.reference_code,
                  documents: response.data.documents.map((document: any) => {
                    return {
                      id: document.id,
                      type: document.type,
                      downloadPathInline: document.download_path_inline
                    };
                  })
                },
                surveyResponses: newPrevState.surveyResponses.map(prevSurveyResponse => {
                  let newProps = {
                    ...prevSurveyResponse
                  };

                  const responseKeys = [
                    {
                      reference: "RIE",
                      key: "survey_response"
                    },
                    {
                      reference: "QUALITY_REQUIREMENTS",
                      key: "quality_requirements_survey_response"
                    }
                  ];

                  const responseKey = responseKeys.find(
                    x => x.reference === prevSurveyResponse.reference
                  );

                  if (responseKey && response.data[responseKey.key]) {
                    newProps = {
                      ...newProps,
                      ...response.data[responseKey.key]
                    };
                  }

                  return {
                    ...newProps,
                    sending: false
                  };
                }),
                measures: newPrevState.measures
                  .filter(() => {
                    return false;
                  })
                  .concat(
                    response.data.measures.map((measureData: any) => {
                      return {
                        id: measureData.id,
                        key: measureData.id,
                        riskId: measureData.risk_id,
                        description: measureData.description,
                        deadlineString: measureData.deadline,
                        originalDeadlineString: measureData.original_deadline,
                        implemented: measureData.implemented,
                        category: measureData.category
                      };
                    })
                  ),
                save: {
                  hasUnsavedChanges: false,
                  isSaving: false
                }
              }
            });
          })
          .catch((reason: string) => {
            alert(reason);
            reject({
              newState: {
                ...newPrevState,
                rie: {
                  ...newPrevState.rie,
                  finalizing: false
                },
                surveyResponses: newPrevState.surveyResponses.map(prevSurveyResponse => {
                  return {
                    ...prevSurveyResponse,
                    sending: false
                  };
                }),
                save: {
                  ...newPrevState.save,
                  isSaving: false
                }
              }
            });
          });
      }
    };
  }

  private sendSaveRequest(app: App): AxiosPromise<any> {
    return axios.put(app.props.metadata.updateURL, this.getRequestBody(app), {
      headers: {
        "X-CSRF-Token": app.props.metadata.csrfToken
      }
    });
  }

  private getRequestBody(app: App): any {
    const rieResponse = app.state.surveyResponses.find(x => x.reference === "RIE");
    const qualityResponse = app.state.surveyResponses.find(
      x => x.reference === "QUALITY_REQUIREMENTS"
    );

    return {
      rie_trajectory: {
        rie_date: app.state.rie.rieDate ? format(app.state.rie.rieDate, "yyyy-MM-dd") : null,
        extra_info: app.state.rie.extraInfo,
        finalized: app.state.rie.finalizing ? true : app.state.rie.finalized,
        survey_response: this.getRequestBodyForSurveyResponse(app, rieResponse),
        quality_requirements_survey_response: this.getRequestBodyForSurveyResponse(
          app,
          qualityResponse
        ),
        measures: app.derivations.trajectory.measures.map(measure => {
          return {
            id: measure.id,
            risk_id: measure.risk ? measure.risk.id : null,
            description: measure.description,
            deadline: measure.deadline ? format(measure.deadline, "yyyy-MM-dd") : null,
            implemented: measure.implemented,
            category: measure.category
          };
        }),
        franchisee_signature: app.state.rie.franchiseeSignature,
        childminder_signature: app.state.rie.childminderSignature
      }
    };
  }

  private getRequestBodyForSurveyResponse(app: App, surveyResponseState?: SurveyResponseState) {
    if (!surveyResponseState) {
      return null;
    }

    const surveyResponse = app.derivations.trajectory.surveyResponses.find(
      x => x.survey.reference === surveyResponseState.reference
    );

    return {
      sent: surveyResponseState.sending ? true : surveyResponseState.sent,
      question_responses: surveyResponse.questionResponses.map(questionResponse => {
        return {
          question_id: questionResponse.question.id,
          skipped: questionResponse.skipped,
          choice_responses: questionResponse.choiceResponses
            .filter(choiceResponse => {
              return app.derivations.context.surveyContext.choiceResponseVisible(choiceResponse);
            })
            .map(choiceResponse => {
              return {
                choice_id: choiceResponse.choice.id,
                selected: choiceResponse.isSelected,
                explanation: choiceResponse.explanation
              };
            })
        };
      })
    };
  }
}
