import BaseTransformer from "../system/BaseTransformer";
import Facility from "../../models/general/Facility";
import PersonTransformer from "./PersonTransformer";
import AddressTransformer from "./AddressTransformer";
import DossierTransformer from "./DossierTransformer";

export default class FacilityTransformer extends BaseTransformer<Facility> {
  name = "FacilityTransformer"

  create() : Facility {
    return new Facility;
  }

  fill(data: any, facility: Facility) : Facility {
    facility.id = data.id;
    facility.lrkNumber = data.lrkNumber;
    facility.fullInfo = data.fullInfo;
    facility.numberOfChildren = data.numberOfChildren;

    facility.address = (new AddressTransformer(this.context))
      .transform(data.address);

    facility.childminder = (new PersonTransformer(this.context))
      .transform(data.childminder);

    facility.dossiers = (new DossierTransformer(this.context))
      .transformMany(data.dossiers);

    return facility;
  }
}
