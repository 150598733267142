import { RieQualityRequirementsViewProps } from './../../components/rie/views/RieQualityRequirementsView';
import Trajectory from '../../models/rie/Trajectory';
import SurveyResponseTranformer from '../survey/SurveyResponseTransformer';
import BaseTransformer from '../system/BaseTransformer';
import MeasureTransformer from './MeasureTransformer';
import RieStructureTransformer from './RieStructureTransformer';
import FacilityTransformer from '../general/FacilityTransformer';
import DocumentTransformer from './DocumentTransformer';
import FranchiseeTransformer from '../general/FranchiseeTransformer';

export default class TrajectoryTransfomer extends BaseTransformer<Trajectory> {
  name = "TrajectoryTransfomer"

  create(): Trajectory {
    return new Trajectory;
  }

  fill(data: any, trajectory: Trajectory): Trajectory {
    trajectory.id = data.id;
    trajectory.rieDate = data.rieDate ? new Date(data.rieDate) : null;
    trajectory.extraInfo = data.extraInfo ? data.extraInfo : "";
    trajectory.finalized = data.finalized;
    trajectory.referenceCode = data.referenceCode;
    trajectory.repeatedFromTrajectoryId = data.repeatedFromTrajectoryId;

    trajectory.franchisee = (new FranchiseeTransformer(this.context))
      .transform(data.franchisee);

    trajectory.facility = (new FacilityTransformer(this.context))
      .transform(data.facility);

    trajectory.qualityRequirementsSurveyResponse = data.qualityRequirementsSurveyResponse ? (new SurveyResponseTranformer(this.context)).transform(data.qualityRequirementsSurveyResponse) : null;
    trajectory.rieSurveyResponse = (new SurveyResponseTranformer(this.context))
      .transform(data.surveyResponse);

    trajectory.rieStructure = (new RieStructureTransformer(this.context))
      .transform(data.rieStructure);

    trajectory.measures = (new MeasureTransformer(this.context))
      .transformMany(data.measures);

    trajectory.documents = (new DocumentTransformer(this.context))
      .transformMany(data.documents);

    return trajectory;
  }
}
