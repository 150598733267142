import * as React from "react";

interface ButtonProps {
  id?: string;
  label: string | JSX.Element;
  isEnabled?: boolean;
  onClick?: () => void;
}

const Button: React.SFC<ButtonProps> = props => {
  let isEnabled = !(props.isEnabled === false);
  let onClick = props.onClick ? props.onClick : () => {};

  let classes: string[] = ["button"];
  if (!isEnabled) {
    classes.push("disabled");
  }

  return (
    <button
      className={classes.join(" ")}
      onClick={() => {
        isEnabled && onClick();
      }}
      style={{ backgroundColor: "#922f7f", padding: "10px 18px" }}
      data-enabled={isEnabled}
      id={props.id}
    >
      {props.label}
    </button>
  );
};
export default Button;
