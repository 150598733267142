import Derivations from "./derivations/Derivations";
import Handlers from "./Handlers";
import RieStructure from "./models/rie/RieStructure";
import Trajectory from "./models/rie/Trajectory";
import { NavigateReducer, NavigateState } from "./reducers/NavigateReducer";
import { MeasureReducer, MeasuresState } from "./reducers/MeasureReducer";
import { SaveReducer, SaveState } from "./reducers/SaveReducer";
import { SurveyResponsesReducer, SurveyResponsesState } from "./reducers/SurveyResponseReducer";
import { RieState, RieReducer } from "./reducers/RieReducer";
import User from "./models/general/User";

export type State = {
  navigate: NavigateState;
  save: SaveState;
  surveyResponses: SurveyResponsesState;
  measures: MeasuresState;
  rie: RieState;
};

export interface Props {
  initialTrajectoryData: Trajectory;
  rieStructure: RieStructure;
  metadata: Metadata;
  currentUser: User;
}

export interface Metadata {
  backURL: string;
  updateURL: string;
  csrfToken: string;
  documentsURL: string;
}

export default class App {
  private _state: State;
  get state(): State {
    return this._state;
  }

  private _props: Props;
  get props(): Props {
    return this._props;
  }

  private _derivations: Derivations;

  // Readonly, perform derivations based on state and props
  get derivations(): Derivations {
    return this._derivations;
  }

  // May be mutated to calculate new states in reducers
  get derivationsCopy(): Derivations {
    return new Derivations(this.props, this.state);
  }

  // Typically React's setState function
  private _stateUpdatedHandler: (newState: State) => void = null;

  private _handlers: Handlers;
  public get handlers(): Handlers {
    return this._handlers;
  }

  constructor(
    props: Props,
    initialState: State = null,
    stateUpdatedHandler: (newState: State) => void = null
  ) {
    this._props = props;
    this._stateUpdatedHandler = stateUpdatedHandler;

    this._state = initialState
      ? initialState
      : {
          navigate: new NavigateReducer().initialState(props),
          save: new SaveReducer().initialState(props),
          surveyResponses: new SurveyResponsesReducer().initialState(props),
          measures: new MeasureReducer().initialState(props),
          rie: new RieReducer().initialState(props)
        };

    this._derivations = new Derivations(this._props, this._state);
    this._handlers = new Handlers(this, this.setState.bind(this));
  }

  private setState(newState: State): void {
    this._state = newState;
    this._derivations = new Derivations(this._props, this._state);

    if (this._stateUpdatedHandler) {
      this._stateUpdatedHandler(this._state);
    }
  }
}
