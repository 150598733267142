import DerivedNavigation from "./DerivedNavigation";
import Derivations from "../Derivations";
import { View, NavigateState } from "../../reducers/NavigateReducer";

export default class RieSurveySection extends DerivedNavigation {
  constructor(derivations: Derivations) {
    super(derivations);
  }

  get previousNavigateState(): NavigateState {
    const previous = this.questionResponse.previousActiveQuestionResponse;

    if (this.isLeavingInventorization && this.viewVisible(View.AttentionPoints)) {
      return {
        view: View.AttentionPoints,
        questionId: null,
        riskId: null
      }
    } else if (previous) {
      return {
        view: View.SurveySection,
        questionId: previous ? previous.question.id : null,
        riskId: null
      };
    } else if (this.derivations.context.shouldShowQualityRequirements) {
      const qualityRequirements = this.derivations.trajectory.qualityRequirementsSurveyResponse;

      return {
        view: View.SurveySection,
        questionId: qualityRequirements.lastActiveQuestionResponse.question.id,
        riskId: null
      };
    } else {
      return {
        view: View.Explanation,
        questionId: null,
        riskId: null
      };
    }
  }

  get nextNavigateState(): NavigateState {
    const next = this.questionResponse.nextActiveQuestionResponse;
    if (next &&
      !this.derivations.trajectory.preparationSectionResponse.isCompleted &&
      next.question.section != this.derivations.trajectory.preparationSectionResponse.section
    ) {
      // Exceptional case: don't allow navigation to inventorization sections if
      // preparation is not complete.
      return null;
    }

    if (this.isLeavingPreparationSection && this.viewVisible(View.AttentionPoints)) {
      return {
        view: View.AttentionPoints,
        questionId: null,
        riskId: null
      }
    } else if (next) {
      return {
        view: View.SurveySection,
        questionId: next.question.id,
        riskId: null
      }
    } else if (this.derivations.currentUser.isCustomer) {
      return {
        view: View.Send,
        questionId: null,
        riskId: null
      };
    } else {
      return {
        view: View.Evaluation,
        questionId: null,
        riskId: this.derivations.trajectory.firstActiveRiskEvaluation ? this.derivations.trajectory.firstActiveRiskEvaluation.risk.id : null
      }
    }
  }

  private get isLeavingInventorization(): boolean {
    const previous = this.questionResponse.previousActiveQuestionResponse;
    const preparationSectionResponse = this.derivations.trajectory.preparationSectionResponse;
    const currentSectionResponse = this.questionResponse.sectionResponse;
    const leavingInventorization = currentSectionResponse.section.id !== preparationSectionResponse.section.id && previous && previous.sectionResponse.section.id === preparationSectionResponse.section.id;

    return leavingInventorization;
  }

  private get isLeavingPreparationSection(): boolean {
    const next = this.questionResponse.nextActiveQuestionResponse;
    const preparationSectionResponse = this.derivations.trajectory.preparationSectionResponse;
    const currentSectionResponse = this.questionResponse.sectionResponse;
    const switchingSection = next && next.sectionResponse.section.id !== currentSectionResponse.section.id;
    const leavingPreparationSection = currentSectionResponse.section.id === preparationSectionResponse.section.id && switchingSection;

    return leavingPreparationSection
  }
}
