import App, { Props, State } from "../App";
import { Reducer, StateReduction } from "./Reducer";
import Choice from "../models/survey/structure/Choice";
import { saveAll } from "./SaveReducer";
import Question from "../models/survey/structure/Question";
import Survey from "../models/survey/structure/Survey";
import SurveyResponse from "../models/survey/response/SurveyResponse";

export const CHOICE_TOGGLE = "CHOICE_TOGGLE";
export type CHOICE_TOGGLE = typeof CHOICE_TOGGLE;
export interface ChoiceToggle {
  type: CHOICE_TOGGLE;
  choice: Choice;
}
export function choiceToggle(choice: Choice): ChoiceToggle {
  return {
    type: CHOICE_TOGGLE,
    choice
  };
}

export const CHOICE_EXPLANATION_CHANGE = "CHOICE_EXPLANATION_CHANGE";
export type CHOICE_EXPLANATION_CHANGE = typeof CHOICE_EXPLANATION_CHANGE;
export interface ChoiceExplanationChange {
  type: CHOICE_EXPLANATION_CHANGE;
  choice: Choice;
  explanation: string;
}
export function choiceExplanationChange(
  explanation: string,
  choice: Choice
): ChoiceExplanationChange {
  return {
    type: CHOICE_EXPLANATION_CHANGE,
    choice,
    explanation
  };
}

export const SURVEY_RESPONSE_SEND = "SURVEY_RESPONSE_SEND";
export type SURVEY_RESPONSE_SEND = typeof SURVEY_RESPONSE_SEND;
export interface SurveyResponseSend {
  type: SURVEY_RESPONSE_SEND;
  survey: Survey;
}
export function surveyResponseSend(survey: Survey): SurveyResponseSend {
  return {
    type: SURVEY_RESPONSE_SEND,
    survey
  };
}

export const QUESTION_SKIP_CHANGE = "QUESTION_SKIP_CHANGE";
export type QUESTION_SKIP_CHANGE = typeof QUESTION_SKIP_CHANGE;
export interface QuestionSkipChange {
  type: QUESTION_SKIP_CHANGE;
  question: Question;
  skipped: boolean;
}
export function questionSkipChange(question: Question, skipped: boolean): QuestionSkipChange {
  return {
    type: QUESTION_SKIP_CHANGE,
    question,
    skipped
  };
}

export const CLEAR_QUESTION = "CLEAR_QUESTION";
export type CLEAR_QUESTION = typeof CLEAR_QUESTION;
export interface ClearQuestion {
  type: CLEAR_QUESTION;
  question: Question;
}
export function clearQuestion(question: Question): ClearQuestion {
  return {
    type: CLEAR_QUESTION,
    question
  };
}

export const CLEAR_QUESTIONS = "CLEAR_QUESTIONS";
export type CLEAR_QUESTIONS = typeof CLEAR_QUESTIONS;
export interface ClearQuestions {
  type: CLEAR_QUESTIONS;
  questions: Question[];
}
export function clearQuestions(questions: Question[]): ClearQuestions {
  return {
    type: CLEAR_QUESTIONS,
    questions
  };
}

export type SurveyResponseState = {
  reference: string;
  sending: boolean;
  sent: boolean;
  questionResponses: {
    questionId: number;
    skipped: boolean;
    choiceResponses: ChoiceResponseState[];
  }[];
};
export type SurveyResponsesState = SurveyResponseState[];

export type ChoiceResponseState = {
  choiceId: number;
  isSelected: boolean;
  explanation: string;
};

export type SurveyResponseAction =
  | ChoiceToggle
  | ChoiceExplanationChange
  | SurveyResponseSend
  | QuestionSkipChange
  | ClearQuestion
  | ClearQuestions;

export class SurveyResponsesReducer extends Reducer<SurveyResponseAction, SurveyResponsesState> {
  reduce(app: App, prevState: State, action: SurveyResponseAction): StateReduction {
    switch (action.type) {
      case CHOICE_TOGGLE:
        return this.handleChoiceToggle(app, prevState, action);

      case CHOICE_EXPLANATION_CHANGE:
        return this.handleChoiceExplanationChange(app, prevState, action);

      case SURVEY_RESPONSE_SEND:
        return this.handleSurveyResponseSend(app, prevState, action);

      case QUESTION_SKIP_CHANGE:
        return this.handleQuestionSkipChange(app, prevState, action);

      case CLEAR_QUESTION:
        return this.handleClearQuestion(app, prevState, action);

      case CLEAR_QUESTIONS:
        return this.handleClearQuestions(app, prevState, action);
    }
  }

  initialState(props: Props): SurveyResponsesState {
    const trajectory = props.initialTrajectoryData;

    return [
      this.initialSurveyResponseState(trajectory.rieSurveyResponse),
      trajectory.qualityRequirementsSurveyResponse &&
        this.initialSurveyResponseState(trajectory.qualityRequirementsSurveyResponse)
    ].filter(Boolean);
  }

  private initialSurveyResponseState(surveyResponse: SurveyResponse) {
    return {
      reference: surveyResponse.survey.reference,
      sending: false,
      sent: surveyResponse.sent,
      questionResponses: surveyResponse.questionResponses.map(questionResponse => {
        return {
          questionId: questionResponse.question.id,
          skipped: questionResponse.skipped,
          choiceResponses: questionResponse.choiceResponses.map(choiceResponse => {
            return {
              choiceId: choiceResponse.choice.id,
              isSelected: choiceResponse.isSelected,
              explanation: choiceResponse.explanation
            };
          })
        };
      })
    };
  }

  private handleChoiceToggle(app: App, prevState: State, action: ChoiceToggle): StateReduction {
    let { trajectory } = app.derivationsCopy;

    let questionResponse = trajectory.questionResponses.find(
      x => x.question.id == action.choice.question.id
    );
    let choiceResponse = questionResponse.choiceResponses.find(
      x => x.choice.id == action.choice.id
    );

    if (!choiceResponse.isSelected || choiceResponse.choice.question.allowMultipleChoices) {
      choiceResponse.isSelected = !choiceResponse.isSelected;
    }

    return {
      newState: {
        ...prevState,
        save: {
          ...prevState.save,
          hasUnsavedChanges: true
        },

        surveyResponses: prevState.surveyResponses.map(prevSurveyResponse => {
          return {
            ...prevSurveyResponse,
            questionResponses: prevSurveyResponse.questionResponses.map(item => {
              if (item.questionId != action.choice.question.id) {
                return item;
              }

              return {
                ...item,
                skipped: false,
                choiceResponses: questionResponse.choiceResponses.map(choiceResponse => {
                  return {
                    choiceId: choiceResponse.choice.id,
                    isSelected: choiceResponse.isSelected,
                    explanation: choiceResponse.explanation
                  };
                })
              };
            })
          };
        })
      }
    };
  }

  private handleChoiceExplanationChange(
    app: App,
    prevState: State,
    action: ChoiceExplanationChange
  ): StateReduction {
    return {
      newState: {
        ...prevState,
        save: {
          ...prevState.save,
          hasUnsavedChanges: true
        },
        surveyResponses: prevState.surveyResponses.map(prevSurveyResponse => {
          return {
            ...prevSurveyResponse,
            questionResponses: prevSurveyResponse.questionResponses.map(item => {
              if (item.questionId != action.choice.question.id) {
                return item;
              }

              return {
                ...item,
                choiceResponses: item.choiceResponses.map(item => {
                  if (item.choiceId != action.choice.id) {
                    return item;
                  }

                  return {
                    ...item,
                    explanation: action.explanation
                  };
                })
              };
            })
          };
        })
      }
    };
  }

  private handleSurveyResponseSend(
    app: App,
    prevState: State,
    action: SurveyResponseSend
  ): StateReduction {
    return {
      newState: {
        ...prevState,
        surveyResponses: prevState.surveyResponses.map(prevSurveyResponse => {
          if (prevSurveyResponse.reference !== action.survey.reference) {
            return prevSurveyResponse;
          }

          return {
            ...prevSurveyResponse,
            sending: true
          };
        })
      },
      async: (getCurrentState: () => State, resolve: (reduction: StateReduction) => void) => {
        app.handlers.save(saveAll());
      }
    };
  }

  private handleQuestionSkipChange(
    app: App,
    prevState: State,
    action: QuestionSkipChange
  ): StateReduction {
    return {
      newState: {
        ...prevState,
        save: {
          ...prevState.save,
          hasUnsavedChanges: true
        },
        surveyResponses: prevState.surveyResponses.map(prevSurveyResponse => {
          return {
            ...prevSurveyResponse,
            questionResponses: prevSurveyResponse.questionResponses.map(item => {
              if (item.questionId != action.question.id) {
                return item;
              }

              return {
                ...item,
                skipped: action.skipped
              };
            })
          };
        })
      }
    };
  }

  private handleClearQuestion(app: App, prevState: State, action: ClearQuestion): StateReduction {
    return this.reduce(app, prevState, clearQuestions([action.question]));
  }

  private handleClearQuestions(app: App, prevState: State, action: ClearQuestions): StateReduction {
    return {
      newState: {
        ...prevState,
        save: {
          ...prevState.save,
          hasUnsavedChanges: true
        },
        surveyResponses: prevState.surveyResponses.map(prevSurveyResponse => {
          return {
            ...prevSurveyResponse,
            questionResponses: prevSurveyResponse.questionResponses.map(item => {
              if (action.questions.findIndex(x => x.id === item.questionId) === -1) {
                return item;
              }

              return {
                ...item,
                choiceResponses: item.choiceResponses.map(item => {
                  return {
                    ...item,
                    explanation: null,
                    isSelected: false
                  };
                })
              };
            })
          };
        })
      }
    };
  }
}
