import Derivations from "../Derivations";
import { View } from "../../reducers/NavigateReducer";
import RieSurveySection from "./RieSurveySection";
import Finalize from "./Finalize";
import Evaluation from "./Evaluation";
import PlanOfAction from "./PlanOfAction";
import Implementation from "./Implementation";
import Send from "./Send";
import Explanation from "./Explanation";
import AttentionPoints from "./AttentionPoints";
import QualityRequirementsSurveySection from "./QualityRequirementsSurveySection";
import SurveySection from "./SurveySection";

export default abstract class DerivedNavigationBuilder {
  public static build(derivations: Derivations) {
    switch (derivations.state.navigate.view) {
      case View.Explanation:
        return new Explanation(derivations);

      case View.SurveySection:
        return new SurveySection(derivations);

      case View.Evaluation:
        return new Evaluation(derivations);

      case View.PlanOfAction:
        return new PlanOfAction(derivations);

      case View.Finalize:
        return new Finalize(derivations);

      case View.Implementation:
        return new Implementation(derivations);

      case View.Send:
        return new Send(derivations);

      case View.AttentionPoints:
        return new AttentionPoints(derivations);

      default:
        throw new Error(`No registered navigation deriver for view type ${derivations.state.navigate.view}`);
    }
  }
}
