import { format, parse } from 'date-fns';
import * as React from 'react';

import Measure from '../../../models/rie/Measure';
import Datepicker from './Datepicker';

export interface MeasureChecklistProps {
  measures: ReadonlyArray<Measure>;
  onMeasureClick?: (measure: Measure) => void;
  onDeadlineChange?: (measure: Measure, deadline: Date) => void;
  readOnly?: boolean;
}

const MeasureChecklist: React.SFC<MeasureChecklistProps> = ({
  onMeasureClick = () => {},
  onDeadlineChange = (measure: Measure, deadline: Date) => {},
  measures,
  readOnly = false
}) => {
  let style: any = { width: '100%', maxWidth: '700px' };
  if(readOnly === true) {
    style.pointerEvents = 'none';
    style.cursor = 'not-allowed';
  }

  return <>
    <table style={ style } className={ 'minimal_table margin-top-none full_width hoverable'}>
      <thead>
        <tr>
          <th style={ { width: '50px' } }>Voltooid</th>
          <th style={ { width: '120px' } }>Oorspr. streefdatum</th>
          <th style={ { width: '120px' } }>Huidige streefdatum</th>
          <th>Beschrijving</th>
        </tr>
      </thead>
      <tbody>
        { measures.map(measure => {
          return <tr key={ measure.key } className="item" onClick={ () => { onMeasureClick(measure) }}>
            <td style={ { textAlign: 'center' } }>
              { !readOnly && <input type='checkbox' readOnly={ true } checked={ measure.implemented } />  }
              { readOnly && measure.implemented && '✓' }
            </td>
            <td>
              { measure.originalDeadline ? <span>
                { format(measure.originalDeadline, 'd-M-yyyy') }
              </span> :
              <span>-</span> }
            </td>
            <td>
              { readOnly ? (
                measure.deadline ?
                  <span>
                    { format(measure.deadline, 'd-M-yyyy') }
                  </span> :
                  <span>-</span>
              ) : (
                <div onClick={ (e) => { e.stopPropagation(); }}>
                  <Datepicker
                    value={ measure.deadline }
                    onChange={ (deadline) => {
                      onDeadlineChange(measure, parse(deadline, 'yyyy/MM/dd', new Date()))
                    }}
                  />
                </div>
              ) }
            </td>
            <td>
              { measure.description }
            </td>
          </tr>
        })}
      </tbody>
    </table>
  </>
};
export default MeasureChecklist;
