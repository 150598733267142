import Survey from '../survey/structure/Survey';
import Risk from './Risk';
import RieSurveyChoice from './RieSurveyChoice';
import AttentionPoint from './AttentionPoint';

export default class RieStructure {
  public id: number;
  private _risks: Risk[];

  public get risks(): Risk[] {
    return this._risks
  };

  public set risks(risks: Risk[]) {
    this._risks = risks.sort((r1, r2) => {
      if (r1.questions.length === 0 || r2.questions.length === 0) {
        return 1;
      } else {
        return r1.questions[0].id - r2.questions[0].id;
      }
    });
  }

  public surveyChoices: RieSurveyChoice[];
  public survey: Survey;
  public attentionPoints: AttentionPoint[];

  previousRisk(risk: Risk): Risk {
    let risks = this.risks;
    let index = risks.indexOf(risk);
    let previousIndex = index + -1;
    return previousIndex > -1 ? risks[previousIndex] : null;
  }

  nextRisk(risk: Risk): Risk {
    let risks = this.risks;
    let index = risks.indexOf(risk);
    let nextIndex = index + 1;
    return risks.length > nextIndex ? risks[nextIndex] : null;
  }
}
