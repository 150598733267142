import { RieContext, Role } from './RieContext';
import { SurveyContext } from './SurveyContext';
import QuestionResponse from '../../models/survey/response/QuestionResponse';
import { View } from '../../reducers/NavigateReducer';
import ChoiceResponse from '../../models/survey/response/ChoiceResponse';
import { ExplanationMode } from '../../models/survey/structure/ExplanationMode';

export default class RieChildminderContext extends RieContext {
  public role = Role.Childminder;

  public visibleViews: View[] = [
    View.Explanation,
    View.SurveySection,
    View.Send,
    View.Implementation
  ];

  surveyContext: SurveyContext = new class extends SurveyContext {
    private rieContext: RieContext;

    constructor(rieContext: RieContext) {
      super();
      this.rieContext = rieContext;
    }

    questionResponseAllowSkip(questionResponse: QuestionResponse) {
      return questionResponse.sectionResponse.section != this.rieContext.trajectory.preparationSectionResponse.section;
    }

    choiceResponseVisible(choiceResponse: ChoiceResponse) {
      const choice = this.rieContext.trajectory.rieStructure.surveyChoices.find(x => x.choice == choiceResponse.choice);
      return !choice || choice.visibleForChildminder;
    }
  }(this);
}

