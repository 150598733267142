import Condition from '../structure/Condition';
import { ExplanationMode, explanationModeComparator } from '../structure/ExplanationMode';
import Question from '../structure/Question';
import ChoiceResponse from './ChoiceResponse';
import SectionResponse from './SectionResponse';
import SurveyResponse from './SurveyResponse';

export default class QuestionResponse {
  public question: Question;
  public surveyResponse: SurveyResponse;
  public id: number;
  public choiceResponses: ChoiceResponse[];
  public skipped: boolean;

  private _selectedChoiceResponses: ChoiceResponse[] = [];
  public get selectedChoiceResponses(): ChoiceResponse[] {
    return this._selectedChoiceResponses;
  }

  get sectionResponse(): SectionResponse {
    return this.surveyResponse.sectionResponses.find(x => x.section == this.question.section);
  }

  public get isCompleted(): boolean {
    if (!this.isActive) {
      return true;
    }

    let atLeastOneSelectedChoiceResponse = this._selectedChoiceResponses.length > 0;
    let explanationGivenIfRequired = this._selectedChoiceResponses.find(x => x.choice.explanationMode == ExplanationMode.Required && !x.explanation) == null;

    return atLeastOneSelectedChoiceResponse &&
      explanationGivenIfRequired;
  }

  public get isCompletedOrSkipped(): boolean {
    return this.isCompleted || this.skipped;
  }

  public get isActive(): boolean {
    if (this.question.conditions.length == 0) {
      return true;
    } else {
      return this.question.conditions.find((condition: Condition) => {
        let choiceResponse = this.surveyResponse.choiceResponses
          .find(x => x.choice == condition.choice);
        return choiceResponse.questionResponse.isActive && choiceResponse.isSelected;
      }) != null;
    }
  }

  public get previousQuestionResponse(): QuestionResponse {
    return this.question.previousQuestion ? this.surveyResponse.questionResponses.find(x => x.question == this.question.previousQuestion) : null;
  }

  public get previousQuestionResponses(): QuestionResponse[] {
    let previousQuestionsResponses: QuestionResponse[] = [];
    let current = this.previousQuestionResponse;
    while (current) {
      previousQuestionsResponses.push(current);
      current = current.previousQuestionResponse;
    }
    return previousQuestionsResponses;
  }

  public get previousActiveQuestionResponse(): QuestionResponse {
    return this.previousQuestionResponses.find(x => x.isActive);
  }

  public get nextQuestionResponse(): QuestionResponse {
    return this.question.nextQuestion ? this.surveyResponse.questionResponses.find(x => x.question == this.question.nextQuestion) : null;
  }

  public get nextQuestionResponses(): QuestionResponse[] {
    let nextQuestionsResponses: QuestionResponse[] = [];
    let current = this.nextQuestionResponse;
    while (current) {
      nextQuestionsResponses.push(current);
      current = current.nextQuestionResponse;
    }
    return nextQuestionsResponses;
  }

  public get nextActiveQuestionResponse(): QuestionResponse {
    return this.nextQuestionResponses.find(x => x.isActive);
  }

  public selectChoiceResponse(choiceResponse: ChoiceResponse, value: boolean) {
    let index = this._selectedChoiceResponses.indexOf(choiceResponse);
    if (index > -1 && value == false) {
      this._selectedChoiceResponses.splice(index, 1);
    } else if (index == -1 && value == true) {
      if (!this.question.allowMultipleChoices) {
        this._selectedChoiceResponses.splice(0);
      }
      this._selectedChoiceResponses.push(choiceResponse);
    }
  }

  public choiceResponseSelected(choiceResponse: ChoiceResponse) {
    return this._selectedChoiceResponses.find(current => current == choiceResponse) != null;
  }

  public get isEmpty(): boolean {
    return !this.choiceResponses.find(cr => cr.isSelected);
  }
}
