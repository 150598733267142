import Address from "./Address";
import Person from "./Person";
import Dossier from "./Dossier";

export default class Facility {
  id: number;
  lrkNumber: number;
  address: Address;
  childminder: Person;
  fullInfo: string;
  dossiers: Dossier[];
  numberOfChildren: number;
}
