import { format, isValid, parse } from 'date-fns';
import * as React from 'react';

import Button from '../../components/ui/Button';
import Measure from '../../models/rie/Measure';
import Datepicker from '../../components/rie/partials/Datepicker';

export enum Mode {
  Create,
  Update
}

export interface RiskMeasureFormProps {
  initialMeasure: Measure;
  mode: Mode
  onCancel: () => void;
  onConfirm: (measure: Measure) => void;
}

export interface RiskMeasureFormState {
  description: string;
  deadlineString: string;
}

export default class RiskMeasureForm extends React.Component<RiskMeasureFormProps, RiskMeasureFormState> {
  constructor(props: RiskMeasureFormProps) {
    super(props);
    const init = props.initialMeasure;

    this.state = {
      description: init.description,
      deadlineString: init.deadline ? format(init.deadline, 'yyyy-MM-dd') : ''
    };
  }

  render() {
    return (
      <fieldset style={ { border: '1px solid #BBB', padding: '10px', maxWidth: '700px', boxSizing: 'border-box' }}>
        <div style={ { fontWeight: 'bold', fontSize: '14px', marginBottom: '10px' } }>
          { this.props.mode == Mode.Create ? 'Maatregel toevoegen' : 'Maatregel wijzigen' }
        </div>
        <div style={ { marginBottom: '5px' } }>
          <label style={ { display: 'block' }}>
            <div>Beschrijving*</div>
            <input type="text"
              autoFocus
              style={ { display: 'block', width: '100%', boxSizing: 'border-box' } }
              value={ this.state.description}
              name="measure_description"
              onChange={ (e) => { this.descriptionChangeHandler(e.target.value) } } />
          </label>
        </div>
        <div style={ { marginTop: '5px', marginBottom: '5px' } }>
          <label style={ { display: 'block' }}>
            <div>Streefdatum</div>
            <Datepicker
              value={ this.state.deadlineString }
              name="measure_deadline"
              onChange={ (value: string) => {
                this.deadlineStringChangeHandler(value)
              }}
            />
          </label>
        </div>

        <div style={ { marginTop: '15px' } }>
          <Button
            label={ this.props.mode == Mode.Create ? "Toevoegen" : "Wijzigen" }
            onClick={ () => { this.props.onConfirm(this.measure) }}
          />
          <Button
            label="Annuleren"
            onClick={ this.props.onCancel }
          />
        </div>
      </fieldset>
    )
  }

  private get parsedDeadline() : Date {
    return this.state.deadlineString ? parse(this.state.deadlineString, 'yyyy/MM/dd', new Date()) : null;
  }

  private get deadlineIsValid(): boolean {
    return this.parsedDeadline == null ? true : isValid(this.parsedDeadline);
  }

  private get deadline(): Date {
    return this.deadlineIsValid ? this.parsedDeadline : this.props.initialMeasure.deadline;
  }


  private get measure(): Measure {
    let measure = new Measure;
    const init = this.props.initialMeasure;

    measure.id = init.id;
    measure.key = init.key;
    measure.description = this.state.description;
    measure.deadline = this.deadline;
    measure.risk = init.risk;
    measure.trajectory = init.trajectory;

    return measure;
  }

  descriptionChangeHandler(description: string) {
    this.setState(prevState => {
      return {
        ...prevState,
        description
      }
    })
  }

  deadlineStringChangeHandler(deadlineString: string) {
    this.setState(prevState => {
      return {
        ...prevState,
        deadlineString: deadlineString.trim()
      }
    })
  }
}
